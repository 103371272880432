import { useState, useRef, ChangeEvent, DragEvent } from "react";

import { useLocation, useHistory } from "react-router-dom";

import { ReactComponent as UploadIcon } from "../../svg/icons/file_2.svg";
import { ReactComponent as DeleteIcon } from "../../svg/icons/delete.svg";
import { ReactComponent as DownloadIcon } from "../../svg/icons/download-icon.svg";
import BackButtonComponent from "../../components/BackButton/BackButton.component";
import { convertToBase64 } from "../../helpers/convertToBase64";
import { getData, post } from "../../apis/apiMethods";
import Loader from "../../components/Loader/Loader.component";
import { apiEndpoints } from "../../apis/apiEndpoints";
import LoaderColored from "../../components/Loader/LoaderColored";
import CustomTextArea from "../../components/CustomHTMLElements/CustomTextArea";
import ROUTES from "../../helpers/ROUTES";
import CommentsModal from "../../components/Comments/Comments";

import style from "./UploadDownloadDealSlip.module.scss";
import { appInsights } from "../../components/AppInsight/AppInsight";

type TLocationState = {
  slipMetaData: {
    admin_uploaded_slip_filename: string;
    admin_uploaded_slip_path: string;
    loan_id: number;
    deal_slip_id: number;
    from: string;
    loan_ref: string;
    deal_slip_comments: {
      id: number;
      datetime_created: string;
      first_name: string;
      last_name: string;
      user_type: string;
      comment: string;
    }[];
  };
};
const DownloadUploadDealSlip = () => {
  const fileInputElement = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<null | string>(null);
  const [fileToUpload, setFileToUpload] = useState<FileList | null>();
  const [documentPayload, setDocumentPayload] = useState({});
  const [isSendingToAdmin, setIsSendingToAdmin] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [comments, setComments] = useState("");
  const [isAddingComment, setIsAddComments] = useState(false);

  const dismissRef = useRef<null | HTMLButtonElement>(null);

  const location = useLocation();
  const history = useHistory();

  const {
    slipMetaData: { deal_slip_id, from, loan_ref, deal_slip_comments },
  } = location?.state as TLocationState;

  const loanId = location?.pathname.split("/")[2];

  const handleBrowseClick = () => {
    if (fileInputElement.current !== null) {
      return fileInputElement.current.click();
    }
  };

  const enableDropping = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const validateFileToUpload = async (file: FileList | null) => {
    setError(null);

    if (file && file.length) {
      if (file[0].name.endsWith(".pdf")) {
        setFileToUpload(file);
        const fileBase64 = await convertToBase64(file[0]);

        const documentToUpload = {
          loan_id: Number(loanId),
          base64_file_string: fileBase64,
          filename_with_extension: file[0].name,
        };

        setDocumentPayload(documentToUpload);
      } else {
        setError("Invalid file type");
        setFileToUpload(null);
      }
    }
  };

  const prepareDownloadLink = (content: string, fileName: string) => {
    const appendSource = "application/pdf";

    const linkSource = `data:${appendSource};base64,${content}`;
    const documentLink = document.createElement("a");
    documentLink.download = fileName;
    documentLink.href = linkSource;
    documentLink.click();
  };

  const getDocument = async () => {
    setIsDownloading(true);
    try {
      const response = await getData(
        `${apiEndpoints.DOWNLOAD_DEAL_SLIP}/${loanId}`
      );

      if (response?.status_code === 200) {
        prepareDownloadLink(
          response?.data?.base64_string,
          response?.data?.file_name
        );
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UploadDownloadDealSlip.tsx",
        },
      });
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
    setIsDownloading(false);
  };

  const sendDocumentToAdmin = async () => {
    setIsSendingToAdmin(true);
    try {
      const response = await post(
        `${apiEndpoints?.SEND_SIGNED_SLIP}`,
        documentPayload
      );
      if (response?.data?.status === true) {
        setSuccessMessage("Signed deal slip was uploaded successfully!");
        setTimeout(() => {
          setSuccessMessage("");
          if (from === "pendingDealSlips") {
            history.push(`${ROUTES.PENDING_DEAL_SLIPS}`);
          }
          if (from === "dealSlipStatus") {
            history.go(-2);
          }
        }, 3000);
      } else {
        setErrorMessage(response?.data?.message);
        dismissRef.current?.click();
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UploadDownloadDealSlip.tsx",
        },
      });
      setErrorMessage(error?.response?.data?.message);
      dismissRef.current?.click();
    }
    setIsSendingToAdmin(false);
  };

  const handleChangeComments = (e: ChangeEvent<HTMLTextAreaElement>) => {
    return setComments(e.target.value);
  };

  const handleAddComments = async () => {
    setIsAddComments(true);
    try {
      const payload = {
        comment: comments,
        deal_slip_id,
      };
      const response = await post(
        `${apiEndpoints.DEAL_SLIP_COMMENTS}`,
        payload
      );

      if (response.data.status === true) {
        setSuccessMessage(response?.data?.message);
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
        history.push(`/pending-deal-slips`);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UploadDownloadDealSlip.tsx",
        },
      });
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
    setIsAddComments(false);
  };

  const handleRemoveFile = () => {
    setFileToUpload(null);
    (
      document.getElementById("deal-slip-upload-input") as HTMLInputElement
    ).value = "";
  };

  return (
    <div className="animated fadeInRight">
      <h3 className="page-title d-flex align-items-center">
        <BackButtonComponent /> Loan REF: {loan_ref}
      </h3>

      <div className={style.uploadContainer}>
        {errorMessage && (
          <p className="alert alert-danger alert-dismissible small">
            {errorMessage}
          </p>
        )}
        {successMessage && (
          <p className="alert alert-success alert-dismissible small">
            {successMessage}
          </p>
        )}
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-xl-5">
            <div className="row">
              <div className="col-12 col-lg-12">
                <h2 className={`page-title ${style.title}`}>
                  Download Deal Slip
                </h2>

                <div className="pl-4">
                  <button
                    name="button"
                    className={`btn advancly-btn btn-lgtransition-3d-hover ${style.previewDocument}`}
                    type="button"
                    onClick={getDocument}
                  >
                    <DownloadIcon width={24} height={24} />
                    <span>Download</span>
                    {isDownloading && <LoaderColored />}
                  </button>
                </div>
              </div>
              <div className="col-12 col-lg-12">
                <div className="pl-4">
                  <CustomTextArea
                    maxLength="256"
                    reference={null}
                    placeholder="Enter Comments"
                    label="Comments"
                    name="comments"
                    errors={null}
                    showRequiredIcon={true}
                    rows=""
                    bottomLabel=""
                    disabled={false}
                    readOnly={false}
                    otherLabel=""
                    otherLabelClassName=""
                    max={""}
                    defaultValue=""
                    minLength={""}
                    onChange={handleChangeComments}
                    extraLabel=""
                  />

                  <div>
                    <button
                      className={`btn advancly-btn btn-sm transition-3d-hover`}
                      onClick={handleAddComments}
                    >
                      Add Comment
                      {isAddingComment && <Loader />}
                    </button>
                    <button
                      className={`btn advancly-white-btn btn-sm transition-3d-hover ml-3`}
                      data-toggle="modal"
                      data-target="#commentsModal"
                    >
                      Previous Comments
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-xl-7">
            <h3 className={`page-title ${style.title}`}>
              Upload your Deal Slip
            </h3>

            <input
              type="file"
              className="d-none"
              ref={fileInputElement}
              id="deal-slip-upload-input"
              accept=".pdf"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                validateFileToUpload(e.target.files);
              }}
            />

            <p className={style.welcomeText}>
              Thank you for accepting our offer. <br />
              Kindly upload a signed copy of the deal slip
            </p>

            <div
              className={style.uploadCard}
              id="deal-slip-upload-div"
              onDragOver={enableDropping}
              onDrop={(e: DragEvent<HTMLDivElement>) => {
                e.preventDefault();
                validateFileToUpload(e.dataTransfer.files);
              }}
            >
              <div className={style.iconContainer}>
                <UploadIcon />
              </div>
              <h5 className={style.header}>Upload Deal Slip</h5>
              <p>
                Drag & drop your file here or{" "}
                <span
                  className={style.browseAction}
                  onClick={handleBrowseClick}
                >
                  browse
                </span>
              </p>
            </div>
            {error && (
              <h6 className="text-danger font-weight-bold text-center ">
                {error}
              </h6>
            )}
            <hr className={style.divider}></hr>
            <div className={style.uploadedDoc}>
              <div className={style.uploadedDocHeader}>
                <h6>Deal Slip</h6>
              </div>

              <div className={style.uploadedItem}>
                {fileToUpload && (
                  <>
                    <p>
                      {fileToUpload && fileToUpload[0].name}
                      <DeleteIcon
                        className={style.deleteIcon}
                        onClick={handleRemoveFile}
                      />
                    </p>
                  </>
                )}
              </div>
            </div>

            <button
              className="d-none"
              type="button"
              data-toggle="modal"
              data-target="#feedBackModal"
              ref={dismissRef}
            ></button>
            <button
              className={`btn advancly-btn btn-lgtransition-3d-hover ${style.sendDocument}`}
              onClick={sendDocumentToAdmin}
              disabled={!fileToUpload}
            >
              Send Document
              {isSendingToAdmin && <Loader />}
            </button>
          </div>
        </div>
      </div>

      <CommentsModal comments={deal_slip_comments} />
    </div>
  );
};

export default DownloadUploadDealSlip;
