import React, { Fragment, useRef, useState } from "react";
import { postDataWithDotNet } from "../../apis/dotNetApiMethods";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "./../../helpers/yupValidators";
import * as yup from "yup";
import { errorHandler } from "./../../helpers/errorHandler";
import { apiEndpoints } from "./../../apis/apiEndpoints";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import Loader from "./../Loader/Loader.component";
import AccountSuccessModal from "./AccountSuccessModal.component";
import useCountryBanksList from "../../custom-hooks/useCountryBanksList";
import PageLoader from "./../PageLoader/PageLoader.component";
import CustomSelectDropdown from "./../CustomHTMLElements/CustomSelectDropdown";
import { useAuth } from "../../Context/auth.context";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  accountNumber: yupValidators.accountNumber,
  bankCode: yupValidators.bankCode,
});

export default function AccountSearchForm() {
  const { aggregatorDetails } = useAuth();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const resultModal = useRef();

  const history = useHistory();
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ accountNumber, bankCode }) => {
    setLoading(true);
    setError(false);
    setSuccess(false);

    const reqBody = {
      bank_code: bankCode,
      bank_account_num: accountNumber,
    };

    try {
      const data = await postDataWithDotNet(
        apiEndpoints.accountNumberValidation,
        reqBody,
        true
      );
      setLoading(false);
      if (data.status === true) {
        setSuccess(data);
        setModalMessage(data);
        reset();
      } else {
        setError(errorHandler(data));
        setModalMessage(errorHandler(data));
      }
      resultModal.current.click();
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "AccountSearchForm.component.jsx" },
      });
      setLoading(false);
      if (error.response && error.response.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        setError(errorHandler(error));
        setModalMessage(errorHandler(error));
        resultModal.current.click();
      }
    }
  };
  const {
    data: bankListData,
    status: bankStatus,
    error: bankListError,
  } = useCountryBanksList({ country_code: aggregatorDetails.country_code });

  if (bankStatus === "loading") return <PageLoader />;
  return (
    <Fragment>
      {error && <p className="alert alert-danger small">{error}</p>}

      {bankListError && (
        <p className="alert alert-danger small">
          {errorHandler(bankListError)}
        </p>
      )}
      {success && (
        <div className="d-flex justify-content-between align-items-center alert alert-success small">
          <h5 className="font-weight-bold text-dark">Account Resolved</h5>
          <button
            className="btn btn-sm advancly-nobg-btn"
            data-toggle="modal"
            data-target="#accountSuccessModal"
          >
            View Result Again
          </button>
        </div>
      )}

      <p className="text-left">
        Enter the Account Number you want to search below
      </p>

      <button
        className="d-none"
        data-toggle="modal"
        data-target="#accountSuccessModal"
        ref={resultModal}
      ></button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <CustomInputField
              type="number"
              maxLength="10"
              minLength="10"
              reference={register}
              placeholder="Enter Account Number"
              label="Account Number"
              name="accountNumber"
              errors={errors.accountNumber}
            />
          </div>
          <div className="col-12 ">
            <CustomSelectDropdown
              reference={register}
              label="Bank"
              name="bankCode"
              errors={errors.bankCode}
            >
              <option value="">Select Bank</option>

              {bankListData && bankListData.length
                ? bankListData.map((bank) => (
                    <option key={bank.code} value={bank.code}>
                      {bank.name}
                    </option>
                  ))
                : null}
            </CustomSelectDropdown>
          </div>
          <div className="col-12">
            <button
              type="submit"
              className="btn advancly-btn btn-sm transition-3d-hover w-100"
              disabled={loading || bankListError}
            >
              Proceed
              {loading && <Loader />}
            </button>
          </div>
        </div>
      </form>
      <AccountSuccessModal modalMessage={modalMessage} />
    </Fragment>
  );
}
