import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getQueryLoanDetails = async ({ loan_ref }) => {
  const { data } = await getDataWithDotNet(
    `${apiEndpoints.queryLoanDetails}?externalId=${loan_ref}`
  );
  return data;
};

export default function useQueryLoanDetails({ loan_ref }) {
  return useQuery([{ loan_ref }, "getQueryLoanDetails"], getQueryLoanDetails, {
    enabled: loan_ref ? true : false,
  });
}
