import { useQuery } from "react-query";
import { postData } from "../apis/apiMethods";
import { apiEndpoints } from "../apis/apiEndpoints";

const validateBankAccount = async (query) => {
  const { bankCode, accountNumber } = query;
  const data = await postData(apiEndpoints.VALIDATE_BANK_ACCOUNT, {
    bank_code: bankCode,
    bank_account_num: accountNumber,
  });
  return data?.bank_account_name;
};
export default function useValidateBankAccount({ bankCode, accountNumber }) {
  return useQuery(
    [{ bankCode, accountNumber }, "validateBankAccount"],
    validateBankAccount,
    {
      enabled: bankCode && accountNumber?.length === 10 ? true : false,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
}
