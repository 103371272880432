import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getAggregatorProducts = async ({ country_code }) => {
  if (country_code === "" || country_code === null) {
    const data = await getDataWithDotNet(apiEndpoints.getAggregatorProducts);
    return data.data;
  } else {
    const data = await getDataWithDotNet(
      `${apiEndpoints.getAggregatorProducts}?country_code=${country_code}`
    );
    return data.data;
  }
};

export default function useAggregatorProducts({ country_code }) {
  return useQuery(
    [{ country_code }, "getAggregatorProducts"],
    getAggregatorProducts,
    {
      refetchOnWindowFocus: false,
    }
  );
}
