import React from "react";
import styles from "./OnboardingStep.module.scss";
import { Link } from "react-router-dom";

export default function OnboardingStep({
  activeButton,
  num,
  title,
  text,
  buttonLink,
  stepCompleted,
}) {
  return (
    <div className={styles.stepWrapper}>
      <div className={styles.stepInnerWrapper}>
        <div
          className={`${styles.stepBox} ${
            stepCompleted && styles.stepCompleted
          }`}
        >
          {stepCompleted ? "✔" : num}
        </div>
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{text}</div>
        </div>
      </div>
      {activeButton ? (
        <div className={styles.button}>
          <Link
            to={buttonLink}
            type="button"
            className="btn advancly-btn btn-sm transition-3d-hover"
          >
            {stepCompleted ? "View" : "Proceed"}
          </Link>
        </div>
      ) : (
        <div className={styles.button}>
          <span type="button" className="btn advancly-grey-btn btn-sm disabled">
            {stepCompleted ? "View" : "Proceed"}
          </span>
        </div>
      )}
    </div>
  );
}
