import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getBorrowerDetails = async ({ searchString }) => {
  let queryParams = `?${searchString}`;
  const data = await getDataWithDotNet(
    `${apiEndpoints.getBorrowerDetails}${queryParams && queryParams}`
  );
  return data.data;
};

export default function useBorrowerDetails({ searchString }) {
  return useQuery(
    [{ searchString }, "getBorrowerDetails"],
    getBorrowerDetails,
    {
      refetchOnWindowFocus: false,
    }
  );
}
