import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getInvestorGraph = async ({ aggregatorId, filter }) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.AGGREGATOR_INVESTORS_GRAPH}?aggregatorId=${aggregatorId}&filter=${filter}`
  );
  return data;
};

export default function useInvestorGraph({ aggregatorId, filter }) {
  return useQuery(
    [{ aggregatorId, filter }, "getInvestorGraph"],
    getInvestorGraph,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000,
    }
  );
}
