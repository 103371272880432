import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import UpdateAnIndividualBatchLoanRecord from "./UpdateAnIndividualBatchLoanRecord.component";
import UpdateACorporateBatchLoanRecord from "./UpdateACorporateBatchLoanRecord.component";

const UpdateABatchLoanRecordModal = ({ loanToBeUpdated, fetchNewData }) => {
  useEffect(() => {
    if (loanToBeUpdated.status === 3) {
      dismissRef.current.click();
    }
    // eslint-disable-next-line
  }, []);

  const scrollToTop = () => {
    document.getElementById("topOfPage").scrollIntoView({
      behavior: "smooth",
    });
  };

  const dismissRef = useRef();
  return (
    <div
      className="modal fade"
      id="updateABatchLoanRecordModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="updateABatchLoanRecordModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0 pb-0">
            <h6 className="page-subtitle text-center w-100">Update Loan</h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            ></button>
          </div>
          <div className="modal-body">
            <h6 className="font-weight-bold">Loan ID: {loanToBeUpdated.id}</h6>
            <div id="topOfPage">
              {loanToBeUpdated.borrowerType && (
                <div className="form-group">
                  {(() => {
                    switch (loanToBeUpdated.borrowerType) {
                      case "Individual":
                        return (
                          <UpdateAnIndividualBatchLoanRecord
                            loanToBeUpdated={loanToBeUpdated}
                            scrollToTop={scrollToTop}
                            dismissRef={dismissRef}
                            fetchNewData={fetchNewData}
                          />
                        );
                      case "Corporate":
                        return (
                          <UpdateACorporateBatchLoanRecord
                            loanToBeUpdated={loanToBeUpdated}
                            scrollToTop={scrollToTop}
                            dismissRef={dismissRef}
                            fetchNewData={fetchNewData}
                          />
                        );
                      default:
                        return null;
                    }
                  })()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(UpdateABatchLoanRecordModal);
