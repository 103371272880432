import React, { Fragment, useEffect, useState } from "react";
import WalletDisbursmentDeclineComment from "./WalletDisbursmentDeclineComment";
import usePendingDisbursment from "../../custom-hooks/usePendingDisbursment";
import { errorHandler } from "../../helpers/errorHandler";
import moment from "moment";
import { formatMoney } from "../../helpers/formatter";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import TableDataLoading from "../../components/TableDataLoading/TableDataLoading.component";
import WalletDisbursementApprovalSwitch from "./WalletDisbursementApprovalSwitch";
import { appInsights } from "../../components/AppInsight/AppInsight";

const WalletDisbursement = () => {
  // const [searchValue, setSearchValue] = useState("");

  const [pendingUpdate, setPendingUpdate] = useState("");
  const {
    data: pendingDisbursments,
    error: pendingError,
    status: pendingStatus,
  } = usePendingDisbursment();
  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletDisbursement.jsx",
      isLoggedIn: true,
    });
  }, []);
  if (pendingError)
    return (
      <p className="alert alert-danger small text-center">
        {errorHandler(pendingError)}
      </p>
    );
  return (
    <Fragment>
      {/* <div className="">
        <form>
          <div className="d-flex flex-wrap align-items-center justify-content-start  custom-search-input mb-2">
            <div className="input-group mb-2" style={{ maxWidth: "200px" }}>
              <input
                type="text"
                className="search-query form-control"
                placeholder="Search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <span className="fa fa-search"></span>
            </div>
            <div className="form-group mr-3">
              <div className="input-group mt-2">
                <label className="mr-2 mt-2">From </label>
                <input
                  type="date"
                  name="fromDate"
                  className="form-control"
                  // value={dueLoansState.fromDate}
                  // onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="form-group mr-3 mt-2">
              <div className="input-group">
                <label className="mr-2 mt-2">To </label>
                <input
                  type="date"
                  className="form-control"
                  name="toDate"
                  // value={dueLoansState.toDate}
                  // onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="">
              <button
                type="submit"
                className="btn btn-primary btn-sm mb-2"
                value="submit"
                //   disabled={status === "loading"}
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </div> */}

      <div>
        <div className="table-responsive">
          <table className="table ">
            <thead className="bg-lighter-secondary font-weight-bold">
              <tr className="font-weight-bold bg-lighter-grey">
                <th>Recipient</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Reference</th>
                <th colSpan="2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {pendingStatus === "loading" ? (
                <TableDataLoading />
              ) : pendingDisbursments && pendingDisbursments?.length ? (
                <Fragment>
                  {pendingDisbursments?.map((disbursement, _id) => {
                    const {
                      recipient_account_name,
                      amount,
                      currency,
                      maker_checker_status,
                      maker_checker_reference,
                      pub_date,
                    } = disbursement;
                    return (
                      <tr key={_id}>
                        <td>
                          {recipient_account_name &&
                          recipient_account_name.length > 0
                            ? recipient_account_name
                            : "Not Found"}
                        </td>
                        <td>{moment(pub_date).format("DD-MM-YYYY")}</td>
                        <td>{formatMoney(amount, currency)} </td>
                        <td
                          className={
                            maker_checker_status === "Pending"
                              ? "text-warning  "
                              : "text-dark"
                          }
                        >
                          {maker_checker_status}
                        </td>
                        <td>{maker_checker_reference}</td>

                        <td
                          className="text-success cursor-pointer"
                          data-toggle="modal"
                          data-target="#approvalDisbursmentModal"
                          onClick={() => setPendingUpdate(disbursement)}
                        >
                          Accept
                        </td>
                        <td
                          className="text-danger cursor-pointer"
                          data-toggle="modal"
                          data-target="#declineDisbursmentModal"
                          onClick={() => setPendingUpdate(disbursement)}
                        >
                          {" "}
                          Decline
                        </td>
                      </tr>
                    );
                  })}
                </Fragment>
              ) : (
                <NoTableDataAvailable text="There is no Pending Disbursment" />
              )}
            </tbody>
          </table>
        </div>
        <WalletDisbursementApprovalSwitch pendingUpdate={pendingUpdate} />

        <WalletDisbursmentDeclineComment pendingUpdate={pendingUpdate} />
      </div>
    </Fragment>
  );
};

export default WalletDisbursement;
