import React from "react";
import moment from "moment";
import { formatMoney } from "../../helpers/formatter";
import { attachClassNameToTag } from "../../helpers/attachClassNameToTag";
import { accurateQuantifier } from "../utility";

export default function EachLoanRow({ data: loans, profileDetails }) {
  const {
    loan_ref,
    loan_amount,
    loan_interest_amount,
    loan_amount_repay,
    loan_due_date,
    loan_status,
    loan_repayment_balance,
    loan_tenure,
    currency,
  } = loans;
  const { last_name, first_name } = profileDetails;
  return (
    <tr>
      <td className="text-uppercase">{loan_ref}</td>
      <td className="text-capitalize">
        {first_name && first_name.toLowerCase()}{" "}
        {last_name && last_name.toLowerCase()}
      </td>
      <td>{formatMoney(loan_amount, currency)}</td>
      <td>{accurateQuantifier(loan_tenure, "day")}</td>
      <td>{formatMoney(loan_interest_amount, currency)}</td>
      <td>
        {formatMoney(
          Number(loan_amount_repay) - Number(loan_repayment_balance),
          currency
        )}
      </td>
      <td>{moment(loan_due_date).format("DD-MM-YYYY")}</td>
      <td>
        <span className={attachClassNameToTag(loan_status)}>{loan_status}</span>
      </td>
    </tr>
  );
}
