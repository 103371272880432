import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getDisbursementDetails = async ({
  searchValue,
  page,
  PageSize,
  fromdate,
  todate,
}) => {
  let queryParams = `?page=${page}&PageSize=${PageSize}`;
  if (fromdate) {
    queryParams = `${queryParams}&fromdate=${fromdate}`;
  }
  if (todate) {
    queryParams = `${queryParams}&todate=${todate}`;
  }
  if (searchValue) {
    queryParams = `${queryParams}&searchvalue=${searchValue}`;
  }
  const data = await getDataWithDotNet(
    `${apiEndpoints.getAllDisbursement}${queryParams}`
  );
  return data.data;
};

export default function useDisbursementDetails({
  searchValue,
  page,
  PageSize,
  fromdate,
  todate,
}) {
  return useQuery(
    [
      { searchValue, page, PageSize, fromdate, todate },
      "getDisbursementDetails",
    ],
    getDisbursementDetails,
    {
      refetchOnWindowFocus: false,
    }
  );
}
