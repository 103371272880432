import { useState, Fragment } from "react";
import { useLocation } from "react-router-dom";
import InvestorNairaInvestment from "./InvestorNairaInvestment";
import InvestorDollarInvestment from "./InvestorDollarInvestment";
import ToggleTab from "../../../../components/ToggleTab/ToggleTab.component";
import BackButtonComponent from "../../../../components/BackButton/BackButton.component";

const EachInvestorInvestment = () => {
  const [activeTab, setActiveTab] = useState("naira-funds");

  const location = useLocation();
  const { id, data, investment_product_id, investment_product_name } =
    location?.state;
  return (
    <Fragment>
      <div className="animated fadeInRight">
        <div className="d-flex flex-row  mt-2 mb-1">
          <h3 className="page-title d-flex align-items-center">
            <BackButtonComponent /> {investment_product_name}
          </h3>
        </div>
        <div className="card">
          <div className="card-body p-3">
            <div className="alignToggleTabItems mb-3">
              <ToggleTab
                text="Naira Fund"
                id="naira-funds"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <ToggleTab
                text="Dollar Fund"
                id="dollar-funds"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>

            <div>
              {(() => {
                switch (activeTab) {
                  case "naira-funds":
                    return (
                      <InvestorNairaInvestment
                        id={id}
                        data={data}
                        investment_product_id={investment_product_id}
                      />
                    );

                  case "dollar-funds":
                    return (
                      <InvestorDollarInvestment
                        id={id}
                        data={data}
                        investment_product_id={investment_product_id}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EachInvestorInvestment;
