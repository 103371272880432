import React from "react";
import useAggregatorAccounts from "../../custom-hooks/useAggregatorAccounts";
import useBanksList from "../../custom-hooks/useBanksList";
import Loader from "../Loader/Loader.component";

const BusinessBankInfo = () => {
  const { data: banks, status: banksStatus } = useBanksList();
  const {
    data: aggregatorAccounts,
    status: aggregatorAccountsStatus,
    error: aggregatorAccountsError,
  } = useAggregatorAccounts();
  const hasAccount = aggregatorAccounts ? aggregatorAccounts.length > 0 : false;
  const bankAccount = hasAccount ? aggregatorAccounts[0] : null;

  return (
    <div className="card">
      <div className="card-body p-4">
        <h5 className="page-subtitle">Banking Information</h5>
        {aggregatorAccountsStatus === "loading" || banksStatus === "loading" ? (
          <div className="d-flex justify-content-center">
            <Loader />
          </div>
        ) : aggregatorAccountsError ? (
          <h5 className="text-danger text-center font-weight-bold">
            An error occured
          </h5>
        ) : (
          <form className="form mt-3">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <div>
                  <label id="bankNameLabel" className="form-label">
                    Bank name
                  </label>
                  <div className="form-group">
                    {/* {hasAccount ? (
                      <select
                        className="form-control"
                        name="bankName"
                        // disabled
                      >
                        <option>{bankAccount?.bank_name}</option>
                      </select>
                    ) : ( */}
                    <select className="form-control" name="bankName">
                      <option>{bankAccount?.bank_name}</option>

                      {banks && banks.length
                        ? banks.map((data, index) => {
                            return (
                              <option value={data?.code} key={index}>
                                {data?.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    {/* } */}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div>
                  <label id="bankAccountNumLabel" className="form-label">
                    Business Account Number
                  </label>
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      name="bankAccountNum"
                      placeholder="Enter business bank account number"
                      defaultValue={
                        hasAccount ? bankAccount?.bank_account_num : ""
                      }
                      // disabled={hasAccount}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div>
                  <label id="bankAccountNameLabel" className="form-label">
                    Business Account Name
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="bankAccountName"
                      readOnly
                      defaultValue={
                        hasAccount ? bankAccount?.bank_account_name : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex justify-content-end mt-3 flex-wrap">
                  <button
                    type="button"
                    disabled
                    className="btn advancly-nobg-btn btn-sm mr-3 transition-3d-hover"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled
                    className="btn advancly-btn btn-sm transition-3d-hover"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default BusinessBankInfo;
