import React from "react";
import moment from "moment";
import { formatMoney } from "../../helpers/formatter";

export default function EachRepayment({ data }) {
  const {
    loan_ref,
    borrower_full_name,
    repayment_amount,
    loan_due_date,
    loan_outstanding,
    product_name,
    currency,
  } = data;
  return (
    <tr>
      <td>{loan_ref}</td>
      <td className="text-capitalize">
        {borrower_full_name && borrower_full_name.toLowerCase()}
      </td>
      <td>{product_name}</td>
      <td>{formatMoney(repayment_amount, currency)}</td>
      <td>{formatMoney(loan_outstanding, currency)}</td>
      <td>{moment(loan_due_date).format("DD-MM-YYYY")}</td>
    </tr>
  );
}
