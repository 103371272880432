import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getDueRepaymentDetails = async ({ Page, PageSize, currency }) => {
  let queryParams = `?Page=${Page}&PageSize=${PageSize}`;
  if (currency) {
    queryParams = `${queryParams}&Currency=${currency}`;
  }
  const data = await getDataWithDotNet(
    `${apiEndpoints.getAllDueRepayments}${queryParams && queryParams}`
  );
  return data;
};

export default function useDueRepaymentDetails({ Page, PageSize, currency }) {
  return useQuery(
    [{ Page, PageSize, currency }, "getDueRepaymentDetails"],
    getDueRepaymentDetails,
    {
      refetchOnWindowFocus: false,
    }
  );
}
