import React from "react";
//@ts-ignore
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import "./Paginator.styles.scss";

interface IPaginatorProps {
  size: number;
  page: number;
  count: number;
  changeCurrentPage: (page: number) => void;
}

const Paginator: React.FC<IPaginatorProps> = ({
  size,
  page,
  count,
  changeCurrentPage,
}) => {
  return (
    <div className="custom-paginator px-3 mt-3">
      <div>
        <p className="pagination-label">
          Showing Page {page} of {Math.ceil(count / size)}
        </p>
      </div>
      <Pagination
        currentPage={page}
        totalSize={count}
        sizePerPage={size}
        changeCurrentPage={changeCurrentPage}
        numberOfPagesNextToActivePage={2}
        theme="bootstrap"
        previousPageText="Prev"
        nextPageText="Next"
      />
    </div>
  );
};

export default Paginator;
