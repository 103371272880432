import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getSearchForBorrower = async ({ search_keywords }) => {
  const data = await getData(
    `${apiEndpoints.SEARCH_FOR_BORROWER}?search_param=${search_keywords}`
  );
  return data?.data;
};

export default function useSearchForBorrower({ search_keywords }) {
  return useQuery(
    [{ search_keywords }, "getSearchForBorrower"],
    getSearchForBorrower,
    { enabled: search_keywords?.length >= 3 ? true : false }
  );
}
