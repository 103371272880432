import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getAutoPaidRepaymentdetails = async ({ Page, PageSize }) => {
  let queryParams = `?Page=${Page}&PageSize=${PageSize}&status=600`;

  const data = await getDataWithDotNet(
    `${apiEndpoints.getAllClosedLoans}${queryParams && queryParams}`
  );
  return data;
};

export default function useAutoPaidRepaymentdetails({ Page, PageSize }) {
  return useQuery(
    [{ Page, PageSize }, "getAutoPaidRepaymentdetails"],
    getAutoPaidRepaymentdetails,
    {
      refetchOnWindowFocus: false,
    }
  );
}
