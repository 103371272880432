import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getDashboardBorrowersGraph = async ({ aggregatorId, filter }) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.dashboardBorrowersGraph}?aggregatorId=${aggregatorId}&filter=${filter}`
  );
  return data;
};

export default function useDashboardBorrowerGraph({ aggregatorId, filter }) {
  return useQuery(
    [{ aggregatorId, filter }, "getDashboardBorrowersGraph"],
    getDashboardBorrowersGraph,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000,
    }
  );
}
