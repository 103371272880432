import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getAggregatorDetails = async () => {
  const data = await getDataWithDotNet(apiEndpoints.getAggregatorDetails);
  return data.data;
};

export default function useAggregatorDetails() {
  return useQuery(["getAggregatorDetails"], getAggregatorDetails, {
    refetchOnWindowFocus: false,
  });
}
