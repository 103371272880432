import { FC, useState } from "react";
import styles from "./UploadedDocument.module.scss";
import { ReactComponent as Folder } from "../../svg/icons/folder.svg";
import { ReactComponent as Files } from "../../svg/icons/files.svg";
import { ReactComponent as NoFillFiles } from "../../svg/icons/noFillsFiles.svg";
import { ReactComponent as Bin } from "../../svg/icons/bin.svg";
import { ReactComponent as Download } from "../../svg/icons/download-icon.svg";
import { ReactComponent as CheckedTick } from "../../svg/icons/green-check-tick.svg";
import { getData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { isBase64 } from "../../helpers/getExactValueToSend";
import Loader from "../Loader/Loader.component";
import LoaderColored from "../Loader/LoaderColored";
import { appInsights } from "../AppInsight/AppInsight";

interface IDeleteUploadedDocument {
  name: string;
  deleteFile: () => void;
  hideDeleteButton?: boolean;
  offerLetter: boolean | undefined | null;
}

export const DeleteUploadedDocument: FC<IDeleteUploadedDocument> = ({
  name,
  deleteFile,
  hideDeleteButton,
  offerLetter,
}) => {
  return (
    <div className={styles.uploadedDocCard}>
      <div className={styles.left}>
        {!offerLetter && <Folder />}
        {offerLetter && <NoFillFiles />}
        <span>{name}</span>
      </div>
      {hideDeleteButton ? null : (
        <div className={styles.right} onClick={deleteFile}>
          <Bin />
        </div>
      )}
    </div>
  );
};

interface IDownloadUploadedDocument {
  name: string;
  pathname: string;
}

export const DownloadUploadedDocument: FC<IDownloadUploadedDocument> = ({
  name,
  pathname,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const prepareDownloadLink = (content: string, fileName: string) => {
    const appendSource = fileName.endsWith(".pdf")
      ? "application/pdf"
      : "application/msword";

    const linkSource = `data:${appendSource};base64,${content}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setIsDownloading(false);
  };

  const downloadFile = async (pathname: string) => {
    if (isBase64(pathname)) {
      return prepareDownloadLink(pathname, name);
    }
    try {
      setIsDownloading(true);
      const data = await getData(
        `${apiEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${pathname}`,
        true
      );
      prepareDownloadLink(data.base64_string, pathname);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UploadedDocumentCard.component.tsx",
        },
      });
      setIsDownloading(false);
    }
  };
  return (
    <div className={styles.uploadedDocCard}>
      <div className={styles.left}>
        <Folder />
        <span>{name}</span>
      </div>
      <div
        className={`${styles.right} ${isDownloading ? "disabled" : ""}`}
        onClick={() => downloadFile(pathname)}
      >
        {isDownloading ? <LoaderColored /> : <Download />}
      </div>
      <span className="ml-2">
        <CheckedTick />
      </span>
    </div>
  );
};

interface IDownloadOfferLetterDocument {
  name: string;
  pathname: string;
  documentName: string;
  viewHistory: boolean | undefined | null;
}

export const DownloadOfferLetterDocument: FC<IDownloadOfferLetterDocument> = ({
  name,
  pathname,
  documentName,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const prepareDownloadLink = (content: string, fileName: string) => {
    const appendSource = fileName.endsWith(".pdf")
      ? "application/pdf"
      : "application/msword";

    const linkSource = `data:${appendSource};base64,${content}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setIsDownloading(false);
  };

  const downloadFile = async (pathname: string) => {
    if (isBase64(pathname)) {
      return prepareDownloadLink(pathname, name);
    }
    try {
      setIsDownloading(true);
      const data = await getData(
        `${apiEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${pathname}`,
        true
      );
      prepareDownloadLink(data.base64_string, pathname);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UploadedDocumentCard.component.tsx",
        },
      });
      setIsDownloading(false);
    }
  };
  return (
    <div className={styles.uploadedDocCard}>
      <div className={styles.left}>
        <Files />
        <span>{documentName}</span>
      </div>
      <div
        className={`${styles.right} ${isDownloading ? "disabled" : ""}`}
        onClick={() => downloadFile(pathname)}
      >
        {isDownloading ? <Loader /> : <Download />}
      </div>
    </div>
  );
};
