import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextArea from "../CustomHTMLElements/CustomTextArea";

const schema = yup.object().shape({
  narrationText: yup
    .string()
    .trim()
    .required("Please enter a Narration")
    .min(2, "Narration must have at least 2 characters"),
});

const SendMoneyNaration = ({
  sendMoneySteps,
  setSendMoneySteps,
  step,
  setNarration,
}) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = ({ narrationText }) => {
    setNarration(narrationText);
    setSendMoneySteps(sendMoneySteps + step);
  };

  return (
    <Fragment>
      <div className="modal-content">
        <div className="modal-header border-bottom-0">
          <h6 className="modal-title">Enter a Narration</h6>
          <button
            type="button"
            className="close "
            data-dismiss="modal"
            onClick={() => {
              setSendMoneySteps(1);
            }}
          >
            {" "}
            &times;
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row">
              <small
                className="color-mid-blue text-right   cursor-pointer font-weight-bold pl-2 pb-2"
                onClick={() => {
                  setSendMoneySteps(sendMoneySteps - step);
                }}
              >
                &#8592; Go back
              </small>
            </div>
            <div className="form-group">
              <CustomTextArea
                maxLength="128"
                reference={register}
                placeholder="Add a Narration"
                label="Add Narration"
                name="narrationText"
                errors={errors.narrationText}
              />
              <div className="row">
                <div className="col-12 pb-4 pt-4">
                  <button className="btn advancly-btn btn-sm w-100">
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default SendMoneyNaration;
