import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { errorHandler } from "../../helpers/errorHandler";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import Loader from "../Loader/Loader.component";
import { useQueryCache } from "react-query";
import useAggregatorRoles from "../../custom-hooks/useAggregatorRoles";
import { appInsights } from "../AppInsight/AppInsight";

const EditUserRoleModal = ({ userToUpdate }: any) => {
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState("");
  const queryCache = useQueryCache();
  const { register, handleSubmit, reset, watch } = useForm();
  const watchCheckboxRole = watch("user_roles", false);
  const dismissModal = () => {
    dismissRef?.current?.click();
  };

  const onSubmitHandler = async ({ user_roles }: any) => {
    setLoading(true);
    setError(false);

    const reqBody = {
      userID: userToUpdate.userId,
      roleName: user_roles,
    };
    try {
      const res = await postData(
        apiEndpoints.ASSIGN_EDIT_AGGREGATOR_ROLE,
        reqBody,
        true
      );
      if (res.status === true) {
        setSuccess("User Role has been assigned .");
        queryCache.invalidateQueries("getAggregatorUsers");
        reset();
        setTimeout(() => {
          dismissModal();
          setSuccess("");
        }, 3000);
      } else {
        setError(res.message);
      }
      setLoading(false);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "EditUserRoleModal.tsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };
  const { data: aggregatorRoles } = useAggregatorRoles();

  return (
    <div
      className="modal fade"
      id="editUserRoleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="addNewUserModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0" id="topOfPage">
            <div className="row">
              <h5 className="page-title col-12 ">Assign User Role</h5>
            </div>
            <button
              type="button"
              className="close d-none"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            ></button>
          </div>
          <div className="modal-body mb-0 mt-0">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              {error && <p className="alert alert-danger small">{error}</p>}
              {success && (
                <p className="alert alert-success small">{success}</p>
              )}
              <div className="row ">
                {aggregatorRoles?.map((role: any, index: number) => (
                  <div className="col-12" key={index}>
                    <label htmlFor="aggregatorInitiator">
                      <input
                        type="checkbox"
                        name="user_roles"
                        ref={register}
                        value={role?.value}
                        defaultChecked={userToUpdate?.roles.includes(
                          role?.value
                        )}
                      />{" "}
                      {role?.name}{" "}
                    </label>
                  </div>
                ))}
              </div>
              <div className="col-12">
                <button
                  className="btn advancly-btn btn-sm col-12 transition-3d-hover mt-2 mb-0 "
                  disabled={watchCheckboxRole.length === 0}
                >
                  Submit
                  {loading && <Loader />}
                </button>{" "}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserRoleModal;
