import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getWalletTransactionLog = async ({ transaction_ref }) => {
  let queryParams = `?transaction_reference=${transaction_ref}`;

  const data = await getData(
    `${apiEndpoints.WALLET_TRANSACTION_LOG}${queryParams} `
  );

  return data.data;
};

export default function useWalletTransactionLog({ transaction_ref }) {
  return useQuery(
    [
      {
        transaction_ref,
      },
      "getWalletTransactionLog",
    ],
    getWalletTransactionLog,
    {
      enabled: transaction_ref ? true : false,
      refetchOnWindowFocus: false,
    }
  );
}
