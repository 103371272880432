import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
// import PageLoader from "../../../../components/PageLoader/PageLoader.component";
import EligibilityTestField from "./EligibilityTestField";
import styles from "../../GetStarted.module.scss";
import PageLoaderAnimation from "../../../../components/PageLoader/PageLoaderAnimation";
import OnboardingBackWordButton from "../../../../components/OnboardingBackWordButton/OnboardingBackWordButton";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { useForm } from "react-hook-form";

export default function EligibilityTestStepOne({
  setCurrentStep,
  status,
  onOptionAnswerChanged,
  onMultipleAnswerChanged,
  onTextAnswerChanged,
  questions,
  answers,
  generalEligbilityAnswers,
  setGeneralEligbilityAnswers,
  questionsAnswered,
  setQuestionsAnswered,
}) {
  const { register, handleSubmit, setValue } = useForm();

  const history = useHistory();

  const IsSparkForBusiness = questions?.find((question) => {
    return (
      !question?.isGeneral &&
      question?.productTypes.length > 0 &&
      question?.productTypes.length !== 0 &&
      question?.productTypes.some(({ product }) => {
        return product === "SparkLoanForBusiness";
      })
    );
  });

  const IsSparkForCustomers = questions?.find((question) => {
    return (
      !question?.isGeneral &&
      question?.productTypes.length > 0 &&
      question?.productTypes.length !== 0 &&
      question?.productTypes.some(({ product }) => {
        return product === "SparkLoanForCustomers";
      })
    );
  });
  useEffect(() => {
    // this effect is only use to store state of user information
    //This was done so when the user is to go back after an error the filled value would be there
    if (generalEligbilityAnswers) {
      let dataObjects = Object.entries(generalEligbilityAnswers);
      for (const [key, value] of dataObjects) {
        setValue(key, value);
      }
    }
  }, [generalEligbilityAnswers, setValue]);
  const onSubmit = (data) => {
    // if user has questions for spark proceeds to step 2 else  step 3 the user must have a product
    if (IsSparkForBusiness || IsSparkForCustomers) {
      setGeneralEligbilityAnswers(data);
      setCurrentStep(2);
    } else {
      setGeneralEligbilityAnswers(data);
      setCurrentStep(3);
    }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "EligibilityTestStepOne.jsx",
      isLoggedIn: true,
    });
  }, []);
  if (status === "loading")
    return (
      <div className="d-flex justify-content-center align-items-center my-auto">
        {" "}
        <PageLoaderAnimation />
      </div>
    );
  return (
    <div>
      <OnboardingBackWordButton />
      <div className="d-flex justify-content-between align-items-center w-100 mb-4 mt-5 flex-wrap">
        <h3 className="page-title mb-0">General Eligibility Test</h3>
        <div className={styles.step}>Step 2 of 3</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {questions && questions.length ? (
            questions
              .sort((a, b) => a.position - b.position)
              .map((obj, _id) => {
                const {
                  conditionalAnswerId,
                  conditionalQuestionId,
                  id,
                  isConditional,
                  isMandatory,
                  question,
                  questionAnswerType,
                  questionAnswers,
                  isGeneral,
                } = obj;
                return (
                  <div className="col-xs-12  col-md-12  pl-0 pr-0  " key={_id}>
                    {isGeneral && !isConditional ? (
                      <EligibilityTestField
                        id={id}
                        isMandatory={isMandatory}
                        question={question}
                        questionAnswerType={questionAnswerType}
                        questionAnswers={questionAnswers}
                        onOptionAnswerChanged={onOptionAnswerChanged}
                        onMultipleAnswerChanged={onMultipleAnswerChanged}
                        onTextAnswerChanged={onTextAnswerChanged}
                        reference={register}
                        questionsAnswered={questionsAnswered}
                      />
                    ) : (
                      ""
                    )}
                    {isGeneral && isConditional && answers && answers.length > 0
                      ? // eslint-disable-next-line array-callback-return
                        answers
                          .filter(
                            (answer) =>
                              Number(answer.questionId) ===
                                conditionalQuestionId &&
                              Number(
                                answer.questionAnswerId.includes(
                                  conditionalAnswerId
                                )
                              )
                          )
                          .map((answer) => {
                            return (
                              <div key={_id}>
                                <EligibilityTestField
                                  id={id}
                                  isMandatory={isMandatory}
                                  question={question}
                                  questionAnswerType={questionAnswerType}
                                  questionAnswers={questionAnswers}
                                  onOptionAnswerChanged={onOptionAnswerChanged}
                                  onMultipleAnswerChanged={
                                    onMultipleAnswerChanged
                                  }
                                  onTextAnswerChanged={onTextAnswerChanged}
                                  reference={register}
                                  questionsAnswered={questionsAnswered}
                                />
                              </div>
                            );
                          })
                      : ""}
                  </div>
                );
              })
          ) : (
            <div className="w-100 p-5">
              <h3 className="text-center">
                No General test questions available please proceed to the next
                step{" "}
              </h3>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end flex-wrap mt-3 mb-4 ">
            <button
              className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancel
            </button>
            <button
              className="btn advancly-btn btn-sm transition-3d-hover mt-2"
              type="submit"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
