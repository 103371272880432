import React from "react";
import { Bar } from "react-chartjs-2";
import { emptyBarChartOptions } from "../../../helpers/graphOptions";
import NoGraphData from "../../NoGraphData/NoGraphData.component";

export default function EmptyDoughnutChart() {
  const data = {
    labels: [""],
    datasets: [
      {
        data: [0],
        backgroundColor: ["#fff"],
        borderColor: ["#fff"],
        borderWidth: 0,
      },
    ],
  };
  return (
    <div>
      <NoGraphData />
      <Bar
        data={data}
        width={100}
        height={300}
        options={emptyBarChartOptions}
      />
    </div>
  );
}
