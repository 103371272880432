import React, { useRef, useState } from "react";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import CustomTextArea from "../CustomHTMLElements/CustomTextArea";
import ToggleTab from "../ToggleTab/ToggleTab.component";
import CommentsDocs from "./CommentsDocs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { errorHandler } from "../../helpers/errorHandler";
import Loader from "../Loader/Loader.component";
import Alert from "../Alert/Alert.component";
import { useQueryCache } from "react-query";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  comment: yup
    .string()
    .trim()
    .required("Please enter a comment")
    .min(5, "Comment must have at least 5 characters"),
});

const CommentsDocsUpload = ({ fileComments }) => {
  const [activeTab, setActiveTab] = useState("comments");
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const dismissRef = useRef();
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const queryCache = useQueryCache();

  const onSubmitHandler = async ({ comment }) => {
    setLoading(true);
    setError("");
    setSuccess("");

    let reqBody = {
      document_file_id: fileComments?.aggregator_document_id,
      comment: comment,
      document_type: 1,
    };
    try {
      const res = await postData(apiEndpoints.DOCUMENT_COMMENTS, reqBody, true);
      setLoading(false);
      reset();
      setSuccess(res.message);
      setError("");
      queryCache.invalidateQueries("getListOfDocumentsAlreadyUploaded");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "ChangePassword.component.jsx" },
      });
      setLoading(false);
      reset();
      setSuccess("");
      setError(errorHandler(error));
    }
  };

  return (
    <div
      id="commentsDocUploadModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="commentsDocsUploadModalLabel"
      aria-hidden="true"
      className="modal fade "
    >
      {" "}
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <div className="w-100">
              <div className="alignToggleTabItems centralize ">
                <ToggleTab
                  text="comments"
                  id="comments"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <ToggleTab
                  text="Add Comments"
                  id="add-comments"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
            </div>
          </div>
          <div className="modal-body mb-0 mt-0">
            {(() => {
              switch (activeTab) {
                case "comments":
                  return <CommentsDocs comments={fileComments?.comments} />;
                case "add-comments":
                  return (
                    <div>
                      {success && <Alert message={success} type="success" />}
                      {error && <Alert message={error} />}
                      <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <div className="mb-4">
                          <CustomTextArea
                            maxLength="128"
                            reference={register}
                            placeholder="Add a comment"
                            label="Add Comment"
                            name="comment"
                            errors={errors.comment}
                          />
                          <div></div>
                          <button
                            type="submit"
                            className={`create  btn advancly-btn  mt-1 `}
                          >
                            Add Comment {loading && <Loader />}
                          </button>
                        </div>
                      </form>
                    </div>
                  );

                default:
                  return;
              }
            })()}
          </div>
          <button
            type="button"
            className=" advancly-btn p-2 mb-2 w-70 m-1"
            data-dismiss="modal"
            aria-label="Close"
            ref={dismissRef}
          >
            <i className="fas fa-times-circle"></i>
            &nbsp; Close
          </button>
        </div>
      </div>
      {/* end of modal dialog */}
    </div>
  );
};

export default CommentsDocsUpload;
