import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "../../components/Loader/Loader.component";
import CustomTextArea from "../../components/CustomHTMLElements/CustomTextArea";
import { useQueryCache } from "react-query";
import { postDataWithDotNet } from "../../apis/dotNetApiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import { appInsights } from "../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  comment: yup
    .string()
    .trim()
    .required("Please enter a comment")
    .min(5, "Comment must have at least 5 characters"),
});

const SingleLoanCommentModal = ({
  loading,
  setLoading,
  setSuccess,
  setError,
  singleLoanToActionOn,
}) => {
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const queryCache = useQueryCache();
  const dismissRef = useRef();

  // Bulk Loan approval
  const onSubmit = async ({ comment }) => {
    setLoading(true);
    setError("");
    setSuccess("");
    const decisionStatus =
      singleLoanToActionOn.commentType === "approve"
        ? "2"
        : singleLoanToActionOn.commentType === "decline"
        ? "3"
        : null;

    let data = {
      status_id: String(decisionStatus),
      state_token: singleLoanToActionOn.state_token,
      aggregator_comment: comment,
    };
    try {
      const res = await postDataWithDotNet(
        "/eco/update_loan_status_aggregator/",
        data
      );
      setLoading(false);
      reset();
      window.scrollTo(0, 0);
      setSuccess(res.message);
      setError("");
      // Refetch Pending Loans Data
      queryCache.invalidateQueries("getPendingLoansDetails");
      dismissRef.current.click();
    } catch (err) {
      appInsights.trackException({
        exception: err,
        properties: {
          fileName: "SingleLoanCommentModal.component.jsx",
        },
      });
      setLoading(false);
      reset();
      window.scrollTo(0, 0);
      setSuccess("");
      setError(errorHandler(err));
      dismissRef.current.click();
    }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "SingleLoanCommentModal.component.jsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div
      className="modal fade"
      id="singleLoanCommentModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="singleLoanCommentModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <h6 className="text-center font-weight-bold text-capitalize">
              {singleLoanToActionOn?.commentType} Loan
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <CustomTextArea
                  maxLength="128"
                  reference={register}
                  placeholder="Add a comment"
                  label="Add Comment"
                  name="comment"
                  errors={errors.comment}
                />
                <div className="d-flex">
                  <button
                    type="button"
                    class="btn advancly-white-btn btn-sm transition-3d-hover w-50 mr-2"
                    data-dismiss="modal"
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn advancly-btn btn-sm transition-3d-hover w-50 ml-2"
                    type="submit"
                    disabled={loading}
                  >
                    Submit
                    {loading && <Loader />}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SingleLoanCommentModal;
