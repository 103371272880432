import { Fragment } from "react";
import Loader from "../../Loader/Loader.component";
import NoTableDataAvailable from "../../NoTableDataAvailable/NoTableDataAvailable.component";
import { accurateQuantifier } from "../../utility";

export default function MultipleLoanPreviewModal({
  borrowersToCreate,
  resetAllFields,
  success,
  fileUploadError,
  loading,
  submitBorrowers,
  redirectToLoanStatusPage,
  dismissPreviewModal,
}) {
  const uploadNewFile = () => {
    resetAllFields();
    dismissPreviewModal.current.click();
  };

  return (
    <Fragment>
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="multipleLoanPreviewModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="multipleLoanPreviewModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="page-subtitle">Review Upload</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => resetAllFields()}
                ref={dismissPreviewModal}
              ></button>
            </div>
            <div className="modal-body">
              {/* If there is no file error and a valid file and has been selected */}
              {borrowersToCreate && !fileUploadError && !success && (
                <Fragment>
                  <div className="table-responsive">
                    <table className="table table-white table-hover">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Status</th>
                          <th>Name</th>
                          <th>Borrower Type</th>
                          <th>BVN / RC Number</th>
                          <th>Email Address</th>
                          <th>Phone Number</th>
                          <th>Gender</th>
                          <th>Loan Amount</th>
                          <th>Loan Tenure</th>
                          {/* <th>Annual Interest Rate</th> */}
                          <th>Repayment Start Date</th>
                          {/* <th>Product ID</th>
                          <th>Sector ID</th> */}
                          <th>Reference Number</th>
                          <th>Address</th>
                          <th>Account Number</th>
                          <th>Bank Code</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Date of Birth</th>
                          <th>Country Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        {borrowersToCreate.length ? (
                          <Fragment>
                            {borrowersToCreate.map((borrower, _id) => {
                              const {
                                address,
                                // annualInterestRate,
                                bankAccountNumber,
                                bankCode,
                                borrowerType,
                                city,
                                companyName,
                                customerBVN,
                                dateofBirth,
                                repaymentStartDate,
                                emailAddress,
                                firstName,
                                lastName,
                                loanAmount,
                                phoneNumber,
                                // productID,
                                rcNumber,
                                state,
                                tenureInDays,
                                // sectorID,
                                aggregator_loan_ref,
                                gender,
                                rowStatus,
                                countryCode,
                              } = borrower;

                              const name = `${firstName} ${lastName}`;
                              return (
                                <tr key={_id}>
                                  <td>{_id + 1}</td>
                                  <td>
                                    {rowStatus.length ? (
                                      <ul className="list-unstyled">
                                        {[...new Set(rowStatus)].map(
                                          (row, _id) => {
                                            return (
                                              <li
                                                className="text-danger"
                                                key={_id}
                                              >
                                                {`Error - ${row}`}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      <span className="text-success">
                                        Correct data format
                                      </span>
                                    )}
                                  </td>
                                  <td>{name || companyName}</td>
                                  <td>
                                    {Number(borrowerType) === 1
                                      ? "Individual"
                                      : "Corporate"}
                                  </td>
                                  <td>{customerBVN || rcNumber}</td>
                                  <td>{emailAddress}</td>
                                  <td>{phoneNumber}</td>
                                  <td className="text-capitalize">{gender}</td>
                                  <td>{loanAmount}</td>
                                  <td>
                                    {accurateQuantifier(tenureInDays, "day")}
                                  </td>
                                  {/* <td>{`${annualInterestRate}%`}</td> */}
                                  <td>{repaymentStartDate.substr(0, 10)}</td>
                                  {/* <td>{productID}</td>
                                  <td>{sectorID}</td> */}
                                  <td>{aggregator_loan_ref}</td>
                                  <td>{address}</td>
                                  <td>{bankAccountNumber}</td>
                                  <td>{bankCode}</td>
                                  <td>{city}</td>
                                  <td>{state}</td>
                                  <td>{dateofBirth.substr(0, 10)}</td>
                                  <td>{countryCode}</td>
                                </tr>
                              );
                            })}
                          </Fragment>
                        ) : (
                          <NoTableDataAvailable />
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <span
                      onClick={uploadNewFile}
                      className="color-blue cursor-pointer"
                    >
                      Upload New File
                    </span>
                    <div>
                      <button
                        className="btn btn-sm advancly-nobg-btn mr-2"
                        onClick={() => {
                          resetAllFields();
                          dismissPreviewModal.current.click();
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        className="btn advancly-btn btn-sm transition-3d-hover"
                        disabled={loading}
                        type="submit"
                        onClick={submitBorrowers}
                      >
                        Request Loans {loading && <Loader />}
                      </button>
                    </div>
                  </div>
                </Fragment>
              )}

              {/* If there is no file error and a valid file and has been selected but there was an error while uploading */}
              {borrowersToCreate &&
                !success &&
                fileUploadError &&
                fileUploadError.length && (
                  <Fragment>
                    <p className="text-left text-danger">
                      The uploaded file contains some errors, see preview below.
                    </p>
                    <div className="table-responsive">
                      <table className="table table-white table-hover">
                        <thead>
                          <tr>
                            <th>Error(s)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fileUploadError.length ? (
                            <Fragment>
                              {fileUploadError.map((error, _id) => {
                                return (
                                  <tr key={_id}>
                                    <td>{error}</td>
                                  </tr>
                                );
                              })}
                            </Fragment>
                          ) : (
                            <NoTableDataAvailable text="No Error Information available" />
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <span
                        onClick={uploadNewFile}
                        className="color-blue cursor-pointer"
                      >
                        Upload New File
                      </span>
                      <div>
                        <button
                          className="btn btn-sm advancly-nobg-btn mr-2"
                          onClick={() => {
                            resetAllFields();
                            dismissPreviewModal.current.click();
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Fragment>
                )}

              {/* If there is no file error and a valid file and has been selected and the upload was succesful */}
              {borrowersToCreate && !fileUploadError && success && (
                <Fragment>
                  <h5 className="text-center text-success my-5 text-uppercase font-weight-bold">
                    {success}{" "}
                    <span
                      onClick={redirectToLoanStatusPage}
                      className="color-blue"
                    >
                      Click here
                    </span>{" "}
                    to view the status of your upload
                  </h5>
                </Fragment>
              )}

              {/* If there is no file error and a valid file has been selected and the upload was succesful but few roles have errors */}
              {borrowersToCreate && fileUploadError && success && (
                <Fragment>
                  <h5 className="text-center text-success my-5 text-uppercase font-weight-bold">
                    {success}{" "}
                    <span
                      onClick={redirectToLoanStatusPage}
                      className="color-blue"
                    >
                      Click here
                    </span>{" "}
                    to view the status of your upload
                  </h5>
                  <p className="text-left text-danger">
                    However, some rows contain errors, see preview below.
                  </p>
                  <div className="table-responsive">
                    <table className="table table-white table-hover">
                      <thead>
                        <tr>
                          <th>Error(s)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fileUploadError.length ? (
                          <Fragment>
                            {fileUploadError.map((error, _id) => {
                              return (
                                <tr key={_id}>
                                  <td>{error}</td>
                                </tr>
                              );
                            })}
                          </Fragment>
                        ) : (
                          <NoTableDataAvailable text="No Error Information available" />
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex mt-3">
                    <span
                      onClick={uploadNewFile}
                      className="color-blue cursor-pointer"
                    >
                      Upload New File
                    </span>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
