import React from "react";
import { useForm } from "react-hook-form";
import { errorHandler } from "../../../helpers/errorHandler";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";

const ChangeSingleSignatoryModal1 = ({
  usersData,
  usersStatus,
  usersError,
  changeSingleSignatory,
  setChangeSingleSignatory,
  setChangeSinglesSignatoryName,
  step,
  setChangeAddNewUserModal,
  signatories,
  signatoriesStatus,
  dismissRef,
}) => {
  const { handleSubmit, register, errors, watch } = useForm();
  const submitHandler = (data) => {
    const signatoriesBody = [{ email: data.changeSignatoryName, levelNo: 1 }];
    setChangeSinglesSignatoryName(signatoriesBody);
    setChangeSingleSignatory(changeSingleSignatory + step);
  };
  const watchChangeSignatoryNameChange = watch("changeSignatoryName");
  // const { data: users, status, error } = useAggregatorSignatoryUsers();
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Change Single Signatory Approval</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={() => {
            setChangeSingleSignatory(2);
          }}
        >
          &times;
        </button>
      </div>
      <div className="modal-body">
        <p className="pt-2">Select one of the following users as a signatory</p>
        <form onSubmit={handleSubmit(submitHandler)}>
          {usersStatus === "loading" && (
            <small className="text-primary">Loading...</small>
          )}
          {usersError && (
            <small className="text-dangeralert alert-danger small text-center">
              {errorHandler(usersError)}
            </small>
          )}
          <CustomSelectDropdown
            name="changeSignatoryName"
            reference={register({ required: true })}
          >
            {signatories && signatories[0]?.email && (
              <option value={signatories[0].email}>
                {signatories[0]?.email}
              </option>
            )}

            <option value={0}>None</option>
            {usersData?.map((user) => {
              const { id, firstName, lastName, email } = user;
              return (
                <option
                  key={id}
                  value={email}
                >{`${firstName} ${lastName}`}</option>
              );
            })}
          </CustomSelectDropdown>
          {errors.changeSignatoryName && (
            <p className="text-danger">Please select a name</p>
          )}
          <small
            className="color-mid-blue   row cursor-pointer font-weight-bold pl-3 "
            onClick={() => {
              setChangeAddNewUserModal(true);

              setChangeSingleSignatory(changeSingleSignatory - step);
            }}
          >
            Create a new User
          </small>
          <button
            className="btn advancly-btn btn-sm w-100 mt-3"
            disabled={!watchChangeSignatoryNameChange}
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangeSingleSignatoryModal1;
