import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getRecentInvestors = async ({
  currentCurrencyValue,
  activeProductName,
}) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.AGGREGATOR_RECENT__INVESTORS}?currency=${currentCurrencyValue}&product=${activeProductName}`
  );
  return data.data;
};

export default function useRecentInvestors({
  currentCurrencyValue,
  activeProductName,
}) {
  return useQuery(
    [{ currentCurrencyValue, activeProductName }, "getRecentInvestors"],
    getRecentInvestors,
    {
      enabled: currentCurrencyValue && activeProductName ? true : false,

      refetchOnWindowFocus: false,
      cacheTime: 1000,
    }
  );
}
