import React, { Fragment } from "react";
import { ReactComponent as DummyUser } from "../../svg/icons/user-fill.svg";
import styles from "./BorrowerProfileCard.module.scss";
import { getLast4 } from "../utility";
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";
import { withRouter } from "react-router-dom";
import ScoreCard from "../ScoreCard/ScoreCard.component";
import Loader from "../Loader/Loader.component";
import TableDataLoading from "./../TableDataLoading/TableDataLoading.component";
import { formatMoney } from "./../../helpers/formatter";

const BorrowerProfileCard = (props) => {
  const {
    currency,
    total_loan_repaid,
    total_loan_collected,
    // trust_score,
    borrower_type_value,
    city,
    state,
    residence_address,
    date_of_birth,
    my_biz_rc_num,
    first_name,
    last_name,
    photo_url,
    bvn_number,
    borrower_phone,
    gender,
    my_biz_name,
  } = props?.profileDetails;
  return (
    <div className="p-3">
      {/* <!-- Profile Card --> */}
      <div className="mb-4">
        <div className={styles.alignBusinessInfo}>
          <div className={styles.alignNameSection}>
            <div className={styles.profilePhoto}>
              {photo_url ? (
                <img
                  src={photo_url}
                  alt="Logo"
                  className={styles.profileImage}
                />
              ) : (
                <DummyUser
                  className={styles.profileImage}
                  title="No Photo Available"
                />
              )}
            </div>
            <Fragment>
              {props?.profileDetails ? (
                <div className={styles.nameSection}>
                  <h6 className={styles.name}>
                    {first_name && first_name.toLowerCase()}{" "}
                    {last_name && last_name.toLowerCase()}
                  </h6>
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center w-100">
                  <Loader />
                </div>
              )}
            </Fragment>
          </div>

          <ScoreCard
            title="Total Amount Repayed"
            value={formatMoney(total_loan_repaid, currency)}
            loading={!props?.profileDetails}
          />
          <ScoreCard
            title="Total Amount Disbursed"
            value={formatMoney(total_loan_collected, currency)}
            loading={!props?.profileDetails}
          />
          {/* <ScoreCard
            title="Trust Score"
            value={`${trust_score ? trust_score : 0}/10`}
            loading={!props?.profileDetails}
            formatValue={false}
          /> */}
        </div>
      </div>
      <hr />
      <h3 className="page-subtitle">Customer Information</h3>
      <div className="table-responsive">
        <table className="table">
          <thead className="bg-lighter-grey">
            <tr>
              <th>BVN</th>
              <th>Phone Number</th>
              <th>Sex</th>
              <th>Borrower Type</th>
              <th>Business Name</th>
              {borrower_type_value === "Corporate" ? <th>RC Number</th> : null}

              <th>Date of Birth</th>
              <th>Street Name</th>
              <th>City</th>
              <th>State</th>
            </tr>
          </thead>
          <tbody>
            {props?.profileDetails ? (
              <tr>
                <td>{`*******${getLast4(bvn_number)}`}</td>
                <td>{borrower_phone}</td>
                <td>{gender ? capitalizeFirstLetter(gender) : ""}</td>
                <td>{borrower_type_value ? borrower_type_value : "N/A"}</td>
                <td>{my_biz_name ? my_biz_name : "Not Found"}</td>

                {borrower_type_value === "Corporate" ? (
                  <td>{my_biz_rc_num ? my_biz_rc_num : "N/A"}</td>
                ) : null}
                <td>{date_of_birth ? date_of_birth.substring(0, 10) : ""}</td>
                <td>
                  {residence_address
                    ? capitalizeFirstLetter(residence_address)
                    : ""}
                </td>
                <td>{city ? capitalizeFirstLetter(city) : ""}</td>
                <td>{state ? capitalizeFirstLetter(state) : ""}</td>
              </tr>
            ) : (
              <TableDataLoading />
            )}
          </tbody>
        </table>
      </div>
      <hr />
    </div>
  );
};

export default withRouter(BorrowerProfileCard);
