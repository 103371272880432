import React, { useRef } from "react";
import ChangeSingleSignatoryComponentPage from "./ChangeSingleSignatoryComponentPage";

const ChangeSignatoryLandingPage = ({ signatories, signatoriesStatus }) => {
  const dismissRef = useRef();
  return (
    <div className="modal" id="changeSignatoryLandingPage">
      <div className="modal-dialog">
        <ChangeSingleSignatoryComponentPage
          dismissRef={dismissRef}
          signatories={signatories}
          signatoriesStatus={signatoriesStatus}
        />
      </div>
    </div>
  );
};

export default ChangeSignatoryLandingPage;
