import React from "react";
import LoaderColored from "../../Loader/LoaderColored";

interface WidgetModalProps {
  generateStatus: String;
  generateKeys: any;
  copyText: any;
  copied: String;
}

const DeveloperWidgetModal = ({
  generateStatus,
  generateKeys,
  copyText,
  copied,
}: WidgetModalProps) => {
  console.log(typeof copyText);
  return (
    <div
      className="modal fade"
      id="devloperWidgetApiModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="developerModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="developerModalLabel">
              Generated Widget API Key
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {copied && (
              <span
                className="d-flex justify-content-center text-success

            "
              >
                copied !!!
              </span>
            )}
            {generateStatus === "loading" ? (
              <div className="d-flex justify-content-center align-items-center m-h-100">
                <LoaderColored />
              </div>
            ) : (
              <div className="row">
                <div className="col-12 ">
                  <h6>Public Key</h6>
                  <div className="d-flex align-items-center justify-content-between">
                    <p>{generateKeys?.data?.widget_public_key}</p>
                    <span
                      className="btn advancly-white-btn btn-sm transition-3d-hover"
                      onClick={() =>
                        copyText(generateKeys?.data?.widget_public_key)
                      }
                    >
                      Copy{" "}
                      <i className="ri-file-copy-fill color-mid-blue ml-2"></i>
                    </span>
                  </div>
                </div>
                <div className="col-12 ">
                  <h6>Public Encoded Key </h6>
                  <div className="d-flex align-items-center justify-content-between">
                    <p>
                      {generateKeys?.data?.widget_public_encoded_key
                        ? generateKeys?.data?.widget_public_encoded_key
                        : "**********************"}
                    </p>
                    <span
                      className="btn advancly-white-btn btn-sm transition-3d-hover"
                      onClick={() =>
                        copyText(generateKeys?.data?.widget_public_encoded_key)
                      }
                    >
                      Copy{" "}
                      <i className="ri-file-copy-fill color-mid-blue ml-2"></i>
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-center pt-2">
                  <small className="text-danger font-weight-bolder p-3 text-center">
                    {generateKeys?.message}
                  </small>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer pt-2">
            <button
              type="button"
              className="btn advancly-btn"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeveloperWidgetModal;
