import React, { useState } from "react";
import { yupValidators } from "../../helpers/yupValidators";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextArea from "../CustomHTMLElements/CustomTextArea";
import Loader from "../Loader/Loader.component";
import { errorHandler } from "../../helpers/errorHandler";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  name: yupValidators.fullName,
  businessName: yupValidators.businessName,
  phoneNumber: yupValidators.phoneNumber,
  email: yupValidators.email,
  message: yupValidators.contactMessage,
});

export default function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ username, password }) => {
    setLoading(true);
    setError(false);
    setSuccess(null);

    // const reqBody = {
    //   username,
    //   password,
    // };

    try {
      // const res = null;
      setLoading(false);
      setSuccess("Message sent");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "ContactForm.component.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  return (
    <div className="w-100">
      <div className="card border-0 p-4">
        <h3 className="page-title mb-0">Contact Support</h3>
        <hr />
        {error && <p className="alert alert-danger small">{error}</p>}
        {success && <p className="alert alert-success small">{success}</p>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6 col-12">
              <CustomInputField
                type="text"
                maxLength="60"
                reference={register}
                placeholder="John Doe"
                label="Name"
                name="name"
                errors={errors.name}
              />
            </div>
            <div className="col-md-6 col-12">
              <CustomInputField
                type="text"
                maxLength="128"
                reference={register}
                placeholder="Uber Nigeria"
                label="Business Name"
                name="businessName"
                errors={errors.businessName}
              />
            </div>
            <div className="col-md-6 col-12">
              <CustomInputField
                type="text"
                maxLength="128"
                reference={register}
                placeholder="08000000000"
                label="Phone Number"
                name="phoneNumber"
                errors={errors.phoneNumber}
              />
            </div>
            <div className="col-md-6 col-12">
              <CustomInputField
                type="email"
                maxLength="128"
                reference={register}
                placeholder="e.g johndoe@abc.com"
                label="Email Address"
                name="email"
                errors={errors.email}
              />
            </div>
            <div className="col-12">
              <CustomTextArea
                maxLength="300"
                reference={register}
                placeholder="Enter message here"
                label="Message"
                name="message"
                errors={errors.message}
                rows={5}
                bottomLabel="This field is limited to 300 characters"
              />
            </div>

            <div className="col-12 text-right">
              <button
                type="submit"
                value="submit"
                className="btn advancly-btn btn-sm transition-3d-hover"
                disabled={loading}
              >
                Send Message
                {loading && <Loader />}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
