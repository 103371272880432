import React from "react";
import { Fragment } from "react";
import styles from "./SignUp.module.scss";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postDataWithoutToken } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import { setUserInfo } from "../../helpers/setUserInfo";
import ROUTES from "../../helpers/ROUTES";
import { useAuth } from "../../Context/auth.context";
import { setLoginInfo } from "../../helpers/setLoginInfo";
import advanclyLogo from "../../img/advancly_logo.svg";
import embedProductLogo from "../../img/advancly_embed_product_logo.svg";
import sparkProductLogo from "../../img/advancly_spark_product_logo.svg";

import sparkImage from "../../img/spark_sign_up.svg";
import LoaderSignUp from "../LoaderSignUp/LoaderSignUp";
import useProductType from "../../custom-hooks/useProductType";
import LoaderColored from "../Loader/LoaderColored";
import { appInsights } from "../AppInsight/AppInsight";
const schema = yup.object().shape({
  productType: yup
    .array()
    .min(1, "please select a product")
    .of(yup.number().required())
    .required(),
});

export const SignUpFive = ({
  setStep,
  setError,
  error,
  setReqBodyData,
  reqBodyData,
  setLoading,
  loading,
}) => {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    data: productTypeData,
    isLoading: productTypeLoading,
    error: productTypeError,
  } = useProductType();

  const { setAggregatorDetails, setEligibilityData } = useAuth();
  const goBackHandler = () => {
    setStep(4);
    setError(false);
  };
  const onSubmit = async ({ productType }) => {
    setError(false);
    setLoading(true);

    try {
      const finalReqBody = {
        ...reqBodyData,
        // aggregator_setup_type_ids: productType,
        aggregator_product_type_ids: productType,
      };
      setReqBodyData(finalReqBody);
      const response = await postDataWithoutToken(
        apiEndpoints.signUpUrl,
        finalReqBody,
        true // Boolean here means that this endpoint should call production URL only
      );
      // This sets everything in the sessionStorage / localStorage
      setUserInfo(response, true); // true signifies that user is on sso by default

      const { aggregatorResult, eligibilityResult } = await setLoginInfo();
      setAggregatorDetails(aggregatorResult);
      setEligibilityData(eligibilityResult);
      setLoading(false);
      history.push(ROUTES.GET_STARTED);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "SignUpFive.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };
  return (
    <main role="main" className={`bg-white ${styles.signUpLayout}`}>
      <div className={styles.left}>
        <Fragment>
          <Link className={styles.sparkLogoLink} to="/login">
            <img
              className={styles.sparkLogo}
              src={advanclyLogo}
              alt="Login logo"
            />
          </Link>
          <div className={`${styles.onboardingBackButton} mt-3`}>
            {" "}
            <small
              className="color-mid-blue  cursor-pointer font-weight-bold  "
              onClick={goBackHandler}
            >
              &#8592; Go back
            </small>
          </div>

          {/* Form section  */}
          <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
            <h3 className="color-blue-hover-none font-weight-bold mb-1">
              Products
            </h3>
            <p
              className="color-card-border-color"
              style={{ color: "#170D33", fontSize: "1.25rem" }}
            >
              Select the products you would like to add to your account
            </p>
            {error && <p className="alert alert-danger small mb-4">{error}</p>}
            <small className="text-danger">{errors.productType?.message}</small>

            {productTypeError && (
              <p className="alert alert-danger small mb-4">
                {errorHandler(productTypeError)}
              </p>
            )}

            <div className="row">
              <div className="d-flex flex-wrap justify-content-between align-items-center col-12 ">
                <div className={`${styles.embedProduct} pr-2`}>
                  <img src={embedProductLogo} alt="Embed product logo" />
                  {productTypeLoading && (
                    <div>
                      <LoaderColored />
                    </div>
                  )}
                  {!productTypeLoading && !productTypeError && (
                    <div className={`mt-4 ${styles.productCheckboxes}`}>
                      <input
                        type="checkbox"
                        name="productType"
                        id="embeddedLoans"
                        ref={register}
                        value={
                          productTypeData &&
                          productTypeData?.length !== 0 &&
                          productTypeData?.length >= 1
                            ? productTypeData[0]?.id
                            : 1
                        }
                      />
                      <label htmlFor="embeddedLoans" className="">
                        {productTypeData &&
                        productTypeData?.length !== 0 &&
                        productTypeData?.length >= 1
                          ? productTypeData[0].name
                          : "Embedded Loans"}
                      </label>
                    </div>
                  )}
                  {productTypeLoading && (
                    <div>
                      <LoaderColored />
                    </div>
                  )}
                  {!productTypeLoading && !productTypeError && (
                    <div className={styles.productCheckboxes}>
                      <input
                        type="checkbox"
                        name="productType"
                        id="embeddedInvestments"
                        className={styles.styledcheckbox}
                        ref={register}
                        value={
                          productTypeData &&
                          productTypeData?.length !== 0 &&
                          productTypeData?.length >= 2
                            ? productTypeData[1]?.id
                            : 2
                        }
                      />
                      <label htmlFor="embeddedInvestments" className=" ">
                        {productTypeData &&
                        productTypeData?.length !== 0 &&
                        productTypeData?.length >= 2
                          ? productTypeData[1]?.name
                          : "Embedded Investments"}
                      </label>
                    </div>
                  )}
                </div>
                <div className={`${styles.middleline} `}></div>
                <div className={`${styles.sparkProduct} pl-2`}>
                  <img src={sparkProductLogo} alt="Spark product logo" />
                  {productTypeLoading && (
                    <div>
                      <LoaderColored />
                    </div>
                  )}

                  {!productTypeLoading && !productTypeError && (
                    <div className={`mt-4 ${styles.productCheckboxes}`}>
                      <input
                        type="checkbox"
                        name="productType"
                        id="sparkBusinessLoans"
                        ref={register}
                        value={
                          productTypeData &&
                          productTypeData?.length !== 0 &&
                          productTypeData?.length >= 3
                            ? productTypeData[2]?.id
                            : 3
                        }
                      />
                      <label htmlFor="sparkBusinessLoans" className="">
                        {productTypeData &&
                        productTypeData[2]?.name &&
                        productTypeData?.length >= 3
                          ? productTypeData[2]?.name
                          : "Loan for my Business"}
                      </label>
                    </div>
                  )}
                  {productTypeLoading && (
                    <div>
                      <LoaderColored />
                    </div>
                  )}
                  {!productTypeLoading && !productTypeError && (
                    <div className={` ${styles.productCheckboxes}`}>
                      <input
                        type="checkbox"
                        name="productType"
                        id="sparkCustomerLoans"
                        value={
                          productTypeData &&
                          productTypeData?.length !== 0 &&
                          productTypeData?.length >= 4
                            ? productTypeData[3]?.id
                            : 4
                        }
                        ref={register}
                      />
                      <label htmlFor="sparkCustomerLoans" className=" ">
                        {productTypeData &&
                        productTypeData?.length !== 0 &&
                        productTypeData?.length >= 2
                          ? productTypeData[3]?.name
                          : "Loan for my customer"}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 flex-wrap">
              <button
                type="submit"
                value="submit"
                className="btn advancly-btn btn-sm transition-3d-hover"
                disabled={loading}
              >
                Proceed
                {loading && <LoaderSignUp />}
              </button>
            </div>
          </form>
        </Fragment>
      </div>
      <div className={styles.right}>
        <div className={styles.content}>
          <img
            className={styles.illustrator}
            src={sparkImage}
            alt="Sign Up Password Illustration"
          />
        </div>
      </div>
    </main>
  );
};
