import React, { useRef, useState } from "react";
import styles from "./webHooks.module.scss";
import { ReactComponent as DeleteIcon } from "../../../img/DeleteIcon.svg";
import { deleteData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { appInsights } from "../../AppInsight/AppInsight";
import { errorHandler } from "../../../helpers/errorHandler";
import { queryCache } from "react-query";
import Loader from "../../Loader/Loader.component";
const DeleteWebHookModal = ({ idData }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const dismissRef = useRef();
  const onSubmit = async () => {
    setLoading(true);
    setError(false);
    try {
      const res = await deleteData(
        `${apiEndpoints.UNSUBSCRIBE_WEB_HOOK}${idData}`
      );
      setLoading(false);
      setSuccess(true);
      setSuccessMessage(res?.message);
      setTimeout(() => {
        setSuccess(false);
        dismissRef.current.click();
      }, 3000);
      // Refetch web hook list
      queryCache.invalidateQueries("getWebHookList");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "DeleteWebHookModal.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));

      setTimeout(() => {
        setError(false);
        dismissRef.current.click();
      }, 4000);
    }
  };

  return (
    <div>
      <div
        className={`modal fade `}
        id="deleteWebHookModal"
        tabindex="-1"
        aria-labelledby="deletewebhookLabel"
        aria-hidden="true"
      >
        <div className={`modal-dialog ${styles.delete_container}`}>
          <div className="modal-content">
            {error && <p className="alert alert-danger small row">{error}</p>}
            {success && (
              <p className="alert alert-success small">{successMessage}</p>
            )}
            <div className="modal-body d-flex flex-column align-items-center ">
              <div className={styles.deleteIcon}>
                <DeleteIcon />
              </div>
              <h5 className={styles.delete_heading}>Delete Webhook?</h5>
              <p className={styles.delete_paragraph}>
                Are you sure you want to delete this webhook? this action is
                irreversible
              </p>
              <div className="d-flex flex-column">
                <button
                  type="button"
                  className="btn advancly-red-btn2 mt-3"
                  onClick={onSubmit}
                >
                  Delete
                  {loading && <Loader />}
                </button>
                <button
                  type="button"
                  ref={dismissRef}
                  className="close btn advancly-nobg-btn mt-3 mb-4 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteWebHookModal;
