// import { yupResolver } from "@hookform/resolvers/yup";
// import { Fragment } from "react";
// import { useForm } from "react-hook-form";
// import { DownloadOfferLetterDocument } from "../../../components/UploadedDocumentCards/UploadedDocumentCard.component";
// import { DeleteUploadedDocument } from "./../../../components/UploadedDocumentCards/UploadedDocumentCard.component";
// import * as yup from "yup";
// import CustomTextArea from "../../../components/CustomHTMLElements/CustomTextArea";
// import Loader from "../../../components/Loader/Loader.component";
// import { ReactComponent as Upload } from "../../../svg/icons/fileUpload.svg";
// import styles from "../GetStarted.module.scss";

// const schema = yup.object().shape({
//   message: yup
//     .string()
//     .trim()
//     .required("Please enter your message")
//     .min(1, "Message must have at least 1 character")
//     .max(256, "Message cannot be longer than 256 characters"),
// });
// export default function GetStartedOfferLetterCards({
//   data,
//   filesToBeUploaded,
//   removeFile,
//   onSubmit,
//   viewHistory,
//   updating,
//   onFileChange,
// }) {
//   const { register, handleSubmit, errors } = useForm({
//     resolver: yupResolver(schema),
//   });
//   const allowSubmit = data.find((eachData) => {
//     const noAggregatorUpload =
//       !eachData?.aggregator_documents[0]?.aggregator_upload_path;
//     return noAggregatorUpload;
//   });
//   return (
//     <Fragment>
//       <div className="  mb-4">
//         <div className="">
//           <p className="text-justify color-card-border-color">
//             Thank you for applying to Advancly! We have attached <br /> an offer
//             letter and relevant documents for your perusal.
//           </p>
//           {/* <p className=" color-card-border-color"> */}
//           {/* Kindly fill out and upload the documents as applicable. */}
//           {/* </p> */}
//           <div className={`row`}>
//             <p className="col-6 ">
//               {/* Received Documents{" "} */}
//               {/* <span className={styles.dateText}>
//                 {data[0]?.aggregator_documents[0]?.admin_upload_date?.substring(
//                   0,
//                   10
//                 )}
//               </span> */}
//               Kindly fill out and upload the documents as applicable.
//             </p>
//             <p className="col-6 ">
//               Kindly upload the filled documents as applicable.
//               {/* <span className={styles.dateText}>
//                 {data[0]?.aggregator_documents[0]?.aggregator_upload_date?.substring(
//                   0,
//                   10
//                 )}
//               </span> */}
//             </p>
//           </div>

//           <div className={`row ${styles.offerLetterCards}`}>
//             {data && data?.length
//               ? data.map((obj, _id) => {
//                   const {
//                     aggregator_documents,
//                     document_field_Name,
//                     document_field_id,
//                   } = obj;
//                   const aggregatorUploaded =
//                     aggregator_documents[0] &&
//                     aggregator_documents[0]?.aggregator_file_name &&
//                     aggregator_documents[0]?.aggregator_upload_path;
//                   return (
//                     <>
//                       <div
//                         className=" col-xs-12 col-md-6 "
//                         key={document_field_id}
//                       >
//                         <DownloadOfferLetterDocument
//                           name={aggregator_documents[0].admin_filename}
//                           pathname={aggregator_documents[0].admin_upload_path}
//                           documentName={document_field_Name}
//                           viewHistory={viewHistory ? true : false}
//                         />
//                       </div>
//                       <div className="col-xs-12 col-md-6 ">
//                         {filesToBeUploaded[_id] &&
//                         filesToBeUploaded[_id]?.filename_with_extension &&
//                         filesToBeUploaded[_id]?.base64_file_string ? (
//                           <DeleteUploadedDocument
//                             name={
//                               filesToBeUploaded[_id].filename_with_extension
//                             }
//                             deleteFile={() => removeFile(_id)}
//                             offerLetter={true}
//                           />
//                         ) : (
//                           <Fragment>
//                             {aggregatorUploaded && (
//                               <DownloadOfferLetterDocument
//                                 name={
//                                   aggregator_documents[0]?.aggregator_file_name
//                                 }
//                                 pathname={
//                                   aggregator_documents[0]
//                                     ?.aggregator_upload_path
//                                 }
//                                 documentName={document_field_Name}
//                               />
//                             )}
//                             {!aggregator_documents[0]
//                               ?.aggregator_upload_path && (
//                               <div
//                                 className="color-mid-blue cursor-pointer mt-4"
//                                 onClick={() => {
//                                   document
//                                     .getElementById(`file-selector${_id}`)
//                                     .click();
//                                 }}
//                               >
//                                 <span className="mr-2">
//                                   <Upload />
//                                 </span>
//                                 <span className="text-underline">
//                                   Upload {document_field_Name}
//                                 </span>
//                               </div>
//                             )}
//                           </Fragment>
//                         )}
//                       </div>
//                       <input
//                         type="file"
//                         accept="application/pdf, application/msword"
//                         onChange={(e) =>
//                           onFileChange(
//                             e,
//                             _id,
//                             document_field_id,
//                             aggregator_documents[0].id
//                           )
//                         }
//                         className="d-none"
//                         id={`file-selector${_id}`}
//                       />
//                     </>
//                   );
//                 })
//               : null}
//           </div>
//           <form onSubmit={handleSubmit(onSubmit)}>
//             <div className="row mt-3">
//               {data[0]?.aggregator_documents[0]?.admin_comment && (
//                 <div className="col-md-6">
//                   <CustomTextArea
//                     maxLength="256"
//                     reference={register}
//                     label={`Admin's comment`}
//                     otherLabelClassName={styles.dateText}
//                     otherLabel={` ${
//                       data[0]?.aggregator_documents[0]?.aggregator_upload_date
//                         ? ` ${data[0]?.aggregator_documents[0]?.admin_upload_date?.substring(
//                             0,
//                             10
//                           )}`
//                         : ""
//                     }`}
//                     name="message"
//                     errors={errors.message}
//                     disabled
//                     defaultValue={
//                       data[0]?.aggregator_documents[0]?.aggregator_upload_path
//                         ? data[0]?.aggregator_documents[0]?.admin_comment
//                         : ""
//                     }
//                     rows="4"
//                   />
//                 </div>
//               )}
//               {allowSubmit && (
//                 <div className="col-md-5">
//                   <CustomTextArea
//                     maxLength="256"
//                     reference={register}
//                     label={`Your comment`}
//                     otherLabelClassName={styles.dateText}
//                     name="message"
//                     errors={errors.message}
//                     defaultValue={
//                       data[0]?.aggregator_documents[0]?.aggregator_upload_path
//                         ? data[0]?.aggregator_documents[0]?.comment
//                         : ""
//                     }
//                     rows="4"
//                   />
//                 </div>
//               )}
//             </div>
//             {allowSubmit && (
//               <button
//                 className="btn advancly-btn btn-sm transition-3d-hover mt-3 pl-2 pr-2"
//                 type="submit"
//                 disabled={updating}
//               >
//                 Send Documents {updating && <Loader />}
//               </button>
//             )}
//           </form>
//           <span className={styles.dateText}>
//             {data[0]?.aggregator_documents[0]?.admin_upload_date?.substring(
//               0,
//               10
//             )}
//           </span>
//         </div>
//       </div>
//     </Fragment>
//   );
// }
// THE ABOVE CODE IS LEFT  AND COMMENTED OUT FOR NOW TILL IT IS STABLE
import { yupResolver } from "@hookform/resolvers/yup";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DownloadOfferLetterDocument } from "../../../components/UploadedDocumentCards/UploadedDocumentCard.component";
import { DeleteUploadedDocument } from "./../../../components/UploadedDocumentCards/UploadedDocumentCard.component";
import * as yup from "yup";
import CustomTextArea from "../../../components/CustomHTMLElements/CustomTextArea";
import Loader from "../../../components/Loader/Loader.component";
import { ReactComponent as Upload } from "../../../svg/icons/fileUpload.svg";
import styles from "../GetStarted.module.scss";
import { v4 as uuidv4 } from "uuid";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import OfferLetterCommentModal from "./OfferLetterCommentModal";

const schema = yup.object().shape({
  message: yup
    .string()
    .trim()
    .required("Please enter your message")
    .min(1, "Message must have at least 1 character")
    .max(256, "Message cannot be longer than 256 characters"),
});
export default function GetStartedOfferLetterCards({
  data,
  filesToBeUploaded,
  removeFile,
  onSubmit,
  viewHistory,
  updating,
  onFileChange,
  setApprovalStatus,
  replaceFile,
}) {
  const [offerLetterComments, setOfferLetterComments] = useState(null);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const allowSubmit = data.find((eachData) => {
    const noAggregatorUpload = eachData?.aggregator_documents.some(
      ({ aggregator_upload_path, approval_status }) => {
        return (
          aggregator_upload_path === null ||
          aggregator_upload_path === undefined ||
          aggregator_upload_path === "" ||
          approval_status === 3
        );
      }
    );
    return noAggregatorUpload;
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "GetStartedOfferLetterCards.jsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <Fragment>
      <div className="  mb-4">
        <div className="">
          <p className="text-justify color-card-border-color">
            Thank you for applying to Advancly! We have attached <br /> an offer
            letter and relevant documents for your perusal.
          </p>
          {/* <p className=" color-card-border-color"> */}
          {/* Kindly fill out and upload the documents as applicable. */}
          {/* </p> */}
          <div className={`row`}>
            <p className="col-6 ">
              {/* Received Documents{" "} */}
              {/* <span className={styles.dateText}>
                {data[0]?.aggregator_documents[0]?.admin_upload_date?.substring(
                  0,
                  10
                )}
              </span> */}
              Kindly fill out and upload the documents as applicable.
            </p>
            <p className="col-6 ">
              Kindly upload the filled documents as applicable.
              {/* <span className={styles.dateText}>
                {data[0]?.aggregator_documents[0]?.aggregator_upload_date?.substring(
                  0,
                  10
                )}
              </span> */}
            </p>
          </div>

          <div className={`row ${styles.offerLetterCards}`}>
            {data && data?.length
              ? data.map((obj, _id) => {
                  const {
                    aggregator_documents,
                    document_field_Name,
                    document_field_id,
                  } = obj;

                  return (
                    <>
                      <div
                        className=" col-xs-12 col-md-6 "
                        key={document_field_id}
                      >
                        {aggregator_documents?.map(
                          ({
                            admin_filename,
                            admin_upload_path,
                            admin_user_id,
                          }) => {
                            return (
                              <div key={admin_user_id}>
                                <DownloadOfferLetterDocument
                                  name={admin_filename}
                                  pathname={admin_upload_path}
                                  documentName={document_field_Name}
                                  viewHistory={viewHistory ? true : false}
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                      <div className="col-xs-12 col-md-6 ">
                        <Fragment>
                          {aggregator_documents?.map((path) => {
                            return !path.aggregator_upload_path &&
                              !path.isNewFile ? (
                              <div key={uuidv4()}>
                                <div
                                  className="color-mid-blue cursor-pointer mt-4"
                                  onClick={() => {
                                    document
                                      .getElementById(`file-selector${_id}`)
                                      .click();
                                  }}
                                >
                                  <span className="mr-2">
                                    <Upload />
                                  </span>
                                  <span className="text-underline">
                                    Upload {document_field_Name}
                                  </span>
                                </div>
                              </div>
                            ) : path.isNewFile === true ? (
                              <DeleteUploadedDocument
                                name={path?.filename_with_extension}
                                deleteFile={() => removeFile(_id)}
                                offerLetter={true}
                              />
                            ) : path.isReplaceFile === true ? (
                              <DeleteUploadedDocument
                                name={path?.aggregator_file_name}
                                deleteFile={() => removeFile(_id)}
                                offerLetter={true}
                              />
                            ) : (
                              path.aggregator_upload_path !== null &&
                              !path.isNewFile && (
                                <div>
                                  <DownloadOfferLetterDocument
                                    name={path?.aggregator_file_name}
                                    pathname={path?.aggregator_upload_path}
                                    documentName={
                                      data[_id]?.document_field_Name
                                    }
                                  />

                                  <div className="">
                                    {path?.approval_status === 1 && (
                                      <span className="ml-2 p-2 pending_status">
                                        Pending
                                      </span>
                                    )}
                                    {path?.approval_status === 2 && (
                                      <span className="ml-2 p-2 approved_status">
                                        Approved
                                      </span>
                                    )}
                                    {path?.approval_status === 3 && (
                                      <span className="ml-2  p-2 declined_status ">
                                        Declined
                                      </span>
                                    )}

                                    <button
                                      className={`btn color-mid-blue  transition-3d-hover `}
                                      data-toggle="modal"
                                      data-target="#offerlettercommentmodal"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setOfferLetterComments(path?.comments);
                                      }}
                                    >
                                      Comments{" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-chat-dots"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                        <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                                      </svg>
                                    </button>

                                    {path.approval_status === 3 && (
                                      <div key={uuidv4()}>
                                        <div
                                          className="color-mid-blue cursor-pointer mt-4"
                                          onClick={() => {
                                            // replaceFile(_id);
                                            replaceFile(_id, path.id);

                                            setApprovalStatus(
                                              path.approval_status
                                            );
                                          }}
                                        >
                                          <span className="mr-2">
                                            <Upload />
                                          </span>
                                          <span className="text-underline">
                                            Re-Upload {document_field_Name}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            );
                          })}
                        </Fragment>
                      </div>

                      <input
                        type="file"
                        accept="application/pdf, application/msword"
                        onChange={(e) =>
                          onFileChange(
                            e,
                            _id,
                            document_field_id,
                            aggregator_documents
                              ?.filter(
                                (
                                  aggregator_file_name,
                                  aggregator_upload_path
                                ) => {
                                  return (
                                    !aggregator_upload_path ||
                                    !aggregator_file_name
                                  );
                                }
                              )
                              .map(({ id }) => {
                                return id;
                              })
                              .join()

                            // aggregator_documents[0].id
                          )
                        }
                        className="d-none"
                        id={`file-selector${_id}`}
                      />
                    </>
                  );
                })
              : null}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mt-3">
              {data[0]?.aggregator_documents[0]?.admin_comment && (
                <div className="col-md-6">
                  <CustomTextArea
                    maxLength="256"
                    reference={register}
                    label={`Admin's comment`}
                    otherLabelClassName={styles.dateText}
                    otherLabel={` ${
                      data[0]?.aggregator_documents[0]?.aggregator_upload_date
                        ? ` ${data[0]?.aggregator_documents[0]?.admin_upload_date?.substring(
                            0,
                            10
                          )}`
                        : ""
                    }`}
                    name="message"
                    errors={errors.message}
                    disabled
                    defaultValue={
                      data[0]?.aggregator_documents[0]?.aggregator_upload_path
                        ? data[0]?.aggregator_documents[0]?.admin_comment
                        : ""
                    }
                    rows="4"
                  />
                </div>
              )}
              {allowSubmit && (
                <div className="col-md-5">
                  <CustomTextArea
                    maxLength="256"
                    reference={register}
                    label={`Your comment`}
                    otherLabelClassName={styles.dateText}
                    name="message"
                    errors={errors.message}
                    defaultValue={""}
                    rows="4"
                  />
                </div>
              )}
            </div>
            {allowSubmit && (
              <button
                className="btn advancly-btn btn-sm transition-3d-hover mt-3 pl-2 pr-2"
                type="submit"
                disabled={updating}
              >
                Send Documents {updating && <Loader />}
              </button>
            )}
          </form>

          {data?.map(({ aggregator_documents }) => {
            return aggregator_documents.map(({ admin_upload_date }) => {
              return (
                <div key={uuidv4()}>
                  <span className={styles.dateText}>
                    {" "}
                    {admin_upload_date?.substring(0, 10)}{" "}
                  </span>
                </div>
              );
            });
          })}
        </div>
      </div>
      <OfferLetterCommentModal offerLetterComments={offerLetterComments} />
    </Fragment>
  );
}
