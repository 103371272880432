import React, { Fragment } from "react";
import { formatMoney } from "../../helpers/formatter";
import Loader from "../Loader/Loader.component";
import styles from "./ScoreCard.module.scss";

export default function ScoreCard({ title, value, formatValue, loading }) {
  return (
    <div className={styles.scoreCard}>
      {loading ? (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : (
        <Fragment>
          <div className={styles.value}>
            {value ? value : !formatValue ? "0%" : formatMoney(0)}
          </div>
          <div className={styles.title}>{title}</div>
        </Fragment>
      )}
    </div>
  );
}
