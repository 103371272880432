import moment from "moment";
import { useHistory } from "react-router-dom";
import { formatMoney } from "../../helpers/formatter";
import { accurateQuantifier } from "../utility";

const EachPendingLoanRow = ({
  data,
  checkboxHandler,
  checked,
  setSingleLoanToActionOn,
  loading,
}) => {
  const {
    id,
    product_name,
    borrower_details,
    amount,
    amount_interest,
    state_token,
    loan_tenure,
    currency,
    submitted_on_date,
  } = data;
  let history = useHistory();

  const handleClick = (props) => {
    history.push(`/borrowers/${props.borrower_id}`);
  };
  return (
    <tr>
      <td>
        <input
          type="checkbox"
          data-id={id}
          data-ref={state_token}
          value={checked}
          onClick={(e) =>
            checkboxHandler({
              eventTarget: e.target,
            })
          }
          className="loans_for_disbursement"
        />
      </td>
      <td>{state_token}</td>
      <td className={"text-capitalize"}>
        {borrower_details ? borrower_details.first_name.toLowerCase() : ""}
      </td>
      <td className={"text-capitalize"}>
        {borrower_details ? borrower_details.last_name.toLowerCase() : ""}
      </td>
      <td>{data ? product_name : "None"}</td>
      <td>{data ? formatMoney(amount, currency) : 0}</td>
      <td>{data ? formatMoney(amount_interest, currency) : "None"}</td>
      <td>{data ? accurateQuantifier(loan_tenure, "day") : "None"}</td>
      <td>{moment(submitted_on_date).format("DD-MM-YYYY")}</td>

      <td
        className="color-mid-blue cursor-pointer"
        onClick={() => handleClick(borrower_details)}
      >
        View Details
      </td>
      <td className="dropright text-right  ">
        <span
          className="more-icon "
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        ></span>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <button
            className="btn btn-sm advancly-nobg-btn"
            name="Accept"
            onClick={() =>
              setSingleLoanToActionOn({ commentType: "approve", state_token })
            }
            data-toggle="modal"
            data-target="#singleLoanCommentModal"
            disabled={loading}
          >
            Approve Loan
          </button>
          <button
            className="btn btn-sm advancly-nobg-btn"
            name="Reject"
            onClick={() =>
              setSingleLoanToActionOn({ commentType: "decline", state_token })
            }
            data-toggle="modal"
            data-target="#singleLoanCommentModal"
            disabled={loading}
          >
            Decline Loan
          </button>
        </div>
      </td>
    </tr>
  );
};
export default EachPendingLoanRow;
