import React from "react";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import { useForm } from "react-hook-form";

import { errorHandler } from "../../../helpers/errorHandler";

const SingleSignatoryModalStep2 = ({
  setSingleSignatory,
  setSinglesSignatoryName,
  step,
  singleSignatory,
  setSignatoryType,
  usersData,
  usersStatus,
  usersError,
  addNewUserModal,
  setAddNewUserModal,
}) => {
  const { handleSubmit, register, errors, watch } = useForm();
  const submitHandler = (data) => {
    const signatoriesBody = [{ email: data.singleSignatoryName, levelNo: 1 }];

    setSinglesSignatoryName(signatoriesBody);
    setSingleSignatory(singleSignatory + step);
  };
  const watchSingleSignatoryNameChange = watch("singleSignatoryName");
  // const { data: users, status, error } = useAggregatorSignatoryUsers();
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Single Signatory Approval</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={() => {
            setSignatoryType("");
            setAddNewUserModal(true);
          }}
        >
          &times;
        </button>
      </div>
      <div className="modal-body">
        <small
          className="color-mid-blue text-right  row cursor-pointer font-weight-bold pl-2 "
          onClick={() => {
            setSignatoryType("");
          }}
        >
          &#8592; Go back
        </small>
        <p className="pt-2">Select one of the following users as a signatory</p>
        <form onSubmit={handleSubmit(submitHandler)}>
          {usersStatus === "loading" && (
            <small className="text-primary">Loading...</small>
          )}
          {usersError && (
            <small className="text-dangeralert alert-danger small text-center">
              {errorHandler(usersError)}
            </small>
          )}
          <CustomSelectDropdown
            name="singleSignatoryName"
            reference={register({ required: true })}
          >
            <option value="">Select a name</option>
            {usersData?.map((user) => {
              const { id, firstName, lastName, email } = user;
              return (
                <option
                  key={id}
                  value={email}
                >{`${firstName} ${lastName}`}</option>
              );
            })}
          </CustomSelectDropdown>
          {errors.singleSignatoryName && (
            <p className="text-danger">Please select a name</p>
          )}
          <small
            className="color-mid-blue   row cursor-pointer font-weight-bold pl-3 "
            onClick={() => {
              setSingleSignatory(singleSignatory - step);
              setAddNewUserModal(true);
            }}
          >
            Create a new User
          </small>
          <button
            className="btn advancly-btn btn-sm w-100 mt-3"
            disabled={!watchSingleSignatoryNameChange}
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  );
};

export default SingleSignatoryModalStep2;
