import React, { Fragment, useState } from "react";

import ToggleTab from "../ToggleTab/ToggleTab.component";
import WalletDisbursement from "../../Pages/WalletDisbursment/WalletDisbursement";

const Approvals = () => {
  const [activeTab, setActiveTab] = useState("wallet-disbursment");
  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          Transaction Approvals
        </h3>
      </div>
      <div className="card mb-5">
        <div className="card-body p-4">
          <div className="alignToggleTabItems  mb-5">
            <ToggleTab
              text="Pending Disbursement"
              id="wallet-disbursment"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div className="row table-responsive">
            <div className="leftApprovalstyles">
              {(() => {
                switch (activeTab) {
                  case "wallet-disbursment":
                    return <WalletDisbursement />;

                  default:
                    return null;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Approvals;
