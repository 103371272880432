import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import useRollOverDetails from "../../custom-hooks/useRollOverDetails";
import { attachClassNameToTag } from "../../helpers/attachClassNameToTag";
import { formatMoney } from "../../helpers/formatter";
import Loader from "../Loader/Loader.component";
import NoTableDataAvailable from "../NoTableDataAvailable/NoTableDataAvailable.component";
import { accurateQuantifier } from "../utility";

const RollOverLoanDetails = ({ parent_rollover_loan_reference }) => {
  const { data, status, error } = useRollOverDetails({
    parent_rollover_loan_reference,
  });

  if (status === "loading")
    return (
      <div className="d-flex justify-content-center">
        <Loader />
      </div>
    );
  if (error)
    return <h5 className="alert alert-danger small">An error occured</h5>;
  return (
    <Fragment>
      {/* <!-- Disbursements Table --> */}
      <div className="table-responsive">
        <table className="table mb-5">
          <thead className="bg-lighter-grey">
            <tr>
              <th>Loan Ref</th>
              <th>Tenure</th>
              <th>Repayment Frequency</th>
              <th>Amount</th>
              <th>Amount to Repay</th>
              <th>Pub Date</th>
              <th>Settlement Date</th>
              <th>Parent R/V Loan Ref</th>
              <th>Roll Over Loan Ref</th>
              <th>Loan Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((loans, _id) => {
                const {
                  loan_amount,
                  loan_amount_repay,
                  status,
                  pub_date,
                  settlement_day,
                  loan_ref,
                  loan_tenure,
                  repayment_type,
                  currency,
                  parent_rollover_loan_reference,
                  rollover_loan_reference,
                } = loans;
                return (
                  <tr key={_id}>
                    <td>{loan_ref}</td>
                    <td>{accurateQuantifier(loan_tenure, "day")}</td>
                    <td>{repayment_type}</td>
                    <td>{formatMoney(Number(loan_amount), currency)}</td>
                    <td>{formatMoney(Number(loan_amount_repay), currency)}</td>
                    <td>{pub_date?.substring(0, 10)}</td>
                    <td>
                      {settlement_day ? settlement_day?.substring(0, 10) : ""}
                    </td>
                    <td>{parent_rollover_loan_reference}</td>
                    <td>{rollover_loan_reference}</td>
                    <td>
                      <span className={attachClassNameToTag(status)}>
                        {attachClassNameToTag(status)}
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoTableDataAvailable />
            )}
          </tbody>
        </table>
      </div>
      {/* <!-- End Disbursements Table --> */}
    </Fragment>
  );
};
export default withRouter(RollOverLoanDetails);
