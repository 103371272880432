import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getSearchBorrowerDetails = async ({ borrowerId }) => {
  const data = await getData(`${apiEndpoints.aggregatorDetails}/${borrowerId}`);
  return data?.data;
};

export default function useSearchBorrowerDetails({ borrowerId }) {
  return useQuery(
    [{ borrowerId }, "getSearchBorrowerDetails"],
    getSearchBorrowerDetails,
    { enabled: borrowerId && borrowerId > 0 ? true : false }
  );
}
