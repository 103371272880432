import React, { useState } from "react";
import { queryCache } from "react-query";
import useAggregatorApiKeys from "../../../custom-hooks/useAggregatorApiKeys";
import useGenerateApiKeys from "../../../custom-hooks/useGenerateApiKeys";
import { decrypt } from "../../../helpers/encryptor";
import LoaderColored from "../../Loader/LoaderColored";
import DeveloperAPIModal from "./DeveloperAPIModal";
import DeveloperWidgetModal from "./DeveloperWidgetModal";
const DeveloperAPIKeys = () => {
  const roleType = JSON.parse(decrypt(sessionStorage.getItem("roleTypes")));
  const [apiKeyTypeValue, setApiKeyTypeValue] = useState(null);
  //for apikeyValue 1 means generate to api keys , 2 means generate widget API keys
  const { data: aggrKeys, status } = useAggregatorApiKeys();
  const {
    data: generateKeys,
    refetch,
    status: generateStatus,
  } = useGenerateApiKeys({
    apiKeyType: apiKeyTypeValue,
  });

  const [copied, setCopied] = useState("");
  const copyText = (text) => {
    setCopied("Copied.");
    navigator.clipboard.writeText(text.toString());
    setTimeout(() => setCopied(""), 3000);
  };
  const generateApiKeyHandler = () => {
    setApiKeyTypeValue("1");
    setTimeout(() => {
      refetch();
    }, 1000);
    setTimeout(() => {
      queryCache.invalidateQueries("getAggregatorApiKeys");
    }, 5000);
  };
  const generateWidgetApiKeyHandler = () => {
    setApiKeyTypeValue("2");
    setTimeout(() => {
      refetch();
    }, 1000);
    setTimeout(() => {
      queryCache.invalidateQueries("getAggregatorApiKeys");
    }, 5000);
  };

  return (
    <div>
      <div
        className=""
        style={{ borderBottom: " 1px solid #D1CFD6", paddingBottom: "1rem" }}
      >
        {copied && (
          <span
            className="d-flex justify-content-center text-success

            "
          >
            copied !!!
          </span>
        )}

        {status === "loading" ? (
          <div className="d-flex justify-content-center align-items-center m-h-100">
            <LoaderColored />
          </div>
        ) : (
          <div className="js-validate mt-3">
            <div className="">
              <div className="generate-api-key-row  row">
                <div className="col-12 col-md-4">
                  <small>Client ID</small>
                  <p>{aggrKeys?.client_id}</p>
                </div>
                <div className="col-12 col-md-4">
                  <small>API key Encoded</small>
                  <p>
                    {aggrKeys?.encoded_secret_key
                      ? aggrKeys?.encoded_secret_key
                      : "************"}
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <div
                  className="btn advancly-white-btn btn-sm transition-3d-hover  "
                  onClick={() => copyText(aggrKeys?.client_id)}
                >
                  Copy <i className="ri-file-copy-fill color-mid-blue ml-2"></i>
                </div>
                {roleType && roleType.includes("aggregator") && (
                  <button
                    type="button"
                    className="btn advancly-btn btn-sm transition-3d-hover ml-2 "
                    data-toggle="modal"
                    data-target="#devloperModal"
                    onClick={generateApiKeyHandler}
                  >
                    Generate API Key
                  </button>
                )}
              </div>
              {/* widget API key */}
              <div className="generate-api-key-row  row pt-3">
                <div className="col-12 col-md-4">
                  <small> Widget Public Key</small>
                  <p>{aggrKeys?.widget_public_key}</p>
                </div>
                <div className="col-12 col-md-4">
                  <small> Widget API key Encoded</small>
                  <p>
                    {aggrKeys?.encoded_secret_key
                      ? aggrKeys?.encoded_secret_key
                      : "*************"}
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <span
                  className="btn advancly-white-btn btn-sm transition-3d-hover "
                  onClick={() => copyText(aggrKeys?.widget_public_key)}
                >
                  Copy <i className="ri-file-copy-fill color-mid-blue ml-2"></i>
                </span>
                {roleType && roleType.includes("aggregator") && (
                  <button
                    type="button"
                    className="btn advancly-btn btn-sm transition-3d-hover ml-2 "
                    data-toggle="modal"
                    data-target="#devloperWidgetApiModal"
                    onClick={generateWidgetApiKeyHandler}
                  >
                    Generate Widget API Key
                  </button>
                )}
              </div>
              <div className="col-12 ">
                <div className="d-flex flex-wrap justify-content-end align-items-center">
                  <div className="">
                    <div className="d-flex justify-content-end mt-3 flex-wrap">
                      <a
                        href="https://docs.advancly.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="color-mid-blue"
                      >
                        API Documentation
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <DeveloperAPIModal
        generateStatus={generateStatus}
        generateKeys={generateKeys}
        copyText={copyText}
        copied={copied}
      />
      <DeveloperWidgetModal
        generateStatus={generateStatus}
        generateKeys={generateKeys}
        copyText={copyText}
        copied={copied}
      />
    </div>
  );
};

export default DeveloperAPIKeys;
