import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import useAggregatorProducts from "../custom-hooks/useAggregatorProducts";
import { errorHandler } from "../helpers/errorHandler";
import { formatMoney } from "../helpers/formatter";
import CustomSelectDropdown from "./CustomHTMLElements/CustomSelectDropdown";
import PageLoader from "./PageLoader/PageLoader.component";
import Paginator from "./Paginator/Paginator.component";
import { accurateQuantifier } from "./utility";
import NoTableDataAvailable from "./NoTableDataAvailable/NoTableDataAvailable.component";

const Products = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [searchValue, setSearchValue] = useState("");

  const { data, error, status } = useAggregatorProducts({ country_code: "" });

  if (status === "loading") return <PageLoader />;
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div>
          <h3 className="page-title mb-4">Products</h3>
        </div>
        <div className="d-flex align-items-center mb-2">
          <div className="input-group mb-3">
            <input
              type="text"
              className="mr-0 search-query form-control"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <div className="input-group-append">
              <span className="input-group-text bg-white">
                <span className="fa fa-search"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body p-3">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="page-subtitle">Product List</h5>
            <div className="d-flex align-items-center">
              <p className="pr-2 pt-1">Showing:</p>
              <CustomSelectDropdown
                onChange={(e) => setSize(e.target.value)}
                value={size}
              >
                <option value={10}>10 Entries</option>
                <option value={20}>20 Entries</option>
                <option value={50}>50 Entries</option>
                <option value={100}>100 Entries</option>
              </CustomSelectDropdown>
            </div>
          </div>
          <div className="table-responsive">
            {error && (
              <p className="alert alert-danger small">{errorHandler(error)}</p>
            )}
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>
                    <div style={{ minWidth: "100px" }}>Product Id</div>
                  </th>
                  <th>
                    <div style={{ minWidth: "150px" }}>Product Name</div>
                  </th>
                  <th>
                    <div style={{ minWidth: "120px" }}>Product Code</div>
                  </th>
                  <th>
                    <div style={{ minWidth: "150px" }}>Product Category</div>
                  </th>
                  <th>
                    <div>Max Amount</div>
                  </th>
                  <th>
                    <div style={{ minWidth: "120px" }}>Max Tenure</div>
                  </th>
                  <th>
                    <div style={{ minWidth: "150px" }}>Interest Rate(%)</div>
                  </th>
                  <th>
                    <div style={{ minWidth: "200px" }}>Repayment Frequency</div>
                  </th>
                  <th>
                    <div style={{ minWidth: "120px" }}>Schedule Type</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data && data.length ? (
                  data
                    .filter(
                      (product) =>
                        product.id
                          .toString()
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        product.product_code
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        product.product_name
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        product.productCategoryName
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        product.maximum_amount
                          .toString()
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        product.maximum_tenor
                          .toString()
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        product.interest_rate
                          .toString()
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        product.repayment_type
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        product.loan_calculation_method_value
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                    )
                    .slice(
                      (pageNumber - 1) * size,
                      size * (pageNumber - 1) + size
                    )
                    .map((data) => {
                      const {
                        id,
                        product_name,
                        product_code,
                        productCategoryName,
                        maximum_amount,
                        maximum_tenor,
                        interest_rate,
                        repayment_type,
                        loan_calculation_method_value,
                        currency,
                      } = data;
                      return (
                        <tr key={id}>
                          <td>{id}</td>
                          <td>{product_name}</td>
                          <td>{product_code}</td>
                          <td>{productCategoryName}</td>
                          <td>{formatMoney(maximum_amount, currency)}</td>
                          <td>{accurateQuantifier(maximum_tenor, "day")}</td>
                          <td>{interest_rate}</td>
                          <td>{repayment_type}</td>
                          <td>{loan_calculation_method_value}</td>
                        </tr>
                      );
                    })
                ) : (
                  <NoTableDataAvailable />
                )}
              </tbody>
            </table>
          </div>
          <Paginator
            size={size}
            page={pageNumber}
            count={data ? data.length : 0}
            changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Products);
