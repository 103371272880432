import React, { Fragment, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import useTransactionsOverview from "../../custom-hooks/useTransactionsOverview";
import { errorHandler } from "../../helpers/errorHandler";
// import { formatMoney } from "../../helpers/formatter";
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";
import useDueLoansCount from "../../custom-hooks/useDueLoansCount";
import { useAuth } from "../../Context/auth.context";
import WalletDetailsModal from "../Repayment/RepaymentModal/WalletDetailsModal";
// import useWalletDetails from "../../custom-hooks/useWalletDetails";
// import Loader from "../../components/Loader/Loader.component";
import LoanCountModal from "./LoanCountModal";
import SendMoneyModal from "../../components/SendMoneyModal/SendMoneyModal";
import { decrypt } from "../../helpers/encryptor";
import DismissableAlert from "../../components/DismissableAlert/DismissableAlert.component";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import useAggregatorActiveCurrencies from "../../custom-hooks/useAggregatorActiveCurrencies";
import Loader from "../../components/Loader/Loader.component";
import useLoanCount from "../../custom-hooks/useLoanCount";
import useTotalOutstandingLoans from "../../custom-hooks/useTotalOutstandingLoans";
import { queryCache } from "react-query";
import LoanDashboardTab from "./LoanDashboardTab";
import InvestmentDashboardTab from "./InvestmentDashboardTab";
import ToggleTab from "../../components/ToggleTab/ToggleTab.component";
import useAggregatorProductDetails from "../../custom-hooks/useAggregatorProductsDetails";
import useInvestmentsOverview from "../../custom-hooks/useInvestmentsOverview";
import { appInsights } from "../../components/AppInsight/AppInsight";
// import { queryCache } from "react-query";

const Dashboard = () => {
  const { aggregatorDetails } = useAuth();
  const [error, setError] = useState(null);
  const [currentCurrency, setCurrentCurrency] = useState("");

  const [activeTab, setActiveTab] = useState("loan_summary_dashboard");

  const [showModal, setShowModal] = useState(true);
  const [signatoryComplete, setSignatoryComplete] = useState(true);
  const roleType = JSON.parse(decrypt(sessionStorage.getItem("roleTypes")));
  // const { data: signatories } = useAggregatorSignatories({ roleType });
  const sign = aggregatorDetails?.has_setup_disbursement_signatory;
  const history = useHistory();

  useEffect(() => {
    setSignatoryComplete(sign);
  }, [sign]);
  // const { data: walletDetails, status: walletDetailsStatus } =
  //   useWalletDetails();

  // const {
  //   data: productsData,
  //   status: productsStatus,
  //   error: productsError,
  // } = useAggregatorProducts();

  // const {
  //   data: transactionsOverview,
  //   status: trasactionOverviewStatus,
  //   error: transactionsError,
  // } = useTransactionsOverview({ currentCurrencyValue: currentCurrency });
  const {
    data: aggregatorCurrencies,
    status: aggregatorCurrenciesStatus,
    error: aggregatorCurrenciesError,
  } = useAggregatorActiveCurrencies();

  const {
    data: productDetails,
    status: productStatus,
    error: productError,
  } = useAggregatorProductDetails();
  const activeProductName = productDetails
    ?.filter(({ is_default }) => {
      return is_default === true;
    })
    .map(({ product_display_name }) => {
      return product_display_name;
    })
    .join();

  useEffect(() => {
    let defaultCurrency;
    aggregatorCurrencies?.data !== undefined
      ? (defaultCurrency = aggregatorCurrencies?.data?.includes("NGN")
          ? "NGN"
          : aggregatorCurrencies?.data?.includes("USD")
          ? "USD"
          : aggregatorCurrencies?.data.includes("KES")
          ? "KES"
          : "NGN")
      : (defaultCurrency = "");

    setCurrentCurrency(defaultCurrency);
  }, [aggregatorCurrencies]);
  // for Loan summary tab
  const {
    data: transactionsOverview,
    status: trasactionOverviewStatus,
    error: transactionsError,
  } = useTransactionsOverview({
    currentCurrencyValue: currentCurrency,
    activeProductName: activeProductName,
  });

  const {
    data: loanCount,
    status: loanCountStatus,
    error: loanCountError,
  } = useLoanCount({
    currentCurrencyValue: currentCurrency,
    activeProductName: activeProductName,

    aggregatorId: aggregatorDetails?.aggregator_id,
  });
  const {
    data: totalOutstandingLoans,
    status: totalOutstandingStatus,
    error: totalOutstandingError,
  } = useTotalOutstandingLoans({
    currentCurrencyValue: currentCurrency,
    activeProductName: activeProductName,
  });

  // for Investment summary tab

  const {
    data: investmentOverview,
    status: investmentOverviewStatus,
    error: investmentOverviewError,
  } = useInvestmentsOverview({
    currentCurrencyValue: currentCurrency,
    activeProductName: activeProductName,
  });

  if (!error && loanCountError) {
    setError(errorHandler(loanCountError));
  }
  if (!error && totalOutstandingError) {
    setError(errorHandler(totalOutstandingError));
  }

  if (!error && investmentOverviewError) {
    setError(errorHandler(investmentOverviewError));
  }
  const { data: dueLoansCount } = useDueLoansCount();
  if (!error && transactionsError) {
    setError(errorHandler(transactionsError));
  }

  if (!error && aggregatorCurrenciesError) {
    setError(errorHandler(aggregatorCurrenciesError));
  }
  if (!error && productError) {
    setError(errorHandler(productError));
  }

  function currencyChangeHandler(e) {
    setCurrentCurrency(e.target.value);
    queryCache.invalidateQueries("getTransactionsOverview");
    queryCache.invalidateQueries("getTotalOutstanding");
    queryCache.invalidateQueries("getInvestmentOverview");
  }

  // Check before showing investment summary

  const isEmbeddedInvestment = aggregatorDetails?.product_types?.some(
    ({ product_type }) => {
      return product_type === "EmbeddedInvestment";
    }
  );
  useEffect(() => {
    appInsights.trackPageView({
      name: "Dashboard.jsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div>
      {/* {firstTimeUser && (
        <div className="">
          <DismissableAlert
            dashboardAlert={true}
            onDismiss={() => setFirstTimeUser(false)}
          >
            <span>
              Welcome to Advancly! Tap the button to finish setting up your
              Investor profile.{" "}
              <button
                data-toggle="modal"
                data-target="#dashboardCompleteProfileModal"
                className="btn btn-sm advancly-white-btn ml-3 border-0"
              >
                Complete Setup
              </button>
            </span>
          </DismissableAlert>
        </div>
      )} */}
      {roleType && roleType.includes("aggregator") && !signatoryComplete && (
        <div className="">
          <DismissableAlert dashboardAlert={true}>
            <span>
              Welcome ! Tap the button to finish setting up your Signatory
              <button
                className="btn btn-sm advancly-white-btn ml-3 border-0"
                onClick={() => history.push("/settings")}
              >
                Signatory Setup
              </button>
            </span>
          </DismissableAlert>
        </div>
      )}
      {error && <p className="alert alert-danger small">{error}</p>}
      <>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center ml-3">
            <h3 className="page-title">
              Hello,{" "}
              {aggregatorDetails &&
                capitalizeFirstLetter(aggregatorDetails?.rep_first_name)}
            </h3>
            <div className="d-flex justify-content-between align-items-center mb-1">
              <div className="d-flex">
                <CustomSelectDropdown
                  value={currentCurrency}
                  onChange={currencyChangeHandler}
                  // label="Currency"
                  className="ml-4 mr-2"
                >
                  {aggregatorCurrencies?.data?.map((currency, id) => (
                    <option key={id}>{currency}</option>
                  ))}
                </CustomSelectDropdown>

                {(aggregatorCurrenciesStatus || productStatus) ===
                  "loading" && <Loader />}
              </div>
            </div>
          </div>
          {roleType &&
            roleType.includes("aggregator_disbursement_initiator") && (
              <button
                className="btn  btn-sm advancly-btn   mb-2 "
                style={{ cursor: "pointer", padding: "0.75rem 2rem" }}
                data-toggle="modal"
                data-target="#SendMoneyModal"
              >
                Send Money
              </button>
            )}
        </div>

        <div className="d-flex justify-content-between align-items-center">
          {/* <p className="color-card-border-color">
            User ID: {aggregatorDetails?.aggregator_id}
          </p> */}
          {/* <p className="color-card-border-color">
            Wallet Acount Number: {aggregatorDetails?.account_number || "N/A"}
          </p> */}
        </div>
        {/* <div className="d-flex justify-content-between align-items-centers mb-1">
          <div className="d-flex">
            <CustomSelectDropdown
              value={currentCurrency}
              onChange={currencyChangeHandler}
              label="Currency"
            >
              {aggregatorCurrencies?.data?.map((currency, id) => (
                <option key={id}>{currency}</option>
              ))}
            </CustomSelectDropdown>
            {aggregatorCurrenciesStatus === "loading" && <Loader />}
          </div>
        </div> */}
        {/* <div className="d-flex justify-content-between align-items-center">
          {walletDetailsStatus === "loading" ? (
            <Loader />
          ) : (
            <p className="color-card-border-color">
              Wallet Balance:{" "}
              {walletDetails?.summary?.availableBalance === 0 ||
              walletDetails?.summary?.availableBalance
                ? formatMoney(
                    walletDetails?.summary?.availableBalance,
                    walletDetails?.summary?.currency?.code
                  )
                : "N/A"}{" "}
            </p>
          )}

          <button
            className="btn btn-sm btn btn-primary mb-2"
            style={{ cursor: "pointer" }}
            data-toggle="modal"
            data-target="#walletDetailsModal"
          >
            Fund Wallet
          </button>
        </div> */}
      </>
      {/* DASHBOARD LOANS */}
      {/* <div className="card-deck d-block d-lg-flex card-lg-gutters-4 mb-4">
        <DashboardCard
          text="Total amount disbursed"
          value={
            transactionsOverview &&
            formatMoneyNoDecimal(
              Math.trunc(transactionsOverview?.total_disbursement),
              transactionsOverview?.currency
            )
          }
          status={trasactionOverviewStatus}
        />
        <DashboardCard
          text="Total repayment"
          value={
            transactionsOverview &&
            formatMoneyNoDecimal(
              transactionsOverview?.total_repayment,
              transactionsOverview?.currency
            )
          }
          status={trasactionOverviewStatus}
        />
        <DashboardCard
          text="Total outstanding loan"
          value={
            totalOutstandingLoans &&
            formatMoneyNoDecimal(
              totalOutstandingLoans?.totalAmount,
              totalOutstandingLoans?.currency
            )
          }
          status={totalOutstandingStatus}
        />
        <DashboardCard
          text="Loan counts"
          value={loanCount >= 1 ? loanCount : 0}
          status={loanCountStatus}
        />
      </div>
       <div className="horizontal-line"></div>
      <DashboardGraphs currentCurrency={currentCurrency} /> */}
      {/* DASHBOARD LOANS */}

      {/* ACTIVE TABS FOR LOANS AND INVESTMENTS */}
      <div className="">
        <div className="px-3 pt-1">
          <Fragment>
            {/* <!-- Transactions Section --> */}
            <div className="alignToggleTabItems">
              <ToggleTab
                text="Loan Summary"
                id="loan_summary_dashboard"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              {activeProductName === "Embed" && isEmbeddedInvestment && (
                <ToggleTab
                  text="Investment Summary"
                  id="investment_summary_dashboard"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              )}
            </div>
            <div className="">
              <div className="bg-white">
                {(() => {
                  switch (activeTab) {
                    case "loan_summary_dashboard":
                      return (
                        <LoanDashboardTab
                          currentCurrency={currentCurrency}
                          activeProductName={activeProductName}
                          transactionsOverview={transactionsOverview}
                          trasactionOverviewStatus={trasactionOverviewStatus}
                          loanCount={loanCount}
                          loanCountStatus={loanCountStatus}
                          totalOutstandingLoans={totalOutstandingLoans}
                          totalOutstandingStatus={totalOutstandingStatus}
                        />
                      );
                    case "investment_summary_dashboard":
                      return (
                        <InvestmentDashboardTab
                          currentCurrency={currentCurrency}
                          activeProductName={activeProductName}
                          investmentOverview={investmentOverview}
                          investmentOverviewStatus={investmentOverviewStatus}
                        />
                      );

                    default:
                      return;
                  }
                })()}
              </div>
            </div>
          </Fragment>
        </div>
      </div>
      {/* END OF ACTIVE TABS FOR LOANS AND INVESTMENTS */}

      <WalletDetailsModal aggregatorDetails={aggregatorDetails} />
      <SendMoneyModal />
      {dueLoansCount && dueLoansCount > 0 && showModal ? (
        <LoanCountModal count={dueLoansCount} setShowModal={setShowModal} />
      ) : (
        ""
      )}
    </div>
  );
};

export default withRouter(Dashboard);
