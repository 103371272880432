import { Fragment } from "react";

import { Link } from "react-router-dom";
import BackButtonComponent from "../BackButton/BackButton.component";
import { ReactComponent as IndividualLoanRequestSVG } from "../../svg/icons/IndividualLoanRequest.svg";
import { ReactComponent as CorporateLoanRequestSVG } from "../../svg/icons/CorporateLoanRequest.svg";
import { ReactComponent as BulkLoanRequestSVG } from "../../svg/icons/BulkLoanRequest.svg";

import LoanRequestComponent from "./LoanRequestComponent";
import { useAuth } from "../../Context/auth.context";

const MakeLoanRequest = () => {
  const {
    setBorrowerSearchDetailsCorporate,
    setBorrowerSearchDetailsIndividual,
  } = useAuth();
  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Loan Request
        </h3>
        <Link className="color-blue font-weight-bold" to="/repayments">
          View Repayments
        </Link>
      </div>
      <p>Select a loan request type</p>
      <Fragment>
        <div>
          <LoanRequestComponent
            loanRequestHeader={"Individual Request"}
            loanRequestDetails={
              "Personal loans for borrowers within your ecosystem"
            }
            loanRequestLoanType={"/individual-loan-request"}
            resetBorrowerDetails={setBorrowerSearchDetailsIndividual()}
          >
            <IndividualLoanRequestSVG />
          </LoanRequestComponent>
        </div>
        <div>
          <LoanRequestComponent
            loanRequestHeader={"Corporate Request"}
            loanRequestDetails={
              "Loan for your business or business owners within your ecosystem"
            }
            loanRequestLoanType={"/corporate-loan-request"}
          >
            <CorporateLoanRequestSVG />
          </LoanRequestComponent>
        </div>

        <div>
          <LoanRequestComponent
            loanRequestHeader={"Bulk  Request"}
            loanRequestDetails={"Process multiple loan requests at once"}
            loanRequestLoanType={"/bulk-loan-request"}
            resetBorrowerDetails={setBorrowerSearchDetailsCorporate()}
          >
            <BulkLoanRequestSVG />
          </LoanRequestComponent>
        </div>
      </Fragment>
    </Fragment>
  );
};

export default MakeLoanRequest;
