import styles from "./Header.module.scss";
import NotificationIcon from "../../svg/icons/notification-icon.svg";
import CaretIcon from "../../svg/icons/caret-icon.svg";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { TNotifications } from "../../components/interfaces/notification";
import moment from "moment";
import { decrypt } from "../../helpers/encryptor";
import {
  capitalizeFirstLetter,
  getFirstLetter,
} from "../../helpers/capitalizeFirstLetter";
import { useQueryCache } from "react-query";
import Toggler from "../Toggler/Toggler.component";
import { useAuth } from "../../Context/auth.context";
import { useCallback, useEffect, useRef, useState } from "react";
import CONFIG from "../config";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData, putData } from "../../apis/apiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import Loader from "../Loader/Loader.component";
import { appInsights } from "../AppInsight/AppInsight";

// Header section updated
export default function Header() {
  const [moreContent, setMoreContent] = useState(false);
  const [notifications, setNotifications] = useState<
    TNotifications["data"] | []
  >([]);
  const [error, setError] = useState("");
  const [newMsg, setNewMsg] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const [readMsgs, setReadMsgs] = useState<number[]>([]);
  const { aggregatorDetails } = useAuth();
  const match = useRouteMatch();
  const history = useHistory();
  const queryCache = useQueryCache();
  const SSOUserId = decrypt(sessionStorage.getItem("ssoUserId"));
  const general_broadcast_hub = `${CONFIG.BASE_URL}${apiEndpoints.GENERAL_BROADCAST_HUB}`;
  useEffect(() => {
    const general_broadcast_connection = new HubConnectionBuilder()
      .withUrl(general_broadcast_hub)
      .configureLogging(LogLevel.Debug)
      .build();

    general_broadcast_connection
      .start()
      .then(() => {
        console.log("connected to general broadcast");
        general_broadcast_connection
          .invoke("GetConnectionId", SSOUserId)
          .then(function (connectionId: any) {
            // console.log(`Connection id: ${connectionId}`);
            console.log(`Connected to ropc method `);
          })
          .catch((err: any) => {
            appInsights.trackException({
              exception: err,
              properties: { fileName: "Header.component.tsx" },
            });
            console.log({ err });
          });
      })
      .catch((err: any) => {
        appInsights.trackException({
          exception: err,
          properties: { fileName: "Header.component.tsx" },
        });
        console.log(`Error starting the connection: ${err.toString()}`);
      });
    general_broadcast_connection.onclose(async () => {
      console.log("disconnected from general broadcast");
    });

    // subscribe to new general msg from server
    general_broadcast_connection.on("sendToAll", function (message: any) {
      console.log("message from server = ", message);
      handleFetchNotifications();
    });

    //subscribe to new specific msg from server
    general_broadcast_connection.on("sendToSpecific", (response: any) => {
      console.log({ response });
      handleFetchNotifications();
    });
  }, [SSOUserId, general_broadcast_hub]);

  const handleFetchNotifications = async () => {
    setIsLoading(true);
    try {
      let response: any = await getData(apiEndpoints.NOTIFICATION_LIST);
      console.log(response);

      if (response.totalUnRead > 0) {
        setNewMsg(true);
      } else {
        setNewMsg(false);
      }
      setNotifications(response.data);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Header.component.tsx" },
      });
      setError(errorHandler(error));
      setTimeout(() => {
        setError("");
      }, 2000);
    }
    setIsLoading(false);
  };

  const handleSetMessageAsRead = useCallback(async () => {
    if (readMsgs.length !== 0) {
      let allNotificationIds = [];
      for (let i = 0; i < readMsgs.length; i++) {
        allNotificationIds.push(readMsgs[i]);
      }
      setIsLoading(true);
      try {
        await putData(apiEndpoints.SET_NOTIFICATION_AS_READ, {
          ids: allNotificationIds,
        });
        setReadMsgs([]);
        handleFetchNotifications();
      } catch (error: any) {
        appInsights.trackException({
          exception: error,
          properties: { fileName: "Header.component.tsx" },
        });
        setError(errorHandler(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    }
    setIsLoading(false);
  }, [readMsgs]);

  const handleClearAllMsgs = async () => {
    let allNotificationIds = [];
    for (let i = 0; i < notifications.length; i++) {
      allNotificationIds.push(notifications[i].id);
    }
    setIsLoading(true);
    try {
      await putData(apiEndpoints.SET_NOTIFICATION_AS_READ, {
        ids: allNotificationIds,
      });
      setNotifications([]);
      handleFetchNotifications();
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Header.component.tsx" },
      });
      setError(errorHandler(error));
      setTimeout(() => {
        setError("");
      }, 2000);
    }
    setIsLoading(false);
    setReadMsgs([]);
  };

  useEffect(() => {
    handleFetchNotifications();
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
        handleSetMessageAsRead();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open, handleSetMessageAsRead]);

  const logout = (): void => {
    sessionStorage.clear();
    history.push("/login");

    // Remove the Query Result for aggregator details from cache
    queryCache.removeQueries(["getAggregatorDetails"], { exact: true });
  };
  //navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });
  return (
    <div
      className={`${styles.headerSection} ${
        navbar && styles.headerSectionScrollNav
      }`}
    >
      <div className={styles.logo}></div>
      <div className={styles.actionButtons}>
        <Toggler />
        {/* notification bell */}
        <div className={styles.notificationBox} ref={ref}>
          {newMsg && <div className={styles.notificationDot}></div>}
          <a
            href="##"
            // id="navbarDropdownBell"
            className={styles.dropdownNotificationButtonArea}
            role="button"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <img src={NotificationIcon} alt="Notification" />
          </a>
          {open && (
            <div
              className={` ${styles.dropdownNotificationMenu} menu-item `}
              aria-labelledby="navbarDropdownBell"
            >
              <div className={styles.notificationHeader}>
                <p className={styles.notifyheading}>Notifications</p>
                {notifications?.length > 0 && (
                  <div className={styles.clear_close}>
                    <small
                      className={styles.clear_all}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClearAllMsgs();
                      }}
                    >
                      Clear all
                    </small>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="menu-item"
                      aria-label="Close"
                      onClick={() => {
                        setOpen(false);
                        handleSetMessageAsRead();
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
              </div>

              {isLoading ? (
                <div className="no-advancly-data text-center">
                  <Loader />
                  <div className="mt-3 text-center">
                    Loading Notifications...
                  </div>
                </div>
              ) : (
                <div
                  className={`${styles.notification_overview}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {error && (
                    <div>
                      <p className="alert alert-danger alert-dismissable">
                        {error}
                      </p>
                    </div>
                  )}

                  <div className={styles.notification}>
                    {notifications?.length === 0 ? (
                      <p className="text-center color-blue">
                        You have no notifications
                      </p>
                    ) : (
                      notifications?.map((item, index) => (
                        <div key={item.id}>
                          <div className={styles.notification_content}>
                            <div className={styles.notifyAlert}>
                              <p className={styles.notification_title}>
                                {item.title}
                              </p>
                              {newMsg && (
                                <div>
                                  <div
                                    className={styles.notificationContentDot}
                                  ></div>
                                </div>
                              )}
                            </div>
                            {moreContent && item.id === selectedMsg && (
                              <small className={styles.notification_contents}>
                                {item.message}
                              </small>
                            )}
                            <small className={styles.notification_time}>
                              {moment(item.createdAt)
                                .startOf("minute")
                                .fromNow()}
                            </small>

                            <button
                              className={styles.seeMore}
                              onClick={() => {
                                setSelectedMsg(item.id);
                                setMoreContent(!moreContent);

                                if (!readMsgs.includes(item.id)) {
                                  setReadMsgs([...readMsgs, item.id]);
                                }
                              }}
                            >
                              {moreContent && item.id === selectedMsg
                                ? "Hide"
                                : "See more"}
                            </button>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {/* end of notification bell */}
        <div className={styles.verticalLine}></div>
        <div className={styles.profileNameBox}>
          <div className={styles.profileNameInnerBox}>
            <div className={styles.profileNameInnerBoxWithContent}>
              {aggregatorDetails
                ? getFirstLetter(
                    capitalizeFirstLetter(
                      aggregatorDetails && aggregatorDetails.rep_first_name
                    )
                  )
                : "."}
            </div>
          </div>
        </div>
        <div className={styles.dropdown}>
          <a
            href="##"
            id="navbarDropdown"
            className={styles.dropdownButtonArea}
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src={CaretIcon} alt="Caret Dropdown" />
          </a>
          <div
            className={`dropdown-menu ${styles.dropdownMenu}`}
            aria-labelledby="navbarDropdown"
          >
            <h5 className={styles.profileName}>
              {aggregatorDetails
                ? `${aggregatorDetails.rep_first_name} ${aggregatorDetails.rep_last_name}`
                : "..."}
            </h5>
            <p className={styles.profileEmail}>
              {aggregatorDetails ? aggregatorDetails.rep_personal_email : "..."}
            </p>

            <hr />

            <Link className={styles.navLink} to={match.path + "settings"}>
              <span>Settings</span>
            </Link>
            <p
              className={`${styles.navLink} ${styles.logoutLink}`}
              onClick={logout}
            >
              <span>Log Out</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
