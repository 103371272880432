import React, { Fragment } from "react";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import SecurityQuestionModal from "../WalletTransactionModals/SecurityQuestionModal";
import SetSignatoryLandingPage from "../NewWalletSignatorySetUp/SetSignatoryLandingPage";
import WalletResetModal from "../WalletResetPinModal/WalletResetModal";
import useAggregatorDetails from "../../custom-hooks/useAggregatorDetails";
import useAggregatorSignatories from "../../custom-hooks/useAggregatorSignatories";
import Loader from "../Loader/Loader.component";
import { decrypt } from "../../helpers/encryptor";
import ChangeSignatoryLandingPage from "../ChangeSignatory/ChangeSingleSingnatorySteps/ChangeSignatoryLandingPage";
import ChangeMultipleSignatoryLandingPage from "../ChangeSignatory/ChangeMultipleSignatorySteps/ChangeMultipleSignatoryLandingPage";
const WalletSignatorySetup = () => {
  const roleType = JSON.parse(decrypt(sessionStorage.getItem("roleTypes")));
  const { data: aggregatorDetails, status } = useAggregatorDetails();
  const { data: signatories, status: signatoriesStatus } =
    useAggregatorSignatories({ roleType });
  const sign = signatories?.some((signatory) => signatory.email);
  return (
    <div className="card">
      <div className="card-body p-4">
        <h5 className="page-subtitle">Wallet Transfer Settings</h5>
        <div className="d-flex pt-2">
          {status === "loading " && (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}
          {signatoriesStatus === "loading " && (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}
          {!aggregatorDetails?.has_set_pin ? (
            <div className="">
              <p className="color-mid-grayish form-label"> Transaction PIN</p>
              <button
                data-toggle="modal"
                data-target="#securityQuestionModal"
                className="btn advancly-white-btn btn-sm transition-3d-hover"
              >
                Set PIN
              </button>
            </div>
          ) : (
            <div className="">
              <p className="color-mid-grayish form-label"> Transaction PIN</p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#walletResetModal"
                className="btn advancly-white-btn btn-sm transition-3d-hover"
              >
                Reset PIN
              </button>
            </div>
          )}
          {roleType && roleType.includes("aggregator") && !sign && (
            <div className="ml-3">
              <p className="color-mid-grayish form-label">Signatory(ies)</p>
              <button
                data-toggle="modal"
                data-target="#SetSignatoryLandingPage"
                className="btn advancly-white-btn btn-sm  transition-3d-hover"
              >
                Set Signatories
              </button>
            </div>
          )}
          {roleType &&
            roleType.includes("aggregator") &&
            sign &&
            signatories?.length === 1 && (
              <div className="ml-3">
                <p className="color-mid-grayish form-label">Signatory(ies)</p>
                <button
                  data-toggle="modal"
                  data-target="#changeSignatoryLandingPage"
                  className="btn advancly-white-btn btn-sm  transition-3d-hover"
                >
                  Change Signatories
                </button>
              </div>
            )}
          {roleType &&
            roleType.includes("aggregator") &&
            sign &&
            signatories?.length > 1 && (
              <div className="ml-3">
                <p className="color-mid-grayish form-label">Signatory(ies)</p>
                <button
                  data-toggle="modal"
                  data-target="#changeMultipleSignatoryLandingPage"
                  className="btn advancly-white-btn btn-sm  transition-3d-hover"
                >
                  Change Signatories
                </button>
              </div>
            )}
        </div>
        {roleType && roleType.includes("aggregator") && sign && (
          <Fragment>
            <div className="row pt-2">
              <p className="col color-mid-grayish form-label pt-2 mt-2 ">
                Current Signatory(ies)
              </p>
            </div>
            <div className="row">
              {signatories?.map(({ email, levelNo }) => (
                <div
                  key={levelNo}
                  className="col-sm-12 col-md-4 pb-2   mt-2 form-group"
                >
                  <label className="color-mid-grayish form-label">{`Level ${levelNo}`}</label>
                  <CustomInputField
                    name={`Level ${levelNo}`}
                    type="text"
                    defaultValue={email}
                    readOnly
                  />
                </div>
              ))}
            </div>
          </Fragment>
        )}
      </div>
      <SecurityQuestionModal />
      <SetSignatoryLandingPage />
      <ChangeSignatoryLandingPage
        signatories={signatories}
        signatoriesStatus={signatoriesStatus}
      />
      <ChangeMultipleSignatoryLandingPage
        signatories={signatories}
        signatoriesStatus={signatoriesStatus}
      />
      <WalletResetModal />
    </div>
  );
};

export default WalletSignatorySetup;
