import React from "react";
import styles from "./DismissableAlert.module.scss";

export default function DismissableAlert({
  children,
  dashboardAlert,
  onDismiss,
}) {
  return (
    <div
      className={`alert alert-dismissible fade show alert-warning ${
        dashboardAlert && styles.dashboardAlert
      }`}
      role="alert"
    >
      <div className="text-center">{children}</div>
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={() => {
          if (onDismiss) {
            onDismiss();
          }
        }}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
}
