import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getWalletDetails = async () => {
  const data = await getDataWithDotNet(apiEndpoints.getWalletDetails);
  return data.data;
};

export default function useWalletDetails() {
  return useQuery(["getWalletDetails"], getWalletDetails, {
    refetchOnWindowFocus: false,
  });
}
