import React from "react";
import { Route, withRouter } from "react-router-dom";
import BorrowerProfile from "../../../components/BorrowerProfile";
import AllDisbursedLoansMain from "./AllDisbursedLoansMain";

const AllDisbursedLoans = (props) => {
  let match = props.match;
  return (
    <div>
      <Route exact path={match.path} render={() => <AllDisbursedLoansMain />} />
      <Route
        path={match.path + "/:borrower_id"}
        render={() => <BorrowerProfile />}
      />
    </div>
  );
};
export default withRouter(AllDisbursedLoans);
