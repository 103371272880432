import React, { useEffect, useState } from "react";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../components/Paginator/Paginator.component";
import moment from "moment";
import { useHistory } from "react-router-dom";
import useBorrowerDetails from "../../custom-hooks/useBorrowerDetails";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import { appInsights } from "../../components/AppInsight/AppInsight";

export default function BorrowersMain() {
  const [searchString] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [searchValue, setSearchValue] = useState("");

  const { data, status } = useBorrowerDetails({ searchString });

  let history = useHistory();

  const handleClick = (props) => {
    history.push(`/borrowers/${props.borrower_id}`);
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "BorrowersMain.jsx",
      isLoggedIn: true,
    });
  }, []);

  if (status === "loading") return <PageLoader />;

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h3 className="page-title mb-4">Borrowers</h3>
        </div>
        <div className="d-flex align-items-center mb-2">
          <div className="input-group mb-3">
            <input
              type="text"
              className="mr-0 search-query form-control"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <div className="input-group-append">
              <span className="input-group-text bg-white">
                <span className="fa fa-search"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body p-3">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="page-subtitle">Borrower List</h5>
            <div className="d-flex align-items-center">
              <p className="pr-2 pt-1">Showing:</p>
              <CustomSelectDropdown
                onChange={(e) => setSize(e.target.value)}
                value={size}
              >
                <option value={10}>10 Entries</option>
                <option value={20}>20 Entries</option>
                <option value={50}>50 Entries</option>
                <option value={100}>100 Entries</option>
              </CustomSelectDropdown>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>Name</th>
                  <th>Sex</th>
                  <th>Borrower Type</th>
                  <th>Category Type</th>
                  <th>Business Name</th>
                  <th>Phone Number</th>
                  <th>Onboarding Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length ? (
                  data
                    .filter(
                      (borrower) =>
                        (borrower.first_name &&
                          borrower.first_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (borrower.last_name &&
                          borrower.last_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (borrower.gender &&
                          borrower.gender
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (borrower.borrower_phone &&
                          borrower.borrower_phone
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (borrower.pub_date &&
                          borrower.pub_date
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()))
                    )
                    .slice(
                      (pageNumber - 1) * size,
                      size * (pageNumber - 1) + size
                    )
                    .map((data, id) => {
                      const {
                        first_name,
                        last_name,
                        gender,
                        borrower_type_value,
                        my_biz_name,
                        borrower_phone,
                        pub_date,
                        customer_category,
                      } = data;
                      return (
                        <tr key={id}>
                          <td className="text-capitalize">
                            {first_name && first_name.toLowerCase()}{" "}
                            {last_name && last_name.toLowerCase()}
                          </td>
                          <td className="text-capitalize">{gender}</td>
                          <td>{borrower_type_value}</td>
                          <td>{customer_category ? customer_category : " "}</td>

                          <td>{my_biz_name ? my_biz_name : "N/A"}</td>
                          <td>{borrower_phone}</td>
                          <td>{moment(pub_date).format("DD-MM-YYYY")}</td>
                          <td
                            className="color-mid-blue cursor-pointer"
                            onClick={() => handleClick(data)}
                          >
                            View Details
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <NoTableDataAvailable />
                )}
              </tbody>
            </table>
          </div>
          <Paginator
            size={size}
            page={pageNumber}
            count={data ? data.length : 0}
            changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
          />
        </div>
      </div>
    </div>
  );
}
