import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getWalletInformation = async ({
  accountNumber,
  consumerType,
  walletProviderId,
}) => {
  let queryParams = `AccountNumber=${accountNumber}&ConsumerType=${consumerType}&walletProviderId=${walletProviderId}`;

  const data = await getData(
    `${apiEndpoints.WALLET_INFORMATION}?${queryParams}`
  );

  return data;
};

export default function useWalletInformation({
  accountNumber,
  consumerType,
  walletProviderId,
}) {
  return useQuery(
    [{ accountNumber, consumerType, walletProviderId }, "getWalletInformation"],
    getWalletInformation,
    { enabled: accountNumber && walletProviderId ? true : false }
  );
}
