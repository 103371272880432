import { useEffect, useState } from "react";
import Paginator from "../../components/Paginator/Paginator.component";
import { Link, withRouter } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import { useAuth } from "../../Context/auth.context";
import useAllInvestments from "../../custom-hooks/useAllInvestments";
import { formatMoney } from "../../helpers/formatter";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import { decrypt } from "../../helpers/encryptor";
import CONFIG from "../../components/config";
import Loader from "../../components/Loader/Loader.component";
import { appInsights } from "../../components/AppInsight/AppInsight";
function AllInvestorsMain() {
  const { aggregatorDetails } = useAuth();
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    searchBy: 3,
    aggregatorId: aggregatorDetails?.aggregator_id,
  });
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    aggregatorId: aggregatorDetails?.aggregator_id,
    startDate: "",
    endDate: "",
    searchValue: "",
  });
  const [count, setCount] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { data, status, error } = useAllInvestments(preState);
  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const handleDownload = () => {
    setDownloadLoading(true);
    const getToken = sessionStorage.getItem("token");
    const token = decrypt(getToken);
    let queryParams = `?AggregatorId=${state.aggregatorId}`;
    if (state.startDate && state.endDate) {
      queryParams = `${queryParams}&FromDate=${state.startDate}&ToDate=${state.endDate}`;
    }
    if (state.searchValue) {
      queryParams = `${queryParams}&FunderName=${state.searchValue}`;
    }
    setPreState(state);
    fetch(CONFIG.BASE_URL + `/account/download_investments${queryParams}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: "Token " + token,
      },
      // body: JSON.stringify(data)
    })
      .then((response) => {
        setDownloadLoading(false);
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${
                state.startDate && state.endDate
                  ? `Transactions from ${state.startDate} to ${state.endDate}.csv`
                  : `Transactions.csv`
              }`
            );
            link.click();
          });
        }

        if (response.status === 403 || response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/login";
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch(() => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AllInvestments.jsx",
          },
        });
        // this.setState({ loading: false, error: "Something went wrong. Please check your internet connection and try again" });
        setDownloadLoading(false);
      });
  };
  const filterData = (e) => {
    e.preventDefault();
    setCount(null);
    setPreState({ ...state, pageNumber: 1 });
  };
  useEffect(() => {
    appInsights.trackPageView({ name: "AllInvestments.jsx", isLoggedIn: true });
  }, []);
  return (
    <div>
      {error && <p className="alert alert-danger small">{error}</p>}
      {!error && (
        <div>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h3 className="page-title mb-4">Investments</h3>
            <button
              type="button"
              className="btn btn-sm advancly-white-btn m-0"
              onClick={handleDownload}
              disabled={status === "loading" || downloadLoading}
            >
              <i className="fas fa-download mr-1" />
              Download {downloadLoading && <Loader />}
            </button>
          </div>
          <div className="card">
            {status === "loading" ? (
              <div className="card-body p-3">
                <PageLoader />
              </div>
            ) : (
              <div className="card-body p-3">
                <div className="d-flex justify-content-between align-items-center py-3">
                  <form
                    className="d-flex flex-row flex-wrap"
                    onSubmit={filterData}
                  >
                    <div
                      className="input-group mr-3"
                      style={{ maxWidth: "200px", marginTop: "10px" }}
                    >
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Name..."
                        value={state.searchValue}
                        name="searchValue"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div style={{ width: "120px" }} className="mr-3">
                      <CustomSelectDropdown
                        onChange={(e) => handleChange(e)}
                        name="currency"
                        value={state.currency}
                        className=" "
                      >
                        <option value="">Currency</option>
                        <option value="NGN">Naira</option>
                        <option value="USD">USD</option>
                      </CustomSelectDropdown>
                    </div>
                    <div className="form-group mr-3">
                      <div className="input-group mt-2">
                        <label className="mr-2 mt-2">From </label>
                        <input
                          type="date"
                          name="startDate"
                          className="form-control"
                          value={state.startDate}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group mr-3 mt-2">
                      <div className="input-group">
                        <label className="mr-2 mt-2">To </label>
                        <input
                          type="date"
                          className="form-control"
                          name="endDate"
                          value={state.endDate}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn advancly-btn btn-sm mt-2"
                        value="submit"
                        disabled={status === "loading"}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead className="bg-lighter-grey">
                      <tr>
                        <th>Investor</th>
                        <th>Amount Invested</th>
                        <th>Tenor</th>
                        <th>Monthly Interest</th>
                        <th>Date Invested</th>
                        <th>Maturity Date</th>
                        <th>Amount Due</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.length ? (
                        data.data.map((request, _id) => {
                          const {
                            funder_name,
                            principal_amount,
                            currency,
                            tenure,
                            monthly_interest_repayment,
                            effective_date,
                            maturity_date,
                            outstanding_interest_amount,
                            funder_id,
                            // investment_status,
                          } = request;
                          return (
                            <tr key={_id}>
                              <td>{funder_name}</td>
                              <td>{formatMoney(principal_amount, currency)}</td>
                              <td>{tenure}</td>
                              <td>
                                {formatMoney(
                                  monthly_interest_repayment,
                                  currency
                                )}
                              </td>
                              {/* <td>
                                <span
                                  className={attachClassNameToTag(
                                    investment_status
                                  )}
                                >
                                  {attachClassNameToTag(investment_status)}
                                </span>
                              </td> */}
                              <td>
                                {effective_date
                                  ? effective_date.substring(0, 10)
                                  : "N/A"}
                              </td>
                              <td>
                                {maturity_date
                                  ? maturity_date.substring(0, 10)
                                  : "N/A"}
                              </td>
                              <td>
                                {formatMoney(
                                  outstanding_interest_amount,
                                  currency
                                )}
                              </td>
                              <td>
                                <Link
                                  to={{
                                    pathname: `/investment-details/${funder_id}`,
                                    data: request,
                                  }}
                                  className="color-blue"
                                >
                                  View Details
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
                {data && data?.data && (
                  <Paginator
                    size={preState.size}
                    page={preState.pageNumber}
                    count={count}
                    changeCurrentPage={(pageNumber) =>
                      setPreState({ ...state, pageNumber })
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default withRouter(AllInvestorsMain);
