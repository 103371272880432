import { decrypt } from "../../helpers/encryptor";

import { Redirect, Route } from "react-router-dom";
interface IPrivateRoute {
  component: React.ComponentType;

  path: string;

  permittedRoles: string[];

  exact: boolean;
}
const PrivateRoute = ({
  component: PrivateComponent,

  path,

  permittedRoles,

  exact,
}: IPrivateRoute) => {
  const roleTypes = JSON.parse(decrypt(sessionStorage.getItem("roleTypes")));

  const isUserPermitted = (permittedRolesForRoute: string[]): boolean => {
    const truth = [] as boolean[];

    permittedRolesForRoute.forEach((_permittedRole) => {
      const result = roleTypes.includes(_permittedRole);

      if (result) {
        truth.push(true);
      } else {
        truth.push(false);
      }
    });

    const finalResult = truth.every((item) => item === true);

    if (finalResult) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        return isUserPermitted(permittedRoles) ? (
          <PrivateComponent />
        ) : (
          <Redirect to={{ pathname: "/access-denied" }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
