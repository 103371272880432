import React from "react";

import moment from "moment";

import style from "./comments.module.scss";

const CommentsDocs = ({ comments }) => {
  return (
    <div>
      <div className={style.commentsContainer}>
        <div className={style.commentsSection}>
          {comments?.length ? (
            comments?.map((comment, index) => (
              <div key={index} className={style.existingComments}>
                <p>
                  {comment?.first_name} {comment?.last_name}{" "}
                  <span>({comment?.user_type})</span>
                  <span>
                    {moment(comment?.datetime_created).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </span>
                </p>
                <p className={style.comments}>{comment.comment}</p>
              </div>
            ))
          ) : (
            <div>
              <p>No comments Available</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentsDocs;
