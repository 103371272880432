import React, { useEffect, useState } from "react";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { getData } from "../../../apis/apiMethods";
import { errorHandler } from "../../../helpers/errorHandler";
import { appInsights } from "../../AppInsight/AppInsight";
import NoTableDataAvailable from "../../NoTableDataAvailable/NoTableDataAvailable.component";
import PageLoader from "../../PageLoader/PageLoader.component";
import Paginator from "../../Paginator/Paginator.component";

const HistoryWebHookModal = ({ webhookEventId }) => {
  const [loading, setLoading] = useState();
  const [submitError, setSubmitError] = useState();
  const [responseData, setResponseData] = useState();
  const [count, setCount] = useState(null);
  const [preState, setPreState] = useState({
    page: 1,
    PageSize: 20,
  });
  // const [state, setState] = useState({
  //   page: 1,
  //   PageSize: 20,
  // });

  useEffect(() => {
    const webHookHistoryApi = async () => {
      setLoading(true);
      try {
        const res = await getData(
          `${apiEndpoints.WEB_HOOK_HISTORY}?WebhookSubscriptionId=${webhookEventId}&Page=${preState.page}&PageSize=${preState.PageSize}`
        );
        setResponseData(res);
        setLoading(false);
      } catch (error) {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "HistoryWebHookModal.jsx",
          },
        });
        setSubmitError(errorHandler(error));
        setLoading(false);
      }
    };
    webHookHistoryApi();
  }, [preState, webhookEventId]);

  if (responseData) {
    if (count === null) {
      setCount(responseData?.totalCount);
    }
  }
  return (
    <div>
      <div
        className="modal fade"
        id="webHookHistoryModal"
        tabindex="-1"
        aria-labelledby="webHookHistoryModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl mr-4">
          {submitError && (
            <small className="text-dangeralert alert-danger small text-center">
              {submitError}
            </small>
          )}
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="webHookHistoryModalLabel">
                History
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className=" p-3">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="">
                      <tr>
                        <th>Status Code</th>
                        <th>Webhook ID </th>
                        <th>Request Payload</th>
                        <th>Response Payload</th>
                        <th>Date Sent</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <div className="d-flex justify-content-center align-items-center ">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <PageLoader />
                        </div>
                      ) : responseData ? (
                        <>
                          {responseData?.data?.map(
                            ({
                              createdAt,
                              id,
                              requestBody,
                              responseBody,
                              statusCode,
                              webhookSubscriptionId,
                            }) => {
                              return (
                                <tr key={id}>
                                  <td>{statusCode}</td>
                                  <td>{webhookSubscriptionId}</td>
                                  <td className="color-mid-blue cursor-pointer text-decoration-underline">
                                    {requestBody}
                                  </td>
                                  <td className="color-mid-blue cursor-pointer ">
                                    {responseBody}
                                  </td>
                                  <td className="color-mid-blue cursor-pointer ">
                                    {createdAt.substring(0, 10)}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {responseData && (
                <Paginator
                  size={preState.PageSize}
                  page={preState.page}
                  count={count}
                  changeCurrentPage={(page) =>
                    setPreState({ ...preState, page })
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryWebHookModal;
