import React from "react";
import { withRouter } from "react-router-dom";

const OnboardingBackWordButton = ({ history }) => {
  return (
    <div className={` mt-3`} style={{ width: "60%" }}>
      {" "}
      <small
        className="color-mid-blue  cursor-pointer font-weight-bold  "
        onClick={() => history.goBack()}
      >
        &#8592; Go back
      </small>
    </div>
  );
};
export default withRouter(OnboardingBackWordButton);
