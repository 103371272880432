import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
// import sparkImage from "../../img/spark_sign_up.svg";
import sparkImageC from "../../img/SparkImageC.png";
import advanclyLogo from "../../img/advancly_logo.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styles from "./Login.module.scss";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { yupValidators } from "../../helpers/yupValidators";
import { postDataWithoutTokenWithDotNet } from "../../apis/dotNetApiMethods";
import { setUserInfo } from "../../helpers/setUserInfo";
import ROUTES from "../../helpers/ROUTES";
import { useAuth } from "../../Context/auth.context";
import {
  isActiveAggregator,
  isSubsequentAggregatorOnboarded,
} from "../../helpers/isActiveAggregator";
import { setLoginInfo } from "../../helpers/setLoginInfo";
import LoaderSignUp from "../LoaderSignUp/LoaderSignUp";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  username: yupValidators.email,
  password: yup.string().required("Please enter your password"),
});

const Login = () => {
  useEffect(() => {
    // This clears everything in the session storage
    sessionStorage.clear();
  }, []);
  const { setEligibilityData, setAggregatorDetails } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [changePasswordMessage, setChangePasswordMessage] = useState("");
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ username, password }) => {
    setLoading(true);
    setError(false);
    setChangePasswordMessage("");

    const reqBody = {
      username,
      password,
    };

    try {
      const res = await postDataWithoutTokenWithDotNet(
        apiEndpoints.loginUrl,
        reqBody,
        true // Boolean here means that this endpoint should call production URL only
      );

      //check user type
      if (
        res.user_type === "aggregator" ||
        res.user_type === "aggregator_user" ||
        res.user_type === "funder_admin"
      ) {
        // This sets everything in the sessionStorage / localStorage

        setUserInfo(res, false); // false signifies that user isn't on SSO by default

        const { aggregatorResult, eligibilityResult } = await setLoginInfo();

        setAggregatorDetails(aggregatorResult);

        setEligibilityData(eligibilityResult);

        setLoading(false);
        //redirect to dashboard
        if (res.forceChangePassword) {
          setChangePasswordMessage(
            "Your login was successful. You will be required to change your password, please do."
          );
          setTimeout(() => {
            history.push({
              pathname: "/settings",
              state: {
                forcedChangeOfPassword: true,
              },
            });
          }, 3000);
        } else {
          if (!eligibilityResult || !isActiveAggregator(eligibilityResult)) {
            window.location.replace(ROUTES.GET_STARTED);
          } else if (
            !eligibilityResult ||
            !isSubsequentAggregatorOnboarded(eligibilityResult)
          ) {
            window.location.replace(ROUTES.GET_STARTED);
          } else {
            window.location.replace(ROUTES.DASHBOARD);
          }
        }
      } else {
        setLoading(false);
        setError(
          "The login details you entered are not for an aggregator account"
        );
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Login.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  return (
    <main role="main" className={`bg-white ${styles.loginLayout}`}>
      <div className={styles.left}>
        <Link className={styles.sparkLogoLink} to="/login">
          <img
            className={styles.sparkLogo}
            src={advanclyLogo}
            alt="Login logo"
          />
        </Link>
        <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <h3 className="h3 color-blue font-weight-semi-bold mb-2">
              Welcome Back.
            </h3>
            <p className="color-card-border-color">
              Log in to manage your account.
            </p>
          </div>
          {error && <p className="alert alert-danger small">{error}</p>}
          {changePasswordMessage && (
            <p className="alert alert-success small">{changePasswordMessage}</p>
          )}
          <CustomInputField
            type="email"
            maxLength="128"
            reference={register}
            name="username"
            placeholder="morty@ricknmorty.com"
            label="Email address"
            errors={errors.username}
            disableAutoComplete={true}
          />
          <CustomInputField
            type="password"
            maxLength="128"
            reference={register}
            name="password"
            placeholder="********"
            label="Password"
            errors={errors.password}
            hasActionButton={true}
            disableAutoComplete={true}
          />
          <div className="d-flex justify-content-end mb-3">
            <Link
              className="text-capitalize color-mid-blue forgot-password font-weight-bold"
              to={ROUTES.RESET_PASSWORD}
            >
              Forgot Password?
            </Link>
          </div>
          <div className="row align-items-center mb-5">
            <div className="col-6">
              <span className="small text-muted">Don't have an account? </span>
              <Link className="small color-mid-blue" to="/signup">
                Sign up
              </Link>
            </div>
            <div className="col-6 text-right">
              <button
                type="submit"
                value="submit"
                className="btn advancly-btn btn-sm transition-3d-hover"
                disabled={loading}
              >
                Log In
                {loading && <LoaderSignUp />}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className={styles.right}>
        <div className={styles.content}>
          <img
            className={styles.illustrator}
            // src={sparkImage}
            src={sparkImageC}
            alt="Login Illustration"
          />
        </div>
      </div>
    </main>
  );
};

export default Login;
