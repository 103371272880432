import AddNewUserModalContent from "./AddNewUserModalContent";

export default function AddNewUserModal() {
  return (
    <div
      className="modal fade"
      id="addNewUserModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addNewUserModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <AddNewUserModalContent />
      </div>
    </div>
  );
}
