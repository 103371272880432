import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getAllABorrowerRepayments = async ({ id }) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.getAllABorrowerRepayments}/${id}`
  );
  return data.data;
};

export default function useAllABorrowerRepayments({ id }) {
  return useQuery(
    [{ id }, "getAllABorrowerRepayments"],
    getAllABorrowerRepayments,
    {
      refetchOnWindowFocus: false,
    }
  );
}
