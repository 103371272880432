import { FC, Fragment, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { formatGraphData } from "../../../helpers/formatGraphData";
import { formatMoney } from "../../../helpers/formatter";
import graphDropdownOptions from "../../../helpers/graphDropdownOptions";
import { GRAPH_SCALE } from "../../../helpers/graphOptions";
import Loader from "../../Loader/Loader.component";
import GraphSubTitle from "../GraphComponents/GraphSubTitleSection/GraphSubTitle.component";
import GraphTitleSection from "../GraphComponents/GraphTitleSection/GraphTitleSection.component";
import NoTableDataAvailable from "../../NoTableDataAvailable/NoTableDataAvailable.component";
import BarChartSingle from "../../Charts/BarChartSingle/BarChartSingle.component";
import EmptyBarChartSingle from "../../Charts/BarChartSingle/EmptyBarChartSingle.component";
import useInvestmentInterestGraph from "../../../custom-hooks/useInvestmentInterestGraph";
import useRecentInvestmentInterest from "../../../custom-hooks/useRecentInvestmentInterest";

interface IInvestmentInterestGraph {
  aggregatorId: number;
  currentCurrency: string;
  activeProductName: string;
}

const InvestmentInterestGraph: FC<IInvestmentInterestGraph> = ({
  aggregatorId,
  currentCurrency,
  activeProductName,
}) => {
  const [filter, setFilter] = useState(graphDropdownOptions.thisWeek);
  const match = useRouteMatch();
  const { data, status, error } = useInvestmentInterestGraph({
    aggregatorId,
    filter,
    currentCurrencyValue: currentCurrency,
    activeProductName: activeProductName,
  });
  const {
    data: recentInvestmentInterestData,
    status: recentInvestmentInterestStatus,
    error: recentInvestmentInterestError,
  } = useRecentInvestmentInterest({
    currentCurrencyValue: currentCurrency,
    activeProductName,
  });

  //JSX Template
  let RenderedComponent = null;

  if (status === "loading") {
    RenderedComponent = (
      <div className="d-flex justify-content-center align-items-center m-h-100">
        <Loader />
      </div>
    );
  } else if (error) {
    RenderedComponent = (
      <h5 className="text-danger text-center font-weight-bold">
        An error occured
      </h5>
    );
  } else if (data) {
    if (!data?.length) {
      // Please remember to alter this when valid data is available
      RenderedComponent = <EmptyBarChartSingle />;
    } else {
      RenderedComponent = (
        <BarChartSingle
          isDisbursement={false}
          graphData={formatGraphData(data)}
        />
      );
    }
  }

  return (
    <div>
      <div className="card">
        <div className="card-body p-3">
          <GraphTitleSection
            filter={filter}
            setFilter={setFilter}
            title="Interests"
          >
            <p className="text-dark">
              in {GRAPH_SCALE.toLocaleString("en-NG")}
            </p>
          </GraphTitleSection>
          <Fragment>{RenderedComponent}</Fragment>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body p-3">
          <GraphSubTitle
            text="Recent Interest"
            to={match.path + "investments"}
          />

          <Fragment>
            {recentInvestmentInterestStatus === "loading" ? (
              <div className="d-flex justify-content-center align-items-center m-h-100">
                <Loader />
              </div>
            ) : recentInvestmentInterestError ? (
              <h5 className="text-danger text-center font-weight-bold">
                An error occured
              </h5>
            ) : recentInvestmentInterestData &&
              recentInvestmentInterestData.length ? (
              <ul className="dashboard-graph-list">
                {recentInvestmentInterestData
                  .slice(0, 3)
                  .map((data: any, _i: number) => {
                    const { funderName, amount, currency, investmentId } = data;
                    return (
                      <li key={investmentId}>
                        <span>{funderName}</span>{" "}
                        <span>{formatMoney(amount, currency)}</span>
                      </li>
                    );
                  })}
              </ul>
            ) : (
              <NoTableDataAvailable />
            )}
          </Fragment>
        </div>
      </div>
    </div>
  );
};
export default InvestmentInterestGraph;
