import React, { Fragment, useState } from "react";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import { useForm } from "react-hook-form";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useSecurityQuestions from "../../custom-hooks/useSecurityQuestions";
import Loader from "../Loader/Loader.component";
import PageLoader from "../PageLoader/PageLoader.component";
import { errorHandler } from "../../helpers/errorHandler";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  question_id: yup.string().required("Please pick a question"),
  answer: yup
    .string()
    .required("Please enter security  answer")
    .min(3, "Answer must be at least 3 characters")
    .max(64, "Answer cannot have more than 64 characters"),
});
const SecurityQuestionModalComponent = ({ setSection }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { question_id, answer } = data;
    const reqBody = {
      question_id: Number(question_id),
      answer,
    };
    setLoading(true);
    setError(false);
    try {
      await postData(apiEndpoints.SAVE_SECURITY_QUESTION, reqBody);
      setLoading(false);
      setSection("2");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "SecurityQuestionModalComponent.jsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };
  const { data, isLoading } = useSecurityQuestions();

  return (
    <Fragment>
      {error && <p>{error}</p>}
      {isLoading ? (
        <div className="p-5">
          <PageLoader />
        </div>
      ) : (
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Security Questions</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <p>
                  Kindly set a security question before creating your
                  transaction PIN
                </p>
                <div className="row mt-4">
                  <div className="col-12">
                    <CustomSelectDropdown
                      reference={register}
                      label="Security Question"
                      name="question_id"
                      errors={errors.question_id}
                    >
                      <option value="">Select</option>
                      {data?.map(({ id, question }) => {
                        return (
                          <option value={id} key={id}>
                            {question}
                          </option>
                        );
                      })}
                    </CustomSelectDropdown>
                  </div>
                  <div className="col-12">
                    <CustomInputField
                      type="text"
                      name="answer"
                      reference={register}
                      maxLength={64}
                      label="Security Question Answer"
                      errors={errors.answer}
                    />
                  </div>
                </div>
                <div className="col-12 pb-4 pt-4">
                  <button
                    className="btn btn-sm advancly-btn  w-100"
                    disabled={loading}
                  >
                    Save and Continue {loading && <Loader />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SecurityQuestionModalComponent;
