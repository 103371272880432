import { Fragment, useRef, useState, useEffect } from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FileUploadIcon from "../../../svg/icons/file-upload-fill.svg";
import readXlsxFile from "read-excel-file";
import { errorHandler } from "../../../helpers/errorHandler";
import { postDataWithDotNet } from "../../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import useBusinessSectors from "../../../custom-hooks/useBusinessSectors";
import PageLoader from "../../PageLoader/PageLoader.component";
import useAggregatorProducts from "../../../custom-hooks/useAggregatorProducts";
import SampleExcelSheet from "../../../files/Sample Batch Borrowers Upload Sheet.xlsx";
import MultipleLoanRequestSuccessModalComponent from "../../MultipleLoanRequestSuccessModal/MultipleLoanRequestSuccessModal.component";
import { Link, withRouter } from "react-router-dom";
import MultipleLoanPreviewModal from "./MultipleLoanPreviewModal.component";
import useCountryBanksList from "../../../custom-hooks/useCountryBanksList";
import useAggregatorStates from "./../../../custom-hooks/useAggregatorStates";
import MultipleLoanFieldsViewModal from "./MultipleLoanFieldsViewModal.component";
import moment from "moment";
import { useAuth } from "../../../Context/auth.context";
import BackButtonComponent from "../../BackButton/BackButton.component";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import { loanRequestSchema } from "../../../helpers/loanRequestSchema";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import CustomTextArea from "../../CustomHTMLElements/CustomTextArea";

import { appInsights } from "../../AppInsight/AppInsight";

import styles from "./MultipleLoanRequest.module.scss";
import useAdvanclyCurrentCountries from "../../../custom-hooks/useAdvanclyCurrentCountries";
import LoaderColored from "../../Loader/LoaderColored";

const yupSchema = yup.object().shape({
  productId: loanRequestSchema.productId,
  loanTenure: loanRequestSchema.loanTenure,
  borrowerSector: loanRequestSchema.borrowerSector,
  interestRate: loanRequestSchema.interestRate,
  loanPurpose: loanRequestSchema.loanPurpose,
  country: loanRequestSchema.country,
});

const MultipleLoanRequest = (props) => {
  const { aggregatorDetails } = useAuth();
  // const { scrollToTop } = props;
  const [productId, setProductId] = useState(null);
  const [loanTenure, setLoanTenure] = useState(null);
  const [loanTenureMax, setLoanTenureMax] = useState(null);
  const [borrowersToCreate, setBorrowersToCreate] = useState(null);
  const [genericLoanParameters, setGenericLoanParameters] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileUploadError, setFileUploadError] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [batchNumber, setBatchNumber] = useState(false);
  const [dataToView, setDataToView] = useState({
    data: null,
    type: "",
  });
  const [countrycode, setCountryCode] = useState(null);

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(yupSchema),
  });

  const allowBulkUpload = aggregatorDetails?.allow_bulk_loan_application_via_ui;

  const schema = {
    "Customer BVN": {
      prop: "customerBVN",
      type: String,
    },
    "Loan Amount": {
      prop: "loanAmount",
      type: Number,
      // type: String,
      required: true,
    },
    "First Name": {
      prop: "firstName",
      type: String,
    },
    "Last Name": {
      prop: "lastName",
      type: String,
    },
    Address: {
      prop: "address",
      type: String,
      required: true,
    },
    "Email Address": {
      prop: "emailAddress",
      type: String,
      required: true,
    },
    "RC Number": {
      prop: "rcNumber",
      type: String,
    },
    "Company Name": {
      prop: "companyName",
      type: String,
    },
    "Date of Birth": {
      prop: "dateofBirth",
      type: String,
      required: true,
    },
    "Repayment Start Date": {
      prop: "repaymentStartDate",
      type: String,
      required: true,
    },
    "Tenure in Days": {
      prop: "tenureInDays",
      type: Number,
      required: true,
    },
    // "Annual Interest Rate": {
    //   prop: "annualInterestRate",
    //   type: Number,
    //   required: true,
    // },
    // "Product ID": {
    //   prop: "productID",
    //   type: Number,
    //   required: true,
    // },
    "Borrower Type": {
      prop: "borrowerType",
      type: Number,
      required: true,
      oneOf: [1, 2],
    },
    "Borrower Gender": {
      prop: "gender",
      type: String,
    },
    State: {
      prop: "state",
      type: String,
      required: true,
    },
    City: {
      prop: "city",
      type: String,
      required: true,
    },
    "Phone Number": {
      prop: "phoneNumber",
      type: String,
      required: true,
    },
    "Bank Code": {
      prop: "bankCode",
      type: String,
      required: true,
    },
    "Bank Account Number": {
      prop: "bankAccountNumber",
      type: String,
      required: true,
    },
    "Photo Url": {
      prop: "photoUrl",
      type: String,
    },
    "Reference Number": {
      prop: "aggregator_loan_ref",
      type: String,
    },
    // "Sector ID": {
    //   prop: "sectorCode",
    //   type: String,
    //   required: true,
    // },
    "Country Code": {
      prop: "countryCode",
      type: String,
      required: true,
    },
  };

  const fileInput = useRef();
  const loanSuccessModal = useRef();
  const dismissPreviewModal = useRef();

  const handleReviewUpload = (data) => {
    if (!allowBulkUpload) {
      window.scrollTo(0, 0);
      return setError("Bulk upload not enabled for this aggregator");
    }
    reviewFileToBeUploaded();
    setGenericLoanParameters({
      productId: Number(data?.productId),
      interestRate: Number(data?.interestRate),
      loanTenureInDays: Number(data?.loanTenure),
      sectorCode: data?.borrowerSector,
      loanPurpose: data?.loanPurpose,
    });
    const reviewButton = document.getElementById("review-upload");
    reviewButton.dataset.toggle = "modal";
    reviewButton.dataset.target = "#multipleLoanPreviewModal";
    reviewButton.click();
  };

  const validateFileToUpload = (file) => {
    setError(null);
    if (file && file.length) {
      if (file[0].name.endsWith(".xls") || file[0].name.endsWith(".xlsx")) {
        setFileToUpload(file);
      } else {
        setError("Invalid file type");
        setFileToUpload(null);
      }
    }
  };

  const reviewFileToBeUploaded = (e) => {
    // Check if aggregatot has the permission for bulk upload turned on
    if (!allowBulkUpload) {
      return "Permission not granted ";
    }

    // CHeck if a file has been selected
    if (!fileToUpload) {
      setError("Please choose a file");
      setFileToUpload(null);
    }

    // Upload only one item. This is in a case where the aggregator uploaded more than one file
    const file = fileToUpload[0];

    // Read the uploaded file and do some data manipulations on them
    readXlsxFile(file, { schema }).then(({ rows, errors }) => {
      // Slice the length of the array to maximum of 5000 amd initializa each row with an error object
      let fileData = rows.slice(0, 5000).map((row) => ({
        ...row,
        rowStatus: [],
      }));
      // Check if there is an error in the excel sheet that was uploaded, update each row of data with the error information
      if (errors && errors.length) {
        fileData = fileData.map((row, _id) => {
          const id = _id + 1;

          // Check if each row has en error by checking the error array returned by the "read-excel-file" Library
          const errorInfo = errors.find((errorRow) => errorRow.row === id);
          return {
            ...row,
            rowStatus: [
              ...row.rowStatus,

              // If there is an error in the row, update the errorInfo object in each row with the error information
              errorInfo ? `${errorInfo.column}: ${errorInfo.error}` : null,
            ],
          };
        });
      }

      // Check if borrower type entered is either 1 or 2. 1 is Individual Borrower, 2 is Corporate Borrower
      // If neither of 1 or 2 is entered, update the errorInfo object in each row with the error information
      fileData = fileData
        .map((row) => {
          const rowError =
            Number(row.borrowerType) === 1 || Number(row.borrowerType) === 2
              ? null
              : "Invalid borrower type";
          return {
            ...row,
            rowStatus: [...row.rowStatus, rowError ? rowError : null],
          };
        })

        // Format Date Objects to DD/MM/YY format
        .map((row) => {
          return {
            ...row,
            repaymentStartDate: row.repaymentStartDate
              ? moment(row.repaymentStartDate, "DD/MM/YYYY").format()
              : null,
            dateofBirth: row.dateofBirth
              ? moment(row.dateofBirth, "DD/MM/YYYY").format()
              : null,
          };
        })

        // Check if repaymentStart and dateOfBirth are valid date objects.
        // If not valid, update the errorInfo object in each row with the error information
        .map((row) => {
          const rowError = row.repaymentStartDate
            .toLowerCase()
            .startsWith("invalid")
            ? "Invalid Repayment Start Date. Expected a DD-MM-YYYY format"
            : null;

          return {
            ...row,
            rowStatus: [...row.rowStatus, rowError ? rowError : null],
          };
        })
        .map((row) => {
          const rowError = row.dateofBirth.toLowerCase().startsWith("invalid")
            ? "Invalid Date of Birth. Expected a DD-MM-YYYY format"
            : null;
          return {
            ...row,
            rowStatus: [...row.rowStatus, rowError ? rowError : null],
          };
        })

        // Check if tenure entered is at least 1.
        // If neither of 1 or 2 is entered, update the errorInfo object in each row with the error information
        .map((row) => {
          const rowError =
            Number(row.tenureInDays) >= 1 ? null : "Tenure must at least be 1";
          return {
            ...row,
            rowStatus: [...row.rowStatus, rowError ? rowError : null],
          };
        });

      const { aggregator_id: aggregatorId } = aggregatorDetails;
      fileData = fileData
        .map((file) => {
          return {
            ...file,
            aggregatorId,
            customerBVN: file.customerBVN ? file.customerBVN.toString() : null,
            sectorCode: file.sectorCode ? file.sectorCode.toString() : null,
            bankAccountNumber: file.bankAccountNumber
              ? file.bankAccountNumber.toString()
              : null,
            phoneNumber: file.phoneNumber ? file.phoneNumber.toString() : null,
            rcNumber: file.rcNumber ? file.rcNumber.toString() : null,
            countryCode: file.countryCode ? file.countryCode.toString() : null,
            // loanAmount: file.loanAmount
            //   ? parseFloat(file.loanAmount.replace(/,/g, ""))
            //   : null,
          };
        })
        .map((file) => {
          // Modifying the request body based on the borrower type.
          if (Number(file.borrowerType) === 1) {
            return { ...file, rcNumber: "", companyName: "" };
          } else if (Number(file.borrowerType) === 2) {
            return { ...file };
          }
          return null;
        })

        // Check the errorInfo object of each row to filter out every null value in the array
        .map((file) => {
          return {
            ...file,
            rowStatus:
              file.rowStatus && file.rowStatus.length
                ? file.rowStatus.filter((status) => status !== null)
                : file.rowStatus,
          };
        });

      setBorrowersToCreate(fileData);
    });
  };

  const resetAllFields = () => {
    setFileToUpload(null);
    setBorrowersToCreate(null);
    document.getElementById("batch-upload-input").value = "";
    setFileUploadError(null);
    setSuccess(null);
    // reset();
  };

  const submitBorrowers = async () => {
    if (!allowBulkUpload) {
      return "Permission not granted";
    }
    setLoading(true);
    setError(null);
    try {
      const data = await postDataWithDotNet(apiEndpoints.batchLoanCreation, {
        ...genericLoanParameters,
        batchLoanRequest: borrowersToCreate,
      });
      if (data.error && data.error.length) {
        setFileUploadError(data.error);
      }
      if (data.statusMessages) {
        setBatchNumber(data.statusMessages.split(":")[1].trim());
      }
      setLoading(false);

      dismissPreviewModal.current.click();
      setTimeout(() => {
        loanSuccessModal.current.click();
        setSuccess(
          "The uploaded file has gone for approval and is being processed."
        );
      });
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "MultipleLoanRequest.jsx" },
      });
      if (error.response && error.response.data.error) {
        setFileUploadError(error.response.data.error);
      } else {
        setError(errorHandler(error));
      }
      setLoading(false);
    }
  };

  const productHandlerId = (e) => {
    setProductId(e.target.value);
  };

  const redirectToLoanStatusPage = () => {
    props.history.push({
      pathname: "/batch-loan-request-status",
      state: batchNumber,
    });
  };

  const {
    data: bankListData,
    status: bankStatus,
    error: bankListError,
  } = useCountryBanksList({ country_code: aggregatorDetails?.country_code });

  const {
    data: statesData,
    status: statesStatus,
    error: statesError,
  } = useAggregatorStates({
    country_code: aggregatorDetails?.country_code,
    aggregator_id: aggregatorDetails?.aggregator_id,
  });

  const {
    data: sectorsData,
    status: sectorsStatus,
    error: sectorsError,
  } = useBusinessSectors();
  const {
    data: countries,
    isLoading: isLoadingCountries,
    error: countriesError,
  } = useAdvanclyCurrentCountries();
  const countryHandler = (e) => setCountryCode(e.target.value);

  const {
    data: productsData,
    status: productsStatus,
    error: productsError,
  } = useAggregatorProducts({ country_code: countrycode });

  useEffect(() => {
    if (productId) {
      const product = productsData.find(
        (eachProduct) => Number(eachProduct.id) === Number(productId)
      );

      setValue("loanTenure", product?.maximum_tenor);
      setLoanTenureMax(product?.maximum_tenor);
      setValue("interestRate", product?.interest_rate);
    }
  }, [productId, productsData, setValue]);

  if (
    sectorsStatus === "loading" ||
    statesStatus === "loading" ||
    bankStatus === "loading"
  )
    return <PageLoader />;

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Loan Request
        </h3>
      </div>
      <p className="text-left">View status of recent batch requests</p>
      <button
        className="d-none"
        type="button"
        data-toggle="modal"
        data-target="#multipleLoanSuccessModal"
        ref={loanSuccessModal}
      ></button>
      {!allowBulkUpload && (
        <h6 className="text-danger font-weight-bold text-center my-5">
          Please note that you do not have access to this feature as you have
          disabled it in your settings.
        </h6>
      )}
      {error && (
        <h5 className="text-danger font-weight-bold text-center my-5">
          {error}
        </h5>
      )}
      {sectorsError && (
        <p className="alert alert-danger small">{errorHandler(sectorsError)}</p>
      )}
      {countriesError && (
        <p className="alert alert-danger small">
          {errorHandler(countriesError)}
        </p>
      )}
      {bankListError && (
        <p className="alert alert-danger small">
          {errorHandler(bankListError)}
        </p>
      )}
      {productsError && (
        <p className="alert alert-danger small">
          {errorHandler(productsError)}
        </p>
      )}
      {statesError && (
        <p className="alert alert-danger small">{errorHandler(statesError)}</p>
      )}
      <input
        type="file"
        className="d-none"
        ref={fileInput}
        id="batch-upload-input"
        accept=".xlsx, .xls" // Accept Excel files only
        onChange={(e) => {
          validateFileToUpload(e.target.files);
        }}
      />
      <div className="mt-5">
        <Fragment>
          <form onSubmit={handleSubmit(handleReviewUpload)}>
            <div className={`row gx-5 ${styles.multipleUploadCard}`}>
              <div className="col-6">
                <CustomSelectDropdown
                  reference={register}
                  label="Country"
                  name="country"
                  errors={errors.country}
                  showRequiredIcon={true}
                  extraLabel={isLoadingCountries ? "(Loading...)" : null}
                  onChange={countryHandler}
                >
                  <option value="">Country</option>
                  {countries?.map(({ name, id, iso2 }) => {
                    return (
                      <option value={iso2} key={id}>
                        {name}
                      </option>
                    );
                  })}
                </CustomSelectDropdown>
                <CustomSelectDropdown
                  customSelectStyle={{ height: "3rem" }}
                  reference={register}
                  label="Loan Product"
                  name="productId"
                  errors={errors.productId}
                  showRequiredIcon={true}
                  onChange={productHandlerId}
                >
                  <option value="">Select Product</option>
                  {productsData &&
                    productsData.length &&
                    productsData.map((product) => (
                      <option key={product.id} value={product.id}>
                        {product.product_name}
                      </option>
                    ))}
                </CustomSelectDropdown>
                {productsStatus === "loading" ? (
                  <div className=" d-flex justify-content-end">
                    {" "}
                    <LoaderColored />{" "}
                  </div>
                ) : (
                  ""
                )}
                <CustomInputField
                  customInputStyle={{ height: "3rem" }}
                  type="number"
                  maxLength="5"
                  step="any"
                  reference={register}
                  label="Annual Interest Rate"
                  name="interestRate"
                  placeholder="Enter Annual Interest Rate"
                  errors={errors.interestRate}
                  extraLabel="(in %)"
                  showRequiredIcon={true}
                  readOnly={productId}
                />

                <CustomInputField
                  type="number"
                  maxLength="3"
                  reference={register}
                  label="Loan Tenure"
                  name="loanTenure"
                  min="10"
                  max={loanTenureMax}
                  placeholder="Enter Loan Tenure"
                  errors={errors.loanTenure}
                  extraLabel="(in days)"
                  showRequiredIcon={true}
                  defaultValue={loanTenure}
                  onChange={(e) => setLoanTenure(e.target.value)}
                  readOnly={!productId}
                />

                <CustomSelectDropdown
                  customSelectStyle={{ height: "3rem" }}
                  reference={register}
                  label="Sector"
                  name="borrowerSector"
                  errors={errors.borrowerSector}
                  showRequiredIcon={true}
                >
                  <option value="">Select Borrower Sector</option>
                  {sectorsData &&
                    sectorsData.length &&
                    sectorsData.map((sector) => {
                      const { category_id, code, category_name } = sector;
                      return (
                        <option key={category_id} value={code}>
                          {category_name}
                        </option>
                      );
                    })}
                </CustomSelectDropdown>

                <CustomTextArea
                  customAreaStyle={{ borderRadius: "5px", height: "8rem" }}
                  maxLength="128"
                  reference={register}
                  placeholder="Loan Purpose"
                  label="Purpose for loan"
                  name="loanPurpose"
                  errors={errors.loanPurpose}
                />
              </div>
              <div className="col-6">
                <div
                  className={`color-blue font-weight-bold d-flex justify-content-start pb-5 ${styles.multipleUploadCardText}`}
                >
                  Upload Excel File
                </div>

                <div className={styles.multipleUploadSubCard}>
                  <div
                    className={styles.uploadBoxIconBorder}
                    onDrop={(e) => {
                      e.preventDefault();
                      validateFileToUpload(e.dataTransfer.files);
                    }}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <img src={FileUploadIcon} alt="Upload File" />
                    <h4>Upload File</h4>
                    {!fileToUpload ? (
                      <Fragment>
                        <div>
                          <span className={styles.actionText}>
                            Drag & drop your file here or{" "}
                          </span>
                          <span
                            className="color-light-blue"
                            onClick={() => fileInput.current.click()}
                          >
                            browse
                          </span>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="color-blue font-weight-bold">
                          {fileToUpload[0].name}
                        </div>
                        <div>
                          {(parseFloat(fileToUpload[0].size) / 1024).toFixed(2)}
                          kb
                        </div>
                        <div
                          className="color-light-blue"
                          onClick={resetAllFields}
                        >
                          <u>Remove</u>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
                <hr className="w-100" />

                <div className="w-100">
                  <div>
                    Don’t have the recommended template? <br />
                    <a
                      className={styles.downloadButton}
                      href={SampleExcelSheet}
                      download="Sample Batch Borrowers Upload Sheet.xlsx"
                      title="Download Bulk Upload Template"
                    >
                      Download here
                    </a>
                  </div>
                </div>
                <div className={`${styles.reviewButtonUpload} `}>
                  <button
                    disabled={!fileToUpload}
                    type="submit"
                    id="review-upload"
                    className={`btn btn-sm transition-3d-hover w-100 mt-2 mb-3 ${
                      fileToUpload ? "advancly-btn" : "advancly-off-btn"
                    }`}
                  >
                    Review Upload
                  </button>
                </div>

                <Link
                  className="color-mid-blue mt-5 mb-3"
                  to="/batch-loan-request-status"
                >
                  <u>View Status of recent Batch Requests</u>
                </Link>
              </div>
            </div>
          </form>

          <div className="mt-5">
            <p className="text-center">
              Guides to follow while preparing excel sheet
            </p>
            <ol className={styles.uploadInstructions}>
              <li>
                Excel sheet must follow thesame pattern as that of the sample
                excel sheet
              </li>
              <li>Excel sheet must not contain more than 500 records</li>
              <li>Loan Tenure must be at least 1 day.</li>
              <li>
                Borrower type must be either of 1 or 2. 1 is for individual
                borrowers, 2 is for corporate borrowers.
              </li>
              <li>
                Sector ID, phone number, state, city, product ID, loan amount
                and loan tenure are required for both corporate and individuals
                borrowers.
              </li>
              <li>
                First name, last name, gender and BVN are required for
                individuals but not for corporate borrowers
              </li>
              <li>
                Company name and RC number are required for corporate borrowers
                but not for individuals
              </li>
              <li>
                Photo URL is not required for either of corporate borrowers or
                individuals
              </li>
              {sectorsData && sectorsData.length && (
                <li>
                  <span
                    data-toggle="modal"
                    data-target="#multipleLoanFieldsViewModal"
                    className={styles.downloadButton}
                    onClick={() =>
                      setDataToView({ data: sectorsData, type: "SECTORS" })
                    }
                  >
                    Click here
                  </span>{" "}
                  to view a list of all sectors available to you
                </li>
              )}
              {productsData && productsData.length && (
                <li>
                  <span
                    data-toggle="modal"
                    data-target="#multipleLoanFieldsViewModal"
                    className={styles.downloadButton}
                    onClick={() =>
                      setDataToView({ data: productsData, type: "PRODUCTS" })
                    }
                  >
                    Click here
                  </span>{" "}
                  to view a list of all products available to you
                </li>
              )}
              {bankListData && bankListData.length && (
                <li>
                  <span
                    data-toggle="modal"
                    data-target="#multipleLoanFieldsViewModal"
                    className={styles.downloadButton}
                    onClick={() =>
                      setDataToView({ data: bankListData, type: "BANKS" })
                    }
                  >
                    Click here
                  </span>{" "}
                  to view a list of all banks available to you
                </li>
              )}
              {statesData && statesData.length && (
                <li>
                  <span
                    data-toggle="modal"
                    data-target="#multipleLoanFieldsViewModal"
                    className={styles.downloadButton}
                    onClick={() =>
                      setDataToView({ data: statesData, type: "STATES" })
                    }
                  >
                    Click here
                  </span>{" "}
                  to view a list of all states available to you
                </li>
              )}
              <li>
                Note that any information that doesn't meet the above-listed
                instructions may cause an error
              </li>
            </ol>
          </div>
        </Fragment>
      </div>

      <MultipleLoanRequestSuccessModalComponent
        success={success}
        setSuccess={setSuccess}
        redirectToLoanStatusPage={redirectToLoanStatusPage}
        fileUploadError={fileUploadError}
      />
      <MultipleLoanPreviewModal
        borrowersToCreate={borrowersToCreate}
        resetAllFields={resetAllFields}
        success={success}
        fileUploadError={fileUploadError}
        loading={loading}
        submitBorrowers={submitBorrowers}
        redirectToLoanStatusPage={redirectToLoanStatusPage}
        dismissPreviewModal={dismissPreviewModal}
      />
      <MultipleLoanFieldsViewModal dataToView={dataToView} />
    </div>
  );
};
export default withRouter(MultipleLoanRequest);
