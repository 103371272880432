import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getOfferLetterAndSlaHistory = async ({id}) => {
 const data = await getDataWithDotNet(
    `${apiEndpoints.OfferLetterAndSlaHistory}?aggregatorId=${id}`,
    true
  );
  return data.data;
};

export default function useOfferLetterAndSlaHistory({ id, viewHistory }) {
  return useQuery(
    [{id}, "getOfferLetterAndSlaHistory"],
    getOfferLetterAndSlaHistory,
    {
      enabled:viewHistory?true:false,
      refetchOnWindowFocus: false,
    }
  );
}
