import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getRecentRepayments = async ({
  aggregatorId,
  currentCurrencyValue,
  activeProductName,
}) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.getRecentRepayments}?aggregator_id=${aggregatorId}&currency=${currentCurrencyValue}&product=${activeProductName}`
  );
  return data.data;
};

export default function useRecentRepayments({
  aggregatorId,
  currentCurrencyValue,
  activeProductName,
}) {
  return useQuery(
    [
      { aggregatorId, currentCurrencyValue, activeProductName },
      "getRecentRepayments",
    ],
    getRecentRepayments,
    {
      enabled: currentCurrencyValue && activeProductName ? true : false,

      refetchOnWindowFocus: false,
    }
  );
}
