import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postDataWithoutToken } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { yupValidators } from "../../helpers/yupValidators";
import LoaderSignUp from "../LoaderSignUp/LoaderSignUp";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  password1: yupValidators.newPassword,
  password2: yupValidators.confirmPassword,
});

const StepThree = ({ stateToken }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ password1, password2 }) => {
    setError(false);
    setSuccess(false);

    if (password1 !== password2) {
      setLoading(false);
      setError("The passwords you entered don't match. Please re-type");
      return;
    }

    setLoading(true);
    const reqBody = {
      reset_step: "third_step",
      password1,
      password2,
      state_token: stateToken,
    };

    try {
      await postDataWithoutToken(apiEndpoints.resetPasswordUrl, reqBody);
      setLoading(false);
      setSuccess(
        "Your password has been reset successfully. Please log in with your new password."
      );
      setTimeout(() => {
        history.push("/login");
      }, 5000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "StepThree.component.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  return (
    <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-7">
        <h3 className="text-dark font-weight-bold text-capitalize mb-3">
          Set New Password
        </h3>
        <p className="text-dark">
          Set a new password. Make it personal but not too personal.
        </p>
      </div>
      {error && <p className="alert alert-danger small mb-4">{error}</p>}
      {success && <p className="alert alert-success small mb-4">{success}</p>}

      <CustomInputField
        type="password"
        maxLength="128"
        reference={register}
        name="password1"
        placeholder="New password"
        label="New password"
        errors={errors.password1}
        hasActionButton={true}
      />
      <CustomInputField
        type="password"
        maxLength="128"
        reference={register}
        name="password2"
        placeholder="Confirm password"
        label="Confirm password"
        errors={errors.password2}
        hasActionButton={true}
      />
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          value="submit"
          className="btn advancly-btn btn-sm transition-3d-hover"
          disabled={loading}
        >
          Reset password
          {loading && <LoaderSignUp />}
        </button>
      </div>
    </form>
  );
};
export default StepThree;
