import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getAllWallets = async ({
  pageNumber,
  size,
  startDate,
  endDate,
  consumerType,
  targetId,
}) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;

  if (startDate) {
    queryParams = `${queryParams}&StartDate=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&EndDate=${endDate}`;
  }
  if (consumerType) {
    queryParams = `${queryParams}&ConsumerType=${consumerType}`;
  }
  if (targetId) {
    queryParams = `${queryParams}&TargetId=${targetId}`;
  }
  const data = await getData(`${apiEndpoints.getAllWallet}?${queryParams}`);

  return data;
};

export default function useAllWallets({
  pageNumber,
  size,
  startDate,
  endDate,
  consumerType,
  targetId,
}) {
  return useQuery(
    [
      { pageNumber, size, startDate, endDate, consumerType, targetId },
      "getAllWalletAllWallets",
    ],
    getAllWallets
  );
}
