import React, { Fragment, useRef, useState } from "react";
import styles from "./Sidebar.module.scss";
import { ReactComponent as CloseIcon } from "../../svg/icons/Close_icon.svg";
import { ReactComponent as AdvestRedirectIcon } from "../../svg/icons/AdvestRedirectIcon.svg";
import { ReactComponent as CaretIcon } from "../../svg/icons/Caret_icon_big.svg";
import { ReactComponent as SparkFullLogo } from "../../img/SparkCompleteLogo.svg";
import { ReactComponent as EmbedFullLogo } from "../../img/EmbedCompleteLogo.svg";
import { ReactComponent as AdvestFullLogo } from "../../img/AdvestCompleteLogo.svg";
import { useForm } from "react-hook-form";
import { useQueryCache } from "react-query";
import Loader from "../Loader/Loader.component";
import { errorHandler } from "../../helpers/errorHandler";
import { useAuth } from "../../Context/auth.context";
import Alert from "../Alert/Alert.component";
import useProductTypeRemaining from "../../custom-hooks/useProductTypeRemaining";
import { putDataWithDotNet } from "../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { useHistory } from "react-router-dom";
import ROUTES from "../../helpers/ROUTES";
import LoaderColored from "../Loader/LoaderColored";
import { setLoginInfo } from "../../helpers/setLoginInfo";
import { appInsights } from "../AppInsight/AppInsight";
const ProductListModal = () => {
  const [productListloading, setProductListLoading] = useState(false);
  const [productListError, setProductListError] = useState();
  const [productsuccess, setProductSuccess] = useState("");
  const [productListResponseMessage, setProductListResponseMessage] =
    useState("");
  const queryCache = useQueryCache();

  const [openItem, setOpenItem] = useState(false);
  const [openItem2, setOpenItem2] = useState(false);
  const { aggregatorDetails, setEligibilityData } = useAuth();
  const { register, handleSubmit, reset, errors } = useForm();
  const history = useHistory();
  const dismissRef = useRef();

  const itemDisplayHandler = () => {
    setOpenItem(!openItem);
  };
  const itemDisplayHandler2 = () => {
    setOpenItem2(!openItem2);
  };
  const {
    data: productTypeRemainingData,
    isLoading: productTypeRemainingLoading,
    error: productTypeRemainingError,
  } = useProductTypeRemaining();
  const onsubmitHandler = async ({ embedNew, sparkNew }) => {
    setProductListError(null);
    // setProductListLoading(true);
    setProductSuccess(false);
    let reqBody = {
      product_type_ids: [
        ...(embedNew ? embedNew : ""),
        ...(sparkNew ? sparkNew : ""),
      ].map((intNumber) => {
        return Number(intNumber);
      }),
    };
    try {
      const res = await putDataWithDotNet(
        apiEndpoints.AGGREGATOR_SUBSEQUENT_PRODUCT_ONBOARDING,
        reqBody
      );
      setProductListLoading(false);
      if (res.status_code === 200) {
        setProductSuccess(true);
        if (dismissRef && dismissRef.current) {
          dismissRef.current.click();
        }

        queryCache.invalidateQueries("getAggregatorDetails");
        queryCache.invalidateQueries("getProductTypeRemaining");
        queryCache.invalidateQueries("getEligibilityStatus");
        //updates eligibility status on local storage

        const { eligibilityResult } = await setLoginInfo();

        setEligibilityData(eligibilityResult);
        setProductListResponseMessage(res.message);
        history.push(ROUTES.GET_STARTED);
        setTimeout(() => {
          reset();
        }, 3000);
      } else {
        setProductSuccess(false);
        setProductListError(res.message);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "ProductListModal.jsx" },
      });
      setProductSuccess(false);
      setProductListLoading(false);
      setProductListError(errorHandler(error));
      setTimeout(() => {
        reset();
        setProductListError();
      }, 5000);
    }
  };

  return (
    <div>
      {productsuccess && (
        <Alert message={productListResponseMessage} type="success" />
      )}
      <div
        className={`modal fade ${styles.mainModal}   `}
        id="aggregatorproductModal"
        tabIndex="-1"
        aria-labelledby="aggregatorproductModal"
        aria-hidden="true"
        role="dialog"
      >
        <div
          className={`modal-dialog  modal-dialog-scrollable   ${styles.sidebarModalDialog}       
  `}
        >
          <div
            className={`modal-content  modal-dialog-scrollable    ${styles.sidebarModalContent} `}
          >
            {productListError && (
              <p className="alert alert-danger  small mb-4">
                {productListError}
              </p>
            )}
            <small className="text-danger">{errors.productType?.message}</small>

            {productTypeRemainingError && (
              <p className="alert alert-danger  mb-4">
                {errorHandler(productTypeRemainingError)}
              </p>
            )}
            <div className={`modal-header border-0 ${styles.modalHeader}`}>
              <h4 className="modal-title">
                {aggregatorDetails &&
                aggregatorDetails?.product_types?.length > 3
                  ? "Available Product"
                  : "Select Product"}
              </h4>
              <button
                type="button"
                className={`close ${styles.closeModal}`}
                data-dismiss="modal"
                aria-label="Close"
                ref={dismissRef}
                onClick={() => {
                  reset();
                  setProductListError();
                }}
              >
                <CloseIcon />
              </button>
            </div>

            <form onSubmit={handleSubmit(onsubmitHandler)}>
              <div className={`modal-body  ${styles.modalMainBody}`}>
                {productListloading ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <LoaderColored />
                  </div>
                ) : (
                  <Fragment>
                    {/* SPARK ITEMS */}
                    <div className={styles.product_card_main}>
                      <div
                        className={styles.product_card_header}
                        onClick={itemDisplayHandler}
                      >
                        <SparkFullLogo />
                        <CaretIcon />
                      </div>
                      {openItem && (
                        <div className="ml-4 mt-3">
                          {productTypeRemainingLoading && (
                            <div>
                              <Loader />
                            </div>
                          )}
                          {/* ACTIVE  SPARK PRODUCT  */}
                          {!productTypeRemainingLoading &&
                            !productTypeRemainingError && (
                              <Fragment>
                                {aggregatorDetails &&
                                  aggregatorDetails?.product_types
                                    .filter(({ product }) => {
                                      return product === "Spark";
                                    })
                                    .map((sparkData) => {
                                      const { id, name } = sparkData;
                                      return (
                                        <div key={id}>
                                          <label
                                            className={
                                              styles.containerCheckboxes
                                            }
                                          >
                                            {name}
                                            <input
                                              type="checkbox"
                                              checked="checked"
                                              disabled
                                            />
                                            <span
                                              className={styles.checkmark}
                                            ></span>
                                          </label>
                                        </div>
                                      );
                                    })}
                              </Fragment>
                            )}
                          {/* SPARK PRODUCT YET TO BE ACTIVE */}
                          {!productTypeRemainingLoading &&
                            !productTypeRemainingError && (
                              <Fragment>
                                {productTypeRemainingData &&
                                  productTypeRemainingData
                                    .filter(({ product }) => {
                                      return product === "Spark";
                                    })
                                    .map((sparkDataRemaining) => {
                                      const { id, name } = sparkDataRemaining;
                                      return (
                                        <div key={id}>
                                          <label
                                            className={
                                              styles.containerCheckboxes
                                            }
                                          >
                                            {name}
                                            <input
                                              type="checkbox"
                                              name="sparkNew"
                                              ref={register}
                                              value={id}
                                            />
                                            <span
                                              className={styles.checkmark}
                                            ></span>
                                          </label>
                                        </div>
                                      );
                                    })}
                              </Fragment>
                            )}
                        </div>
                      )}
                    </div>
                    {/* EMBED ITEMS  */}
                    <div className={styles.product_card_main}>
                      <div
                        className={styles.product_card_header}
                        onClick={itemDisplayHandler2}
                      >
                        <EmbedFullLogo />
                        <CaretIcon />
                      </div>
                      {openItem2 && (
                        <div className="ml-4 mt-3">
                          {productTypeRemainingLoading && (
                            <div>
                              <Loader />
                            </div>
                          )}
                          {/* ACTIVE EMBED PRODUCT  */}
                          {!productTypeRemainingLoading &&
                            !productTypeRemainingError && (
                              <Fragment>
                                {aggregatorDetails &&
                                  aggregatorDetails?.product_types
                                    .filter(({ product }) => {
                                      return product === "Embed";
                                    })
                                    .map((embedData) => {
                                      const { id, name } = embedData;
                                      return (
                                        <div key={id}>
                                          <label
                                            className={
                                              styles.containerCheckboxes
                                            }
                                          >
                                            {name}
                                            <input
                                              type="checkbox"
                                              checked="checked"
                                              disabled
                                            />
                                            <span
                                              className={styles.checkmark}
                                            ></span>
                                          </label>
                                        </div>
                                      );
                                    })}
                              </Fragment>
                            )}
                          {/* EMBED PRODUCT YET TO BE ACTIVE */}
                          {!productTypeRemainingLoading &&
                            !productTypeRemainingError && (
                              <Fragment>
                                {productTypeRemainingData &&
                                  productTypeRemainingData
                                    .filter(({ product }) => {
                                      return product === "Embed";
                                    })
                                    .map((embedDataRemaining) => {
                                      const { id, name } = embedDataRemaining;
                                      return (
                                        <div key={id}>
                                          <label
                                            className={
                                              styles.containerCheckboxes
                                            }
                                          >
                                            {name}
                                            <input
                                              name="embedNew"
                                              ref={register({
                                                valueAsNumber: true,
                                              })}
                                              type="checkbox"
                                              value={id}
                                            />
                                            <span
                                              className={styles.checkmark}
                                            ></span>
                                          </label>
                                        </div>
                                      );
                                    })}
                              </Fragment>
                            )}
                        </div>
                      )}
                    </div>
                    {/* Advest */}
                    <a
                      href="https://advest.advancly.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={styles.product_card_main}>
                        <div className={styles.product_card_header}>
                          <AdvestFullLogo />
                          <AdvestRedirectIcon />{" "}
                        </div>
                      </div>
                    </a>
                  </Fragment>
                )}
              </div>
              <div className={`modal-footer border-0 ${styles.modalFooter}`}>
                <button
                  type="submit"
                  className="btn advancly-btn btn-sm transition-3d-hover w-100 mt-2"
                  disabled={
                    productTypeRemainingData &&
                    productTypeRemainingData?.length === 0
                      ? true
                      : false
                  }
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListModal;
