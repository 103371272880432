import React, { useState } from "react";
import WalletDisbursmentApprovalComment from "./WalletDisbursmentApprovalComment";
import WalletDisbursmentPinModal from "./WalletDisbursmentPinModal";

const WalletDisbursementApprovalSwitch = ({ pendingUpdate }) => {
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [commentDetails, setCommentDetails] = useState("");
  const [approvalSteps, setApprovalSteps] = useState("approval");

  return (
    <div
      className="modal fade"
      id="approvalDisbursmentModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="approvalDisbursmentModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          {(() => {
            switch (approvalSteps) {
              case "approval":
                return (
                  <WalletDisbursmentApprovalComment
                    setCommentDetails={setCommentDetails}
                    setApprovalSteps={setApprovalSteps}
                    setError={setError}
                  />
                );
              case "approvalpin":
                return (
                  <WalletDisbursmentPinModal
                    commentDetails={commentDetails}
                    loading={loading}
                    setLoading={setLoading}
                    error={error}
                    setError={setError}
                    success={success}
                    setSuccess={setSuccess}
                    pendingUpdate={pendingUpdate}
                    approvalSteps={approvalSteps}
                    setApprovalSteps={setApprovalSteps}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default WalletDisbursementApprovalSwitch;
