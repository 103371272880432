import { Fragment, useEffect, useRef, useState } from "react";
import CustomSelectDropdown from "../../../components/CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../../components/Paginator/Paginator.component";
import { Link, useHistory, withRouter } from "react-router-dom";
import PageLoader from "../../../components/PageLoader/PageLoader.component";
import useDisbursementDetails from "../../../custom-hooks/useDisbursementDetails";
import { accurateQuantifier } from "../../../components/utility";
import { attachClassNameToTag } from "../../../helpers/attachClassNameToTag";
import { formatMoney } from "./../../../helpers/formatter";
import NoTableDataAvailable from "./../../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import RollOverModal from "./AllDisbursedLoanModal/RollOverModal";
import { useAuth } from "../../../Context/auth.context";
import { postDataWithDotNet } from "../../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { useQueryCache } from "react-query";
import { errorHandler } from "../../../helpers/errorHandler";
import { appInsights } from "../../../components/AppInsight/AppInsight";

function AllDisbursedLoansMain() {
  const [loanRef, setLoanRef] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalActionType, setModalActionType] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [count, setCount] = useState(null);
  const [preState, setPreState] = useState({
    page: 1,
    PageSize: 10,
  });
  const [state, setState] = useState({
    page: 1,
    PageSize: 10,
    fromdate: "",
    todate: "",
    searchValue: "",
  });

  const { data, status, error } = useDisbursementDetails(preState);
  const { aggregatorDetails } = useAuth();
  let history = useHistory();
  const dismissRef = useRef();
  const queryCache = useQueryCache();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const filterData = (e) => {
    e.preventDefault();
    setCount(null);
    setPreState({ ...state, page: 1 });
  };
  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }
  const handleClick = (props) => {
    history.push({
      pathname: `/disbursements/${props.borrower_details.borrower_id}`,
      state: {
        loanRef: props.loan_ref,
        parent_rollover_loan_reference: props.parent_rollover_loan_reference,
        status: props.status,
        loan_id: props.loan_id,
      },
    });
  };
  const submitRepayment = async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await postDataWithDotNet(
        `${apiEndpoints.finalAutoRepayment}?reference=${loanRef}`
      );
      setLoading(false);
      if (dismissRef && dismissRef.current) {
        dismissRef.current.click();
      }
      setSubmitSuccess(
        "Repayment in being processed. Kindly check back in 10 minutes time to rollover the loan"
      );
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AllDisbursedLoansMain.jsx",
        },
      });
      setLoading(false);
      if (dismissRef && dismissRef.current) {
        dismissRef.current.click();
      }
      queryCache.invalidateQueries("getDisbursementDetails");
      setSubmitError(errorHandler(error));
    }
  };
  const submitRollOverRequest = async (reqBody) => {
    setLoading(true);
    try {
      await postDataWithDotNet(
        `${apiEndpoints.initiateLoanRollOver}?LoanRef=${loanRef}&PreferredLoanTenure=${reqBody.loan_tenure}`
      );
      setLoading(false);
      setModalActionType("step4");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AllDisbursedLoansMain.jsx",
        },
      });
      setSubmitError(errorHandler(error));
      setLoading(false);
      queryCache.invalidateQueries("getDisbursementDetails");
      setModalActionType("step4");
    }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "AllDisbursedLoansMain.jsx",
      isLoggedIn: true,
    });
  }, []);
  if (status === "loading") return <PageLoader />;

  return (
    <div>
      {error && <p className="alert alert-danger small">{error}</p>}
      {submitError && <p className="alert alert-danger small">{submitError}</p>}
      {submitSuccess && (
        <p className="alert alert-success small">{submitSuccess}</p>
      )}
      {!error && status !== "loading" && (
        <div>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h3 className="page-title mb-4">Loans</h3>
            </div>
            <form
              className="d-flex flex-row flex-wrap align-items-center custom-search-input mb-2"
              onSubmit={filterData}
            >
              {/* <div className="input-group mb-2" style={{ maxWidth: "200px" }}>
                <input
                  type="text"
                  name="searchValue"
                  className="search-query form-control"
                  placeholder="Search"
                  value={state.searchValue}
                  onChange={(e) => handleChange(e)}
                />
                <span className="fa fa-search"></span>
              </div> */}

              <div style={{ width: "150px", marginRight: "8px" }}>
                <CustomSelectDropdown
                  name="searchValue"
                  value={state.searchValue}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">All</option>
                  <option value="today">Today</option>
                  <option value="this_week">This week</option>
                  <option value="last_7_days">last 7 days</option>
                  <option value="last_30_days">last 30 days</option>
                  <option value="last_365_days">last 365 days</option>
                  <option value="custom_range">custom range</option>
                </CustomSelectDropdown>
              </div>
              {state.searchValue === "custom_range" && (
                <Fragment>
                  <div className="form-group mr-3">
                    <div className="input-group mt-2">
                      <label className="mr-2 mt-2">From </label>
                      <input
                        type="date"
                        name="fromdate"
                        className="form-control"
                        value={state.fromdate}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group mr-3 mt-2">
                    <div className="input-group">
                      <label className="mr-2 mt-2">To </label>
                      <input
                        type="date"
                        className="form-control"
                        name="todate"
                        value={state.todate}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
              <div className="mb-3">
                <button
                  type="submit"
                  className="btn advancly-btn btn-sm mt-2"
                  value="submit"
                  disabled={status === "loading"}
                >
                  Search
                </button>
              </div>
            </form>

            <div className="mb-2 ml-4">
              <Link
                to="/make-loan-request"
                className="btn advancly-btn btn-sm float-right"
              >
                Make Loan Request (s)
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-body p-3">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="page-subtitle">Loan List</h5>
                <div className="d-flex align-items-center">
                  <p className="pr-2 pt-1">Showing:</p>
                  <CustomSelectDropdown
                    onChange={(e) =>
                      setPreState({ ...state, PageSize: e.target.value })
                    }
                    value={preState.PageSize}
                    changeCurrentPage={(page) =>
                      setPreState({ ...state, page })
                    }
                  >
                    <option value={10}>10 Entries</option>
                    <option value={20}>20 Entries</option>
                    <option value={50}>50 Entries</option>
                    <option value={100}>100 Entries</option>
                  </CustomSelectDropdown>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead className="bg-lighter-grey">
                    <tr>
                      <th>Reference</th>
                      <th>Borrower</th>
                      <th>Product</th>
                      <th>Amount</th>
                      <th>Tenure</th>
                      <th>Interest</th>
                      <th>Outstanding</th>
                      <th>Borrower Type</th>
                      <th>Pub Date</th>
                      <th>Loan Status</th>
                      <th>Roll Over</th>
                      <th>Roll Over Status</th>
                      <th>Roll Over Ref</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.data?.length ? (
                      data?.data?.map((data, id) => {
                        const {
                          borrower_details: {
                            first_name,
                            last_name,
                            borrower_type_value,
                          },
                          loan_ref,
                          pub_date,
                          loan_amount,
                          product_name,
                          loan_tenure,
                          loan_outstanding,
                          loan_interest_amount,
                          status,
                          currency,
                          is_rollover,
                          rollover_loan_reference,
                          can_rollover,
                        } = data;
                        return (
                          <tr key={id}>
                            <td>{loan_ref}</td>
                            <td
                              style={{ minWidth: "180px" }}
                              className="text-capitalize"
                            >
                              {first_name && first_name.toLowerCase()}{" "}
                              {last_name && last_name.toLowerCase()}
                            </td>
                            <td style={{ minWidth: "150px" }}>
                              {product_name}
                            </td>
                            <td>{formatMoney(loan_amount, currency)}</td>
                            <td style={{ minWidth: "150px" }}>
                              {accurateQuantifier(loan_tenure, "day")}
                            </td>
                            <td>
                              {formatMoney(loan_interest_amount, currency)}
                            </td>
                            <td>{formatMoney(loan_outstanding, currency)}</td>
                            <td>{borrower_type_value}</td>
                            <td style={{ minWidth: "120px" }}>
                              {pub_date.substring(0, 10)}
                            </td>
                            <td>
                              <span className={attachClassNameToTag(status)}>
                                {attachClassNameToTag(status)}
                              </span>
                            </td>

                            {can_rollover === true ? (
                              <td
                                className="color-mid-blue cursor-pointer"
                                onClick={() => {
                                  setLoanRef(loan_ref);
                                  setModalActionType("step1");
                                }}
                                style={{ minWidth: "120px" }}
                                data-toggle="modal"
                                data-target="#rollOverModal"
                              >
                                Apply
                              </td>
                            ) : (
                              <td className="text-secondary">Not available</td>
                            )}
                            <td>{is_rollover ? "True" : "False"}</td>
                            <td>{rollover_loan_reference || "N/A"}</td>
                            <td
                              className="color-mid-blue cursor-pointer"
                              onClick={() => handleClick(data)}
                              style={{ minWidth: "120px" }}
                            >
                              View Details
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoTableDataAvailable />
                    )}
                  </tbody>
                </table>
              </div>
              {data && (
                <Paginator
                  size={preState.PageSize}
                  page={preState.page}
                  count={count}
                  changeCurrentPage={(page) => setPreState({ ...state, page })}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <RollOverModal
        modalActionType={modalActionType}
        setModalActionType={setModalActionType}
        loanRef={loanRef}
        aggregatorDetails={aggregatorDetails}
        submitRepayment={submitRepayment}
        submitRollOverRequest={submitRollOverRequest}
        loading={loading}
        submitError={submitError}
        dismissRef={dismissRef}
      />
    </div>
  );
}
export default withRouter(AllDisbursedLoansMain);
