import React, { Fragment, useEffect, useRef, useState } from "react";
import ToggleTab from "../../components/ToggleTab/ToggleTab.component";
import AutoRepaymentLoans from "./AutoRepaymentLoans";
import DueLoans from "./DueLoans";
import RepaymentList from "./RepaymentList";
import MakeRepaymentModal from "./RepaymentModal/MakeRepaymentModal";
import Alert from "../../components/Alert/Alert.component";
import { useAuth } from "../../Context/auth.context";
import { useQueryCache } from "react-query";
import { postDataWithDotNet } from "../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import useDueRepaymentDetails from "../../custom-hooks/useDueRepaymentDetails";
import useWalletDetails from "../../custom-hooks/useWalletDetails";
import { useLocation } from "react-router-dom";
import { appInsights } from "../../components/AppInsight/AppInsight";

function MainRepayment() {
  const [searchString, setSearchString] = useState("");
  const [activeTab, setActiveTab] = useState("repayment-list");
  const [searchValue, setSearchValue] = useState("");
  const [modalActionType, setModalActionType] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [checkAll, setCheckAll] = useState(false);

  const [state, setState] = useState({
    dataToStore: [],
    totalAmount: 0,
    repaymentError: false,
    repaymentSuccess: false,
    repaymentLoading: false,
    reference: "",
    deficitBalance: "",
  });

  const [currency, setCurrency] = useState("");
  const [dueLoansPreState, setDueLoansPreState] = useState({
    pageNumber: 1,
    size: 20,
  });
  const [count, setCount] = useState(null);
  const [dueLoansState, setDueLoansState] = useState({
    pageNumber: 1,
    size: 20,
    fromDate: "",
    toDate: "",
    loanReference: "",
  });
  const { aggregatorDetails } = useAuth();

  const { data, status, error } = useDueRepaymentDetails({
    Page: pageNumber,
    PageSize: size,
    currency,
  });

  const { data: walletDetails, status: walletDetailsStatus } =
    useWalletDetails();
  const dismissRef = useRef();
  const queryCache = useQueryCache();
  const location = useLocation();

  useEffect(() => {
    if (location && location?.state?.dueLoans === true) {
      setActiveTab("due-repayment");
    }
  }, [location]);

  const resetDataToStore = (e) => {
    setState({
      ...state,
      dataToStore: [],
      totalAmount: 0,
    });
    setCheckAll(false);
  };
  const submitRepayment = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      repaymentSuccess: false,
      repaymentError: false,
      repaymentLoading: true,
    });

    try {
      const result = await postDataWithDotNet(
        apiEndpoints.autoRepayment,
        state.dataToStore
      );
      if (result?.data && result?.data?.isPaid === 0) {
        setState({
          ...state,
          repaymentSuccess: false,
          repaymentError: false,
          repaymentLoading: false,
        });
        setModalActionType("step2");
        setState({
          ...state,
          reference: result?.data?.reference,
          deficitBalance: result?.data?.deficitBalance,
        });
      } else {
        setState({
          ...state,
          repaymentSuccess: result.message,
          repaymentError: false,
          repaymentLoading: false,
          dataToStore: [],
          totalAmount: 0,
        });
        if (dismissRef && dismissRef.current) {
          dismissRef.current.click();
        }
        queryCache.invalidateQueries([
          { Page: pageNumber, PageSize: size },
          "getDueRepaymentDetails",
        ]);
        queryCache.invalidateQueries(["getWalletDetails"]);
        setCheckAll(false);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "MainRepayment.jsx",
        },
      });
      if (dismissRef && dismissRef.current) {
        dismissRef.current.click();
      }
      setState({
        ...state,
        repaymentSuccess: false,
        repaymentError: errorHandler(error),
        repaymentLoading: false,
        dataToStore: [],
        totalAmount: 0,
      });
      queryCache.invalidateQueries([
        { Page: pageNumber, PageSize: size },
        "getDueRepaymentDetails",
      ]);
      queryCache.invalidateQueries(["getWalletDetails"]);
      setCheckAll(false);
    }
  };
  const finalSubmit = async () => {
    setState({
      ...state,
      repaymentSuccess: false,
      repaymentError: false,
      repaymentLoading: true,
    });

    try {
      const result = await postDataWithDotNet(
        `${apiEndpoints.finalAutoRepayment}?reference=${state.reference}`
      );
      if (dismissRef && dismissRef.current) {
        dismissRef.current.click();
      }
      setState({
        ...state,
        repaymentSuccess: result.message,
        repaymentError: false,
        repaymentLoading: false,
        dataToStore: [],
        totalAmount: 0,
      });
      queryCache.invalidateQueries([
        { Page: pageNumber, PageSize: size },
        "getDueRepaymentDetails",
      ]);
      queryCache.invalidateQueries(["getWalletDetails"]);
      setCheckAll(false);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "MainRepayment.jsx",
        },
      });
      if (dismissRef && dismissRef.current) {
        dismissRef.current.click();
      }
      setState({
        ...state,
        repaymentSuccess: false,
        repaymentError: errorHandler(error),
        repaymentLoading: false,
        dataToStore: [],
        totalAmount: 0,
      });
      queryCache.invalidateQueries([
        { Page: pageNumber, PageSize: size },
        "getDueRepaymentDetails",
      ]);
      queryCache.invalidateQueries(["getWalletDetails"]);
      setCheckAll(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDueLoansState({ ...dueLoansState, [name]: value });
  };
  const filterData = (e) => {
    e.preventDefault();
    setCount(null);
    setDueLoansPreState({ ...dueLoansState, pageNumber: 1 });
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "MainRepayment.jsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      {state.repaymentError && <Alert message={state.repaymentError} />}
      {state.repaymentSuccess && (
        <Alert message={state.repaymentSuccess} type="success" />
      )}
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <h3 className="page-title">Repayments</h3>
        </div>

        {activeTab === "repayment-list" && (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <form className="d-flex flex-row flex-wrap" onSubmit={filterData}>
                {/* <div
                className="input-group mr-3"
                style={{ maxWidth: "200px", marginTop: "10px" }}
              >
                <input
                  type="search"
                  className="form-control"
                  placeholder="Loan Ref"
                  value={dueLoansState.loanReference}
                  name="loanReference"
                  onChange={(e) => handleChange(e)}
                />
              </div> */}
                <div className="form-group mr-3">
                  <div className="input-group mt-2">
                    <label className="mr-2 mt-2">From </label>
                    <input
                      type="date"
                      name="fromDate"
                      className="form-control"
                      value={dueLoansState.fromDate}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group mr-3 mt-2">
                  <div className="input-group">
                    <label className="mr-2 mt-2">To </label>
                    <input
                      type="date"
                      className="form-control"
                      name="toDate"
                      value={dueLoansState.toDate}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn advancly-btn btn-sm mt-2"
                    value="submit"
                    disabled={status === "loading"}
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        {activeTab === "due-repayment" && (
          <>
            <button
              type="submit"
              className="btn advancly-btn btn-sm mt-2"
              style={{ cursor: "pointer" }}
              data-toggle="modal"
              data-target="#MakeRepaymentModal"
              onClick={() => {
                setModalActionType("step1");
              }}
              disabled={state.dataToStore?.length === 0}
            >
              Repay Loan
            </button>
          </>
        )}
      </div>
      <div className="card">
        <div className="px-3 pt-4">
          <Fragment>
            {/* <!-- Transactions Section --> */}
            <div className="alignToggleTabItems">
              <ToggleTab
                text="All Repayments"
                id="repayment-list"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <ToggleTab
                text="Due Loans"
                id="due-repayment"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <ToggleTab
                text="Closed Loans"
                id="auto-repayment"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
            <div className="row table-responsive">
              <div className="bg-white">
                {(() => {
                  switch (activeTab) {
                    case "repayment-list":
                      return (
                        <RepaymentList
                          dueLoansPreState={dueLoansPreState}
                          setDueLoansPreState={setDueLoansPreState}
                          dueLoansState={dueLoansState}
                          setDueLoansState={setDueLoansState}
                          count={count}
                        />
                      );
                    case "due-repayment":
                      return (
                        <DueLoans
                          searchString={searchString}
                          setSearchString={setSearchString}
                          searchValue={searchValue}
                          setSearchValue={setSearchValue}
                          state={state}
                          setState={setState}
                          data={data}
                          pageNumber={pageNumber}
                          size={size}
                          error={error}
                          setPageNumber={setPageNumber}
                          setSize={setSize}
                          status={status}
                          walletDetails={walletDetails}
                          walletDetailsStatus={walletDetailsStatus}
                          checkAll={checkAll}
                          setCheckAll={setCheckAll}
                          searchCurrency={currency}
                          setCurrency={setCurrency}
                        />
                      );
                    case "auto-repayment":
                      return (
                        <AutoRepaymentLoans
                          searchString={searchString}
                          setSearchString={setSearchString}
                          searchValue={searchValue}
                          setSearchValue={setSearchValue}
                        />
                      );

                    default:
                      return null;
                  }
                })()}
              </div>
            </div>
            <MakeRepaymentModal
              modalActionType={modalActionType}
              setModalActionType={setModalActionType}
              resetDataToStore={resetDataToStore}
              state={state}
              setState={setState}
              aggregatorDetails={aggregatorDetails}
              submitRepayment={submitRepayment}
              finalSubmit={finalSubmit}
              dismissRef={dismissRef}
            />
          </Fragment>
        </div>
      </div>
    </div>
  );
}
export default MainRepayment;
