import React from "react";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";

const CustomSelectDropdown = ({
  name,
  reference,
  children,
  label,
  errors,
  value,
  onChange,
  showRequiredIcon,
  extraLabel,
  required,
  className,
  readOnly,
  disabled,
  style,
  customSelectStyle,
}) => {
  return (
    <>
      <div className={`form-group ${className} `} style={style}>
        <div className="d-flex align-items-center">
          <label className={`form-label text-muted`}>{label}</label>
          {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
          {extraLabel && extraLabel.length && (
            <label className="form-label ml-2">
              <small className="crimson">{extraLabel}</small>
            </label>
          )}
        </div>
        <select
          className="form-control"
          name={name}
          ref={reference}
          value={value}
          onChange={onChange}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          style={{ borderRadius: "10px", ...customSelectStyle }}
        >
          {children}
        </select>
        <ErrorHandler errors={errors} />
      </div>
    </>
  );
};

export default CustomSelectDropdown;
