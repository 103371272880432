import React from "react";
import { useAuth } from "../../Context/auth.context";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import useCountryCodes from "../../custom-hooks/useCountryCodes";

const PersonalInfo = () => {
  const { aggregatorDetails } = useAuth();

  const { data: countries } = useCountryCodes();

  const resolveCountryInfo = countries?.find(
    ({ country_code }) => country_code === aggregatorDetails?.country_code
  );

  return (
    <div className="card">
      <div className="card-body p-4">
        <h5 className="page-subtitle">User Information</h5>

        <form className="js-validate mt-3">
          <div className="row">
            <div className="col-12 col-md-4">
              <CustomInputField
                type="text"
                label="First name"
                defaultValue={aggregatorDetails?.rep_first_name}
                readOnly
              />
            </div>
            <div className="col-12 col-md-4">
              <CustomInputField
                type="text"
                label="Last name"
                defaultValue={aggregatorDetails?.rep_last_name}
                readOnly
              />
            </div>

            <div className="col-12 col-md-4">
              <CustomInputField
                type="email"
                label="Email"
                defaultValue={aggregatorDetails?.rep_personal_email}
                readOnly
              />
            </div>
            <div className="col-12 col-md-4">
              <CustomInputField
                type="number"
                label=" Phone Number"
                defaultValue={aggregatorDetails?.rep_personal_phone}
                readOnly
              >
                {aggregatorDetails?.country_code && (
                  <select disabled>
                    <option value="">
                      {getUnicodeFlagIcon(aggregatorDetails?.country_code)}{" "}
                      {resolveCountryInfo?.dial_code}
                    </option>
                  </select>
                )}
              </CustomInputField>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-end mt-3 flex-wrap">
                <button
                  type="button"
                  disabled
                  className="btn advancly-nobg-btn btn-sm mr-3 transition-3d-hover"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled
                  className="btn advancly-btn btn-sm transition-3d-hover"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalInfo;
