import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { getData } from "../../../apis/apiMethods";
// import { useAuth } from "../../../Context/auth.context";
import { errorHandler } from "../../../helpers/errorHandler";
import ROUTES from "../../../helpers/ROUTES";
import Alert from "../../Alert/Alert.component";
import { appInsights } from "../../AppInsight/AppInsight";
import Loader from "../../Loader/Loader.component";

const SingleSignatoryModalStep3 = ({
  setSingleSignatory,
  setAgreementTick,
  step,
  singleSignatory,
}) => {
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");
  // const { aggregatorDetails } = useAuth();
  const { handleSubmit, register, watch } = useForm();
  const generateOTP = async (data) => {
    setLoading(true);
    setError(null);
    const { agreement } = data;
    setAgreementTick(agreement);
    setSingleSignatory(singleSignatory + step);

    try {
      await getData(apiEndpoints.GENERATE_OTP);
      setLoading(false);
      return data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "SingleSignatoryModalStep2.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const checkboxWatch = watch("agreement");

  return (
    <div>
      {error && <Alert message={error} />}
      <div className="modal-content">
        <div>
          <div className="modal-header">
            <h4 className="modal-title">Terms and Conditions</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(generateOTP)}>
              <small
                className="color-mid-blue text-right  row cursor-pointer font-weight-bold pl-2 "
                onClick={() => {
                  setSingleSignatory(singleSignatory - step);
                }}
              >
                &#8592; Go back
              </small>
              <p className="pt-2">
                In order to proceed, you need to agree to <br />
                our{" "}
                <Link
                  to={ROUTES.WALLET_TERMS_OF_USE}
                  target="_blank rel='noopener noreferrer"
                  className="color-mid-blue"
                >
                  {" "}
                  terms and agreement
                </Link>
              </p>
              <div>
                <label htmlFor="">
                  <input
                    type="checkbox"
                    name="agreement"
                    ref={register}
                    style={{ accentColor: "#713fff" }}
                  />{" "}
                  I agree
                </label>
              </div>
              <button
                className="btn btn-sm advancly-btn mt-5 w-100"
                disabled={!checkboxWatch}
              >
                Continue
                {loading && <Loader />}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleSignatoryModalStep3;
