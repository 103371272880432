import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getAllABorrowerLoans = async ({ id }) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.getAllABorrowerLoans}?borrower_id=${id}`
  );
  return data.data;
};

export default function useAllABorrowerLoans({ id }) {
  return useQuery([{ id }, "getAllABorrowerLoans"], getAllABorrowerLoans, {
    refetchOnWindowFocus: false,
  });
}
