import { useEffect, useState } from "react";
import BusinessInfo from "../../components/BusinessInfo/BusinessInfo.component.jsx";
import PersonalInfo from "../../components/PersonalInfo/PersonalInfo.component";
import WalletSignatorySetup from "../../components/WalletSignatorySetup/WalletSignatorySetup.jsx";
import BusinessBankInfo from "../../components/BusinessBankInfo/BusinessBankInfo.component";
import ChangePassword from "../../components/ChangePassword/ChangePassword.component";
import OtherAccountSettings from "./../../components/OtherAccountSettings/OtherAccountSettings.component";
import { useHistory } from "react-router-dom";
import DeveloperMainComponent from "../../components/Developer/DeveloperMainComponent";

const Settings = () => {
  const [changePasswordText, setChangePasswordText] = useState("");
  const history = useHistory();
  useEffect(() => {
    // if the user is being forced to change his password during login, do the things below.
    //@ts-ignore
    if (history?.location?.state?.forcedChangeOfPassword) {
      setChangePasswordText(
        "We have improved security on all accounts as part of our commitment to you. Kindly update your password."
      );
      const changePasswordSection = document.querySelector("#change_password");
      changePasswordSection?.scrollIntoView({
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <h3 className="page-title mb-4">Settings</h3>
      <div className="mb-3">
        <BusinessInfo />
      </div>
      <div className="mb-3">
        <BusinessBankInfo />
      </div>
      <div className="mb-3">
        <WalletSignatorySetup />
      </div>
      <div className="mb-3">
        <PersonalInfo />
      </div>
      <div className="mb-3">
        <OtherAccountSettings />
      </div>
      <div className="mb-3">
        <DeveloperMainComponent />
      </div>
      <div className="mb-3" id="change_password">
        <ChangePassword changePasswordText={changePasswordText} />
      </div>
    </div>
  );
};

export default Settings;
