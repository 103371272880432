import React from "react";
import { Route } from "react-router";
import ROUTES from "./../../helpers/ROUTES";
import GetStartedOnboarding from "./GetStartedOnboarding/GetStartedOnboarding";
import GetStartedOfferLetter from "./GetStartedOfferLetter/GetStartedOfferLetter";
import GetStarted from "./GetStartedLanding/GetStarted.component";
import GetStartedEligibilityTest from "./GetStartedOnboarding/GetStartedEligibilityTest/GetStartedEligibilityTest";
import GetStartedBusinesInfo from "./GetStartedOnboarding/GetStartedBusinesInfo/GetStartedBusinesInfo";
import GetStartedUploadDocuments from "./GetStartedOnboarding/GetStartedUploadDocuments/GetStartedUploadDocuments";
import GetStartedFinalPage from "./GetStartedFinalPage/GetStartedFinalPage";

export default function GetStartedRoutes() {
  return (
    <div>
      <Route exact path={ROUTES.GET_STARTED} component={GetStarted} />
      <Route
        exact
        path={ROUTES.GET_STARTED_OFFER}
        component={GetStartedOfferLetter}
      />
      <Route
        exact
        path={ROUTES.GET_STARTED_ONBOARDING}
        component={GetStartedOnboarding}
      />
      <Route
        exact
        path={ROUTES.GET_STARTED_ONBOARDING_BUSINESS_INFO}
        component={GetStartedBusinesInfo}
      />
      <Route
        exact
        path={ROUTES.GET_STARTED_ONBOARDING_ELIGIBLITY_TEST}
        component={GetStartedEligibilityTest}
      />
      <Route
        exact
        path={ROUTES.GET_STARTED_ONBOARDING_UPLOAD_DOCUMENT}
        component={GetStartedUploadDocuments}
      />
      <Route
        exact
        path={ROUTES.GET_STARTED_ONBOARDING_FINAL}
        component={GetStartedFinalPage}
      />
    </div>
  );
}
