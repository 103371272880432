import React, { Fragment } from "react";

export default function MultipleLoanFieldsViewModal({ dataToView }) {
  return (
    <Fragment>
      <div
        className="modal fade"
        id="multipleLoanFieldsViewModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="multipleLoanFieldsViewModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="page-subtitle">{dataToView.type}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Fragment>
                {(() => {
                  switch (dataToView.type) {
                    case "SECTORS":
                      return (
                        <div className="table-responsive">
                          <table className="table table-white table-hover">
                            <thead className="bg-lighter-grey">
                              <tr>
                                <th>Sector Name</th>
                                <th>Sector ID</th>
                              </tr>
                            </thead>
                            <tbody>
                              <Fragment>
                                {dataToView.data &&
                                  dataToView.data.map((sector) => {
                                    const { category_id, category_name } =
                                      sector;
                                    return (
                                      <tr key={category_id}>
                                        <td>{category_name}</td>
                                        <td>{category_id}</td>
                                      </tr>
                                    );
                                  })}
                              </Fragment>
                            </tbody>
                          </table>
                        </div>
                      );
                    case "PRODUCTS":
                      return (
                        <div className="table-responsive">
                          <table className="table table-white table-hover">
                            <thead className="bg-lighter-grey">
                              <tr>
                                <th>Product Name</th>
                                <th>Product ID</th>
                              </tr>
                            </thead>
                            <tbody>
                              <Fragment>
                                {dataToView.data &&
                                  dataToView.data.map((product) => {
                                    const { id, product_name } = product;
                                    return (
                                      <tr key={id}>
                                        <td>{product_name}</td>
                                        <td>{id}</td>
                                      </tr>
                                    );
                                  })}
                              </Fragment>
                            </tbody>
                          </table>
                        </div>
                      );
                    case "BANKS":
                      return (
                        <div className="table-responsive">
                          <table className="table table-white table-hover">
                            <thead className="bg-lighter-grey">
                              <tr>
                                <th>Bank Name</th>
                                <th>Bank Code</th>
                              </tr>
                            </thead>
                            <tbody>
                              <Fragment>
                                {dataToView.data &&
                                  dataToView.data.map((bank) => {
                                    const { code, name } = bank;
                                    return (
                                      <tr key={code}>
                                        <td>{name}</td>
                                        <td>{code}</td>
                                      </tr>
                                    );
                                  })}
                              </Fragment>
                            </tbody>
                          </table>
                        </div>
                      );
                    case "STATES":
                      return (
                        <div className="table-responsive">
                          <table className="table table-white table-hover">
                            <thead className="bg-lighter-grey">
                              <tr>
                                <th>S/N</th>
                                <th>State Code</th>
                                <th>State</th>
                              </tr>
                            </thead>
                            <tbody>
                              <Fragment>
                                {dataToView.data &&
                                  dataToView.data.map((state, _id) => {
                                    const { state_code, name } = state;
                                    return (
                                      <tr key={state_code}>
                                        <td>{_id + 1}</td>
                                        <td>{state_code}</td>
                                        <td>{name}</td>
                                      </tr>
                                    );
                                  })}
                              </Fragment>
                            </tbody>
                          </table>
                        </div>
                      );
                    default:
                      return null;
                  }
                })()}
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
