import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "../../components/Loader/Loader.component";
import CustomTextArea from "../../components/CustomHTMLElements/CustomTextArea";
import { useQueryCache } from "react-query";
import { postData } from "../../apis/apiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { appInsights } from "../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  comment: yup
    .string()
    .trim()
    .required("Please enter a comment")
    .min(5, "Comment must have at least 5 characters"),
});
const WalletDisbursmentDeclineComment = ({ pendingUpdate }) => {
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const queryCache = useQueryCache();

  const onSubmitHandler = async ({ comment }) => {
    setLoading(true);
    setError("");
    setSuccess("");

    let reqBody = {
      reference: pendingUpdate?.maker_checker_reference,
      comment,
    };
    try {
      const res = await postData(apiEndpoints.DECLINE_DISBURSMENT, reqBody);
      setLoading(false);
      reset();
      setSuccess(res.message);
      setError("");
      queryCache.invalidateQueries("getPendingDisbursement");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "WalletDisbursmentDeclineComment.jsx",
        },
      });
      setLoading(false);
      reset();
      setSuccess("");
      setError(errorHandler(error));
    }
  };
  const refreshModal = () => {
    queryCache.invalidateQueries("getPendingDisbursement");
    reset();
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletDisbursmentDeclineComment.jsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div
      className="modal fade"
      id="declineDisbursmentModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="declineDisbursmentModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          {error && <p className="alert alert-danger small row">{error}</p>}
          {success ? (
            <div className="modal-body">
              <div className="d-flex align-items-center justify-content-between flex-column">
                <i className="ri-checkbox-circle-fill text-success ri-5x"></i>
                <h5 className="mt-5 font-weight-bold"> {success}</h5>
                <button
                  type="button"
                  className="btn btn-sm advancly-btn mt-5 w-100 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Done
                </button>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="modal-header border-bottom-0">
                <h6 className="text-center font-weight-bold text-capitalize">
                  Decline Disbursement{" "}
                </h6>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={refreshModal}
                ></button>
              </div>
              <div className="modal-body">
                <Fragment>
                  {loading ? (
                    <PageLoader />
                  ) : (
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                      <div className="form-group">
                        <CustomTextArea
                          maxLength="128"
                          reference={register}
                          placeholder="Add a comment"
                          label="Add Comment"
                          name="comment"
                          errors={errors.comment}
                        />
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn advancly-white-btn btn-sm transition-3d-hover w-50 mr-2"
                            data-dismiss="modal"
                            disabled={loading}
                            onClick={refreshModal}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn advancly-btn btn-sm transition-3d-hover w-50 ml-2"
                            type="submit"
                            disabled={loading}
                          >
                            Submit
                            {loading && <Loader />}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Fragment>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default WalletDisbursmentDeclineComment;
