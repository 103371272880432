import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import useAllABorrowerRepayments from "../../custom-hooks/useAllABorrowerRepayments";
import EachRepayment from "../EachRepayment/EachRepayment.component";
import Loader from "../Loader/Loader.component";
import NoTableDataAvailable from "../NoTableDataAvailable/NoTableDataAvailable.component";

const AllABorrowerRepayments = ({ match }) => {
  const { data, status, error } = useAllABorrowerRepayments({
    id: match.params.borrower_id,
  });

  if (status === "loading")
    return (
      <div className="d-flex justify-content-center">
        <Loader />
      </div>
    );
  if (error)
    return <h5 className="alert alert-danger small">An error occured</h5>;
  return (
    <Fragment>
      {/* <!-- Search --> */}
      <div className="js-focus-state input-group input-group-sm my-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="searchRepaymentsTable">
            <span className="fas fa-search"></span>
          </span>
        </div>
        <input
          id="repaymentsTableSearch"
          type="email"
          className="form-control"
          placeholder="Search transactions"
          aria-label="Search activities"
          aria-describedby="searchRepaymentsTable"
        />
      </div>
      {/* <!-- End Search --> */}
      {/* <!-- Repayments Table --> */}
      <div className="table-responsive">
        <table className="table mb-5">
          <thead className="bg-lighter-grey">
            <tr>
              <th>Reference</th>
              <th>Borrower</th>
              <th>Product</th>
              <th>Amount</th>
              <th>Outstanding</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody className="font-size-1">
            {data.length > 0 ? (
              data.map((data, index) => {
                return <EachRepayment data={data} key={index} />;
              })
            ) : (
              <NoTableDataAvailable />
            )}
          </tbody>
        </table>
      </div>
      {/* <!-- End Repayments Table --> */}
    </Fragment>
  );
};
export default withRouter(AllABorrowerRepayments);
