import React, { useState } from "react";
import SelectWalletModal from "./SelectWalletModal";
import SendMoneyModalComponent from "./SendMoneyModalComponent";
import SendMoneyNaration from "./SendMoneyNaration";
import SendMoneyTransactionPin from "./SendMoneyTransactionPin";

const SendMoneyModal = () => {
  const [sendMoneySteps, setSendMoneySteps] = useState(1);
  const [allwalletsState, setAllWalletsState] = useState("");
  const step = 1;
  const [walletAccountNum, setWalletAccountNum] = useState();
  const [recipientDetails, setRecipientDetails] = useState();
  const [walletProviderType, setWalletProviderType] = useState("");
  const [narration, setNarration] = useState("");
  return (
    <div id="SendMoneyModal" className="modal fade" role="dialog" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        {(() => {
          switch (sendMoneySteps) {
            case 1:
              return (
                <SelectWalletModal
                  sendMoneySteps={sendMoneySteps}
                  setSendMoneySteps={setSendMoneySteps}
                  step={step}
                  setWalletAccountNum={setWalletAccountNum}
                  setAllWalletsState={setAllWalletsState}
                  setWalletProviderType={setWalletProviderType}
                />
              );
            case 2:
              return (
                <SendMoneyModalComponent
                  sendMoneySteps={sendMoneySteps}
                  setSendMoneySteps={setSendMoneySteps}
                  step={step}
                  setRecipientDetails={setRecipientDetails}
                  walletAccountNum={walletAccountNum}
                  allwalletsState={allwalletsState}
                />
              );
            case 3:
              return (
                <SendMoneyNaration
                  sendMoneySteps={sendMoneySteps}
                  setSendMoneySteps={setSendMoneySteps}
                  step={step}
                  setRecipientDetails={setRecipientDetails}
                  walletAccountNum={walletAccountNum}
                  allwalletsState={allwalletsState}
                  setNarration={setNarration}
                />
              );

            case 4:
              return (
                <SendMoneyTransactionPin
                  sendMoneySteps={sendMoneySteps}
                  setSendMoneySteps={setSendMoneySteps}
                  step={step}
                  walletAccountNum={walletAccountNum}
                  recipientDetails={recipientDetails}
                  walletProviderType={walletProviderType}
                  narration={narration}
                />
              );

            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
};

export default SendMoneyModal;
