import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getRecentDisbursements = async ({
  currentCurrencyValue,
  activeProductName,
}) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.getRecentDisbursements}?currency=${currentCurrencyValue}&product=${activeProductName}`
  );
  return data.data;
};

export default function useRecentDisbursements({
  currentCurrencyValue,
  activeProductName,
}) {
  return useQuery(
    [{ currentCurrencyValue, activeProductName }, "getRecentDisbursements"],
    getRecentDisbursements,
    {
      enabled: currentCurrencyValue && activeProductName ? true : false,

      refetchOnWindowFocus: false,
    }
  );
}
