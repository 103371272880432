import React from "react";
import DeveloperAPIKeys from "./DeveloperAPIKeys/DeveloperAPIKeys";
import WebHookMainComponent from "./WebHooks/WebHookMainComponent";

const DeveloperMainComponent = () => {
  return (
    <div className="card">
      <div className="card-body p-4">
        <h5 className="page-subtitle">Developer</h5>

        <DeveloperAPIKeys />
        <WebHookMainComponent />
      </div>
    </div>
  );
};

export default DeveloperMainComponent;
