import { useState } from "react";
import useAggregatorSignatoryUsers from "../../../custom-hooks/useAggregatorSignatoryUsers";
import AddNewUserModalContent from "../../AddNewUserModal/AddNewUserModalContent";
import ChangeSingleSignatoryModal1 from "./ChangeSingleSignatoryModal1";
import ChangeSingleSignatoryModal2 from "./ChangeSingleSignatoryModal2";
import ChangeSingleSignatoryModal3 from "./ChangeSingleSignatoryModal3";

const ChangeSingleSignatoryComponentPage = ({
  dismissRef,
  signatories,
  signatoriesStatus,
}) => {
  const [changeSingleSignatory, setChangeSingleSignatory] = useState(2);
  const [changeSingleSignatoryName, setChangeSinglesSignatoryName] =
    useState("");

  const [agreementTick, setAgreementTick] = useState();
  const [changeAddNewUserModal, setChangeAddNewUserModal] = useState(false);
  const step = 1;
  const {
    data: usersData,
    status: usersStatus,
    error: usersError,
  } = useAggregatorSignatoryUsers();
  return (
    <div>
      {(() => {
        switch (changeSingleSignatory) {
          case 1:
            return (
              <AddNewUserModalContent
                changeSingleSignatory={changeSingleSignatory}
                setChangeSingleSignatory={setChangeSingleSignatory}
                changeAddNewUserModal={changeAddNewUserModal}
                step={step}
              />
            );
          case 2:
            return (
              <ChangeSingleSignatoryModal1
                usersData={usersData}
                usersStatus={usersStatus}
                usersError={usersError}
                changeSingleSignatory={changeSingleSignatory}
                setChangeSingleSignatory={setChangeSingleSignatory}
                setChangeSinglesSignatoryName={setChangeSinglesSignatoryName}
                setChangeAddNewUserModal={setChangeAddNewUserModal}
                step={step}
                dismissRef={dismissRef}
                signatories={signatories}
                signatoriesStatus={signatoriesStatus}
              />
            );

          case 3:
            return (
              <ChangeSingleSignatoryModal2
                changeSingleSignatory={changeSingleSignatory}
                setChangeSingleSignatory={setChangeSingleSignatory}
                setAgreementTick={setAgreementTick}
                step={step}
                dismissRef={dismissRef}
              />
            );
          case 4:
            return (
              <ChangeSingleSignatoryModal3
                changeSingleSignatory={changeSingleSignatory}
                setChangeSingleSignatory={setChangeSingleSignatory}
                changeSingleSignatoryName={changeSingleSignatoryName}
                agreementTick={agreementTick}
                step={step}
                dismissRef={dismissRef}
              />
            );

          default:
            return "";
        }
      })()}
    </div>
  );
};

export default ChangeSingleSignatoryComponentPage;
