import React, { useRef, useState } from "react";
import useGeneratedAggregatorKey from "../../custom-hooks/useGeneratedAggregatorKey";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import Loader from "../Loader/Loader.component";
import EditButton from "../../svg/icons/edit-button.svg";
import { ReactComponent as DummyUser } from "../../svg/icons/user-fill.svg";
import styles from "./BusinessInfo.module.scss";
import { convertToBase64 } from "../../helpers/convertToBase64";
import { postDataWithDotNet } from "../../apis/dotNetApiMethods";
import { apiEndpoints } from "./../../apis/apiEndpoints";
import { useAuth } from "../../Context/auth.context";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import useCountryCodes from "../../custom-hooks/useCountryCodes";
import { appInsights } from "../AppInsight/AppInsight";

const BusinessInfo = () => {
  const { aggregatorDetails } = useAuth();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgBase64, setImgBase64] = useState("");

  const photoRef = useRef();

  const fileSelected = async (e) => {
    setImgBase64(await convertToBase64(e.target.files[0]));
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setMessage(null);

    const formElements = e.target;
    const fd = new FormData(formElements);
    fd.append("aggregator_id", aggregatorDetails?.aggregator_id);

    try {
      const res = await postDataWithDotNet(
        apiEndpoints.updateAggregatorUserPhoto,
        fd,
        true
      );
      setMessage(res.message);
      setLoading(false);
      setTimeout(() => {
        setMessage(null);
      }, 5000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "BusinessInfo.component.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const { data: countries } = useCountryCodes();
  const resolveCountryInfo = countries?.find(
    ({ dial_code }) => dial_code === aggregatorDetails?.biz_phone_dialcode
  );

  const {
    status,
    data,
    error: publicKeyError,
  } = useGeneratedAggregatorKey({
    aggregator_id: aggregatorDetails?.aggregator_id,
  });
  if (publicKeyError && !error) {
    setError(errorHandler(publicKeyError));
  }
  return (
    <div className="card">
      <div className="card-body p-4">
        <h5 className="page-subtitle">Business Information</h5>
        {message && <p className="alert alert-success small">{message}</p>}
        {error && <p className="alert alert-danger small">{error}</p>}
        <div className={styles.alignBusinessInfo}>
          <div className={styles.profilePhoto}>
            {imgBase64 ? (
              <img
                src={imgBase64}
                alt="Logo"
                onClick={() => photoRef.current.click()}
                className={styles.profileImage}
              />
            ) : aggregatorDetails?.aggregator_photo_url ? (
              <img
                src={aggregatorDetails?.aggregator_photo_url}
                alt="Logo"
                onClick={() => photoRef.current.click()}
                className={styles.profileImage}
              />
            ) : (
              <DummyUser className={styles.profileImage} />
            )}
            <img src={EditButton} className={styles.editButton} alt="" />
          </div>

          <form onSubmit={handleFileUpload}>
            <input
              type="file"
              onChange={fileSelected}
              id="profilePhoto"
              ref={photoRef}
              accept="image/*"
              className="d-none"
              name="aggregator_photo"
            />
            <div className="row">
              <div className="col-12 col-md-6">
                <CustomInputField
                  type="text"
                  name="bname"
                  label="Business name"
                  defaultValue={aggregatorDetails?.biz_name}
                  readOnly
                />
              </div>
              <div className="col-12 col-md-6">
                <CustomInputField
                  label="Business Phone"
                  name="bphone"
                  type="number"
                  defaultValue={aggregatorDetails?.biz_phone}
                  readOnly
                >
                  {resolveCountryInfo?.country_code && (
                    <select disabled>
                      <option value="">
                        {getUnicodeFlagIcon(resolveCountryInfo?.country_code)}{" "}
                        {aggregatorDetails?.biz_phone_dialcode}
                      </option>
                    </select>
                  )}
                </CustomInputField>
              </div>

              <div className="col-12 col-md-6">
                <CustomInputField
                  label="Business Email"
                  name="bemail"
                  type="email"
                  defaultValue={aggregatorDetails?.biz_email}
                  readOnly
                />
              </div>
              <div className="col-12 col-md-6">
                <CustomInputField
                  label="Business Address"
                  name="address"
                  type="text"
                  defaultValue={aggregatorDetails?.biz_address}
                  readOnly
                />
              </div>

              <div className="col-12 col-md-6">
                <CustomInputField
                  label="Country"
                  name="countryCode"
                  type="text"
                  defaultValue={aggregatorDetails?.country_name}
                  readOnly
                />
              </div>
              <div className="col-12 col-md-6">
                <CustomInputField
                  label="Short Name"
                  name="shortName"
                  type="text"
                  defaultValue={aggregatorDetails?.short_name}
                  readOnly
                />
              </div>
              <div className="col-12 col-md-6">
                <CustomInputField
                  label="Public Key"
                  name="key"
                  type="text"
                  defaultValue={data?.public_key}
                  readOnly
                  extraLabel={status === "loading" && "(Loading public key...)"}
                />
              </div>
              <div className="col-12">
                <div className="d-flex justify-content-end mt-3 flex-wrap">
                  <button
                    type="button"
                    disabled={loading || !imgBase64}
                    className="btn advancly-nobg-btn btn-sm mr-3 transition-3d-hover"
                    onClick={() => setImgBase64("")}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={loading || !imgBase64}
                    className="btn advancly-btn btn-sm transition-3d-hover"
                  >
                    Save Changes {loading && <Loader />}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfo;
