import React, { useState } from "react";
import SecurityQuestionModalComponent from "./SecurityQuestionModalComponent";
import SetPinModal from "./SetPinModal";

const SecurityQuestionModal = () => {
  const [section, setSection] = useState("1");
  return (
    <div className="modal fade" id="securityQuestionModal">
      <div className="modal-dialog modal-dialog-centered">
        {(() => {
          switch (section) {
            case "1":
              return <SecurityQuestionModalComponent setSection={setSection} />;
            case "2":
              return <SetPinModal />;
            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
};

export default SecurityQuestionModal;
