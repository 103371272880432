import React from "react";
import DashboardCard from "../../components/DashboardCard/DashboardCard.component";
import { formatMoneyNoDecimal } from "../../helpers/formatterNoDecimal";
import { ReactComponent as ProgressIconDown } from "../../svg/icons/progress_icon_down.svg";
import { ReactComponent as ProgressIconPeople } from "../../svg/icons/progress_people_icon.svg";
import { ReactComponent as ProgressIconMoney } from "../../svg/icons/progress_money_icon.svg";
import { ReactComponent as ProgressIconTop } from "../../svg/icons/progress_icon_top.svg";
import LoanDashboardGraphs from "../../components/DashboardGraphs/LoanDashboardGraphs.component";

const LoanDashboardTab = ({
  currentCurrency,
  transactionsOverview,
  trasactionOverviewStatus,
  loanCount,
  loanCountStatus,
  totalOutstandingLoans,
  totalOutstandingStatus,
  activeProductName,
}) => {
  totalOutstandingStatus = { totalOutstandingStatus };
  return (
    <div>
      {/* DASHBOARD LOANS */}
      <div className="card-deck d-block d-lg-flex card-lg-gutters-4 mb-4">
        <DashboardCard
          text="Total amount disbursed"
          value={
            transactionsOverview &&
            formatMoneyNoDecimal(
              Math.trunc(transactionsOverview?.total_disbursement),
              transactionsOverview?.currency
            )
          }
          status={trasactionOverviewStatus}
          activeDefaultColor={true}
        >
          {" "}
          <ProgressIconTop />
        </DashboardCard>
        <DashboardCard
          text="Total repayment"
          value={
            transactionsOverview &&
            formatMoneyNoDecimal(
              transactionsOverview?.total_repayment,
              transactionsOverview?.currency
            )
          }
          status={trasactionOverviewStatus}
        >
          <ProgressIconDown />
        </DashboardCard>
        <DashboardCard
          text="Total outstanding loan"
          value={
            totalOutstandingLoans &&
            formatMoneyNoDecimal(
              totalOutstandingLoans?.totalAmount,
              totalOutstandingLoans?.currency
            )
          }
          status={totalOutstandingStatus}
        >
          <ProgressIconPeople />
        </DashboardCard>
        <DashboardCard
          text="Loan counts"
          value={loanCount >= 1 ? loanCount : 0}
          status={loanCountStatus}
        >
          <ProgressIconMoney />
        </DashboardCard>
      </div>
      <div className="horizontal-line-thin"></div>
      <LoanDashboardGraphs
        currentCurrency={currentCurrency}
        activeProductName={activeProductName}
      />
      {/* DASHBOARD LOANS */}
    </div>
  );
};

export default LoanDashboardTab;
