import { useEffect, useState } from "react";
import Paginator from "../../components/Paginator/Paginator.component";
import { Link, withRouter } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import NoTableDataAvailable from "./../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import { useAuth } from "../../Context/auth.context";
import useAllInvestorsDetails from "../../custom-hooks/useAllInvestorsDetails";
import { appInsights } from "../../components/AppInsight/AppInsight";

function AllInvestorsMain() {
  const { aggregatorDetails } = useAuth();
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    searchBy: 3,
    aggregatorId: aggregatorDetails?.aggregator_id,
  });
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    searchBy: 3,
    aggregatorId: aggregatorDetails?.aggregator_id,
    startDate: "",
    endDate: "",
    searchValue: "",
  });
  const [count, setCount] = useState(null);

  const { data, status, error } = useAllInvestorsDetails(preState);

  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const filterData = (e) => {
    e.preventDefault();
    setCount(null);
    setPreState({ ...state, pageNumber: 1 });
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "AllInvestorMain.jsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div>
      {error && <p className="alert alert-danger small">{error}</p>}
      {!error && (
        <div>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h3 className="page-title mb-4">Investors</h3>
            </div>
            <div className="d-flex flex-wrap align-items-center custom-search-input mb-2"></div>
          </div>
          <div className="card">
            {status === "loading" ? (
              <div className="card-body p-3">
                <PageLoader />
              </div>
            ) : (
              <div className="card-body p-3">
                <div className="d-flex justify-content-between align-items-center py-3">
                  <form
                    className="d-flex flex-row flex-wrap"
                    onSubmit={filterData}
                  >
                    <div
                      className="input-group mr-3"
                      style={{ maxWidth: "200px" }}
                    >
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Name..."
                        value={state.searchValue}
                        name="searchValue"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>

                    <div className="form-group mr-3">
                      <div className="input-group ">
                        <label className="mr-2 mt-2">From </label>
                        <input
                          type="date"
                          name="startDate"
                          className="form-control"
                          value={state.startDate}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group mr-3">
                      <div className="input-group">
                        <label className="mr-2 mt-2">To </label>
                        <input
                          type="date"
                          className="form-control"
                          name="endDate"
                          value={state.endDate}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn advancly-btn btn-sm"
                        value="submit"
                        disabled={status === "loading"}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead className="bg-lighter-grey">
                      <tr>
                        <th>S/N</th>
                        <th>Business Name</th>
                        <th>Name</th>
                        <th>Investor Type</th>
                        <th>Contact Email</th>
                        <th>Gender</th>
                        <th>Country</th>
                        <th>Created Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.length ? (
                        data?.data?.map((request, _id) => {
                          const {
                            email,
                            funder_id,
                            first_name,
                            last_name,
                            funder_type,
                            pub_date,
                            gender,
                            country,
                            biz_name,
                          } = request;
                          return (
                            <tr key={_id}>
                              <td>
                                {(preState.pageNumber - 1) * preState.size +
                                  (_id + 1)}
                              </td>
                              <td>{biz_name || "N/A"}</td>
                              <td>
                                {first_name} {last_name}
                              </td>
                              <td>{funder_type}</td>
                              <td>{email}</td>
                              <td>{gender}</td>
                              <td>{country}</td>
                              <td>{pub_date?.substring(0, 10)}</td>
                              <td>
                                <Link
                                  to={`/investor/${funder_id}`}
                                  className="color-blue"
                                >
                                  View Details
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
                {data && data?.data && (
                  <Paginator
                    size={preState.size}
                    page={preState.pageNumber}
                    count={count}
                    changeCurrentPage={(pageNumber) =>
                      setPreState({ ...state, pageNumber })
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default withRouter(AllInvestorsMain);
