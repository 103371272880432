import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import useAggregatorUsers from "../../custom-hooks/useAggregatorUsers";
import AddNewUserModal from "../AddNewUserModal/AddNewUserModal.component";
import styles from "./UsersManagement.module.scss";
import { errorHandler } from "../../helpers/errorHandler";
import UpdateUserConfirmationDialog from "../UpdateUserConfirmationDialog/UpdateUserConfirmationDialog.component";
import NoTableDataAvailable from "../NoTableDataAvailable/NoTableDataAvailable.component";
import TableDataLoading from "../TableDataLoading/TableDataLoading.component";
import EditUserRoleModal from "../EditUserRoleModal/EditUserRoleModal";

interface IUser {
  email: string;
  lastName: string;
  firstName: string;
  isActive: string;
  id: string;
  phone: string;
  roles: string[];
}

const UsersManagement = () => {
  const { data: users, status, error } = useAggregatorUsers();
  const [userToUpdate, setUserToUpdate] = useState<IUser>();

  if (error)
    return (
      <p className="alert alert-danger small text-center">
        {errorHandler(error)}
      </p>
    );

  return (
    <div>
      <h3 className="page-title mb-4">Users Management</h3>
      <div className="card">
        <div className="card-body p-4">
          <h5 className="page-subtitle">Users</h5>
          <div className={styles.userManagementWrapper}>
            <div className={styles.alignPage}>
              <div className="table-responsive">
                <table className="table">
                  <thead className="bg-lighter-grey">
                    <tr>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Phone Number</th>
                      <th>Assigned Role</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {status === "loading" ? (
                      <TableDataLoading />
                    ) : users && users.length ? (
                      <Fragment>
                        {users.map((user: IUser) => {
                          const {
                            email,
                            lastName,
                            firstName,
                            isActive,
                            id,
                            phone,
                            roles,
                          } = user;

                          return (
                            <tr key={id}>
                              <th>
                                {firstName} {lastName}
                              </th>
                              <td>{email}</td>
                              <td>{phone}</td>
                              <td>
                                {" "}
                                {roles.map((role, id) => {
                                  return (
                                    <ul key={id}>
                                      <li>{role}</li>
                                    </ul>
                                  );
                                })}
                              </td>
                              <td>{isActive ? "Active" : "Inactive"}</td>
                              <td className="dropright">
                                <span
                                  className="more-icon"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></span>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <button
                                    className="btn btn-sm advancly-nobg-btn"
                                    data-toggle="modal"
                                    data-target="#updateUserStatusModal"
                                    onClick={() => setUserToUpdate(user)}
                                  >
                                    {isActive ? "Disable User" : "Enable User"}
                                  </button>
                                  <button
                                    className="btn btn-sm advancly-nobg-btn"
                                    data-toggle="modal"
                                    data-target="#editUserRoleModal"
                                    onClick={() => setUserToUpdate(user)}
                                  >
                                    {" "}
                                    Edit User
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </Fragment>
                    ) : (
                      <NoTableDataAvailable text="There are currently no users" />
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-sm advancly-white-btn"
                    data-toggle="modal"
                    data-target="#addNewUserModal"
                  >
                    Add New User
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddNewUserModal />
      <EditUserRoleModal userToUpdate={userToUpdate} />
      <UpdateUserConfirmationDialog userToUpdate={userToUpdate} />
    </div>
  );
};

export default withRouter(UsersManagement);
