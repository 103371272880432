import React from "react";
import { Fragment } from "react";
import styles from "./DashboardGraphs.module.scss";
import Loader from "../Loader/Loader.component";
import { useAuth } from "../../Context/auth.context";
import InvestmentsGraph from "./InvestmentsGraph/InvestmentsGraph.component";
import InvestmentInterestGraph from "./Interests/InvestmentInterestGraph.component";
import InvestmentInvestorsGraph from "./InvestmentInvestors/InvestmentInvestorsGraph";

export const InvestmentDashboardGraph = ({
  currentCurrency,
  activeProductName,
}) => {
  const { aggregatorDetails } = useAuth();

  return (
    <div className={`${styles.alignGraphs} mt-4`}>
      {aggregatorDetails ? (
        <Fragment>
          <InvestmentsGraph
            aggregatorId={aggregatorDetails && aggregatorDetails.aggregator_id}
            currentCurrency={currentCurrency}
            activeProductName={activeProductName}
          />
          <InvestmentInterestGraph
            aggregatorId={aggregatorDetails && aggregatorDetails.aggregator_id}
            currentCurrency={currentCurrency}
            activeProductName={activeProductName}
          />
          <InvestmentInvestorsGraph
            aggregatorId={aggregatorDetails && aggregatorDetails.aggregator_id}
            currentCurrency={currentCurrency}
            activeProductName={activeProductName}
          />
        </Fragment>
      ) : (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      )}
    </div>
  );
};
