import React from "react";
import Lottie from "react-lottie";
import animationData from "../../img/Advancly-loading-animation.json";

const PageLoaderAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="d-flex flex-row justify-content-center mt-5">
      <Lottie
        options={defaultOptions}
        height={200}
        width={200}
        style={{ marginTop: "70%" }}
      />
    </div>
  );
};

export default PageLoaderAnimation;
