import React from "react";
import ReactDOM from "react-dom";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";

import App from "./App";
import "./style/index.scss";

const isProduction = process.env.NODE_ENV === "production";

ReactDOM.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={isProduction}
    isVerboseMode={true}
    loadingComponent={null}
  >
    <App />
  </CacheBuster>,
  document.getElementById("root")
);
