import { Fragment, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import moment from "moment";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import Paginator from "../../components/Paginator/Paginator.component";
import usePendingDealSlipList from "../../custom-hooks/usePendingDealSlips";
import { attachClassNameToTag } from "../../helpers/attachClassNameToTag";
import { formatMoney } from "../../helpers/formatter";
import { appInsights } from "../../components/AppInsight/AppInsight";

function PendingDealSlips() {
  const [Type] = useState("Aggregator");
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(20);

  const history = useHistory();

  const { data, status, error } = usePendingDealSlipList({
    Page: Page,
    PageSize: PageSize,
    Type: Type,
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "PendingDealSlip.jsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <h3 className="page-title">Pending Deal Slips</h3>
        </div>
      </div>
      <div className="card">
        <div className="px-3 pt-4">
          <Fragment>
            <div className="row table-responsive">
              <div className="bg-white">
                <div>
                  {error && <p className="alert alert-danger small">{error}</p>}
                  {status === "loading" ? (
                    <div className="d-flex justify-content-center p-5">
                      <PageLoader />
                    </div>
                  ) : (
                    !error &&
                    status !== "loading" && (
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="page-subtitle"></div>
                          <div className="d-flex align-items-center">
                            <p className="pr-2">Showing:</p>
                            <CustomSelectDropdown
                              onChange={(e) => setPageSize(e.target.value)}
                              value={PageSize}
                            >
                              <option value={10}>10 Entries</option>
                              <option value={20}>20 Entries</option>
                              <option value={50}>50 Entries</option>
                              <option value={100}>100 Entries</option>
                            </CustomSelectDropdown>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table">
                            <thead className="bg-lighter-grey">
                              <tr>
                                <th>Reference</th>
                                <th>Borrower Name</th>
                                <th>Business Name</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data && data.data?.data?.length ? (
                                data.data?.data?.map((data, id) => {
                                  const {
                                    loan_ref,
                                    datetime_created,
                                    status,
                                    loan_id,
                                    biz_name,
                                    loan_amount,
                                    first_name,
                                    last_name,
                                  } = data;
                                  return (
                                    <tr key={id}>
                                      <td>{loan_ref}</td>
                                      <td>{`${first_name ? first_name : ""} ${
                                        last_name ? last_name : ""
                                      }`}</td>
                                      <td>{biz_name}</td>
                                      <td>{formatMoney(loan_amount, "NGN")}</td>
                                      <td style={{ minWidth: "120px" }}>
                                        {moment(datetime_created).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>

                                      <td style={{ minWidth: "120px" }}>
                                        <span
                                          className={attachClassNameToTag(
                                            status
                                          )}
                                        >
                                          {status}
                                        </span>
                                      </td>
                                      <td
                                        className="color-mid-blue cursor-pointer"
                                        onClick={() =>
                                          history.push({
                                            pathname: `/deal-slip-status/${loan_id}/download`,
                                            state: {
                                              slipMetaData: {
                                                admin_uploaded_slip_filename:
                                                  data?.admin_uploaded_slip_filename,
                                                admin_uploaded_slip_path:
                                                  data?.admin_uploaded_slip_path,
                                                loan_id: data?.loan_id,
                                                deal_slip_id: data?.id,
                                                from: "pendingDealSlips",
                                                loan_ref: data?.loan_ref,
                                                deal_slip_comments:
                                                  data?.comments,
                                              },
                                            },
                                          })
                                        }
                                        style={{ minWidth: "120px" }}
                                      >
                                        View Details
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <NoTableDataAvailable />
                              )}
                            </tbody>
                          </table>
                        </div>
                        <Paginator
                          size={PageSize}
                          page={Page}
                          count={data?.data?.totalCount}
                          changeCurrentPage={(pageNumber) =>
                            setPage(pageNumber)
                          }
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
}
export default PendingDealSlips;
