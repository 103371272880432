import React, { useEffect, useState } from "react";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../components/Paginator/Paginator.component";
import usePendingLoansDetails from "../../custom-hooks/usePendingLoansDetails";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import EachPendingLoanRow from "../../components/EachPendingLoanRow/EachPendingLoanRow.component";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import BulkLoanCommentModal from "./BulkLoanCommentModal.component";
import { errorHandler } from "../../helpers/errorHandler";
import Alert from "./../../components/Alert/Alert.component";
import SingleLoanCommentModal from "./SingleLoanCommentModal.component";
import { appInsights } from "../../components/AppInsight/AppInsight";

export default function PendingLoans() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [bulkLoanCommentType, setBulkLoanCommentType] = useState("");
  const [singleLoanToActionOn, setSingleLoanToActionOn] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [searchValue, setSearchValue] = useState("");
  const [dataToStore, setDataToStore] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  // eslint-disable-next-line
  const [checked, setChecked] = useState(false);

  const { data, status, error: loansError } = usePendingLoansDetails();

  //single checkbox
  const checkboxHandler = ({ eventTarget }) => {
    const loanToken = eventTarget.dataset["ref"];
    setChecked(true);
    if (eventTarget.checked === false) {
      const checkedPendingDisbursementData = dataToStore;
      const newCheckedPendingDisbursementData =
        checkedPendingDisbursementData.filter((loan) => {
          return loan !== loanToken;
        });

      setDataToStore([...newCheckedPendingDisbursementData]);
    } else {
      setDataToStore((dataToStore) => [...dataToStore, loanToken]);
    }
  };

  // Multiple Checkbox
  const checkAllPendingDisbursementData = (e) => {
    setCheckAll(e);
    if (checkAll) {
      const allLoans = document.querySelectorAll(".loans_for_disbursement");
      for (let i = 0; i < allLoans.length; i++) {
        allLoans[i].checked = false;
      }
      setCheckAll(false);
      setDataToStore([]);
      return;
    }

    let loans = [];
    const allLoans = document.querySelectorAll(".loans_for_disbursement");
    for (let i = 0; i < allLoans.length; i++) {
      allLoans[i].checked = true;
      const loanRef = allLoans[i].dataset["ref"];
      loans.push(loanRef);
    }
    setCheckAll(true);

    setDataToStore([...dataToStore, ...loans]);
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "PendingLoans.jsx",
      isLoggedIn: true,
    });
  }, []);
  if (status === "loading") return <PageLoader />;
  return (
    <div>
      {success && <Alert message={success} type="success" />}
      {error && <Alert message={error} />}
      {loansError && (
        <p className="alert alert-danger small w-100">
          {errorHandler(loansError)}
        </p>
      )}
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h3 className="page-title mb-4">Loan Requests</h3>
        </div>
        <div className="d-flex align-items-center mb-2">
          <div className="input-group mb-3">
            <input
              type="text"
              className="mr-0 search-query form-control"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <div className="input-group-append">
              <span className="input-group-text bg-white">
                <span className="fa fa-search"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body p-3">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="page-subtitle">Loan Request List</h5>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <p className="pr-2 pt-1">Showing:</p>
                <CustomSelectDropdown
                  onChange={(e) => setSize(e.target.value)}
                  value={size}
                >
                  <option value={10}>10 Entries</option>
                  <option value={20}>20 Entries</option>
                  <option value={50}>50 Entries</option>
                  <option value={100}>100 Entries</option>
                </CustomSelectDropdown>
              </div>
              <div className="pl-3 pb-2">
                <button
                  type="button"
                  className="btn btn-primary btn-sm d-flex align-items-center"
                  onClick={() => setBulkLoanCommentType("approve")}
                  data-toggle="modal"
                  data-target="#bulkLoanCommentModal"
                  disabled={Object.keys(dataToStore).length < 1 || loading}
                >
                  Approve Loan
                </button>
              </div>
              <div className="pl-1 pb-2">
                <button
                  type="button"
                  className="btn btn-danger btn-sm d-flex align-items-center ml-2"
                  onClick={() => setBulkLoanCommentType("decline")}
                  data-toggle="modal"
                  data-target="#bulkLoanCommentModal"
                  disabled={Object.keys(dataToStore).length < 1 || loading}
                >
                  Decline Loan
                </button>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      name="unchecked"
                      onClick={(e) =>
                        checkAllPendingDisbursementData({
                          eventTarget: e.target.checked,
                        })
                      }
                      checked={checkAll}
                    />
                  </th>
                  <th>Reference</th>
                  <th>First Name</th>
                  <th>Second Name</th>
                  <th>Product</th>
                  <th>Amount</th>
                  <th>Interest</th>
                  <th>Tenure</th>
                  <th>Submitted Date</th>
                  <th>Manage</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length ? (
                  data
                    .filter(
                      (borrower) =>
                        borrower.state_token
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        borrower.borrower_details.first_name
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        borrower.borrower_details.last_name
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        borrower.product_name
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        borrower.amount
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        borrower.loan_tenure
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        borrower.amount_interest
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                    )
                    .slice(
                      (pageNumber - 1) * size,
                      size * (pageNumber - 1) + size
                    )
                    .map((data, id) => {
                      return (
                        <EachPendingLoanRow
                          key={id}
                          data={data}
                          checkboxHandler={checkboxHandler}
                          setSingleLoanToActionOn={setSingleLoanToActionOn}
                          loading={loading}
                          checked={checked}
                        />
                      );
                    })
                ) : (
                  <NoTableDataAvailable />
                )}
              </tbody>
            </table>
          </div>
          <Paginator
            size={size}
            page={pageNumber}
            count={data ? data.length : 0}
            changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
          />
        </div>
      </div>
      <BulkLoanCommentModal
        loading={loading}
        setLoading={setLoading}
        setDataToStore={setDataToStore}
        setChecked={setChecked}
        setCheckAll={setCheckAll}
        dataToStore={dataToStore}
        commentType={bulkLoanCommentType}
        setSuccess={setSuccess}
        setError={setError}
      />
      <SingleLoanCommentModal
        loading={loading}
        setLoading={setLoading}
        setSuccess={setSuccess}
        setError={setError}
        singleLoanToActionOn={singleLoanToActionOn}
      />
    </div>
  );
}
