import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
import { encrypt } from "./encryptor";

const getAggregatorDetails = async () => {
  const { data } = await getDataWithDotNet(
    apiEndpoints.getAggregatorDetails,
    true
  );
  return data;
};

const getEligibilityStatus = async (aggregator_id: number) => {
  const { data } = await getDataWithDotNet(
    `${apiEndpoints.getEligibilityStatus}?aggregator_id=${aggregator_id}`,
    true
  );
  return data;
};

export const setLoginInfo = async () => {
  const aggregatorResult = await getAggregatorDetails();
  const eligibilityResult = await getEligibilityStatus(
    aggregatorResult.aggregator_id
  );
  sessionStorage.setItem("_aggDt", encrypt(JSON.stringify(aggregatorResult)));
  sessionStorage.setItem("_aggEd", encrypt(JSON.stringify(eligibilityResult)));

  return { aggregatorResult, eligibilityResult };
};
