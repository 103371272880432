import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getAllInvestments = async ({
  pageNumber,
  size,
  currency,
  startDate,
  endDate,
  searchValue,
  FunderId,
  aggregatorId,
}) => {
  let queryParams = `?Page=${pageNumber}&PageSize=${size}`;
  if (currency) {
    queryParams = `${queryParams}&Currency=${currency}`;
  }
  if (startDate) {
    queryParams = `${queryParams}&from_date=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&to_date=${endDate}`;
  }
  if (searchValue) {
    queryParams = `${queryParams}&FunderName=${searchValue}`;
  }
  if (FunderId) {
    queryParams = `${queryParams}&FunderId=${FunderId}`;
  }
  if (aggregatorId) {
    queryParams = `${queryParams}&AggregatorId=${aggregatorId}`;
  }
  const data = await getData(`${apiEndpoints.getAllInvestments}${queryParams}`);

  return data;
};

export default function useAllInvestments({
  pageNumber,
  size,
  currency,
  startDate,
  endDate,
  searchValue,
  FunderId,
  aggregatorId,
}) {
  return useQuery(
    [
      {
        pageNumber,
        size,
        currency,
        startDate,
        endDate,
        searchValue,
        FunderId,
        aggregatorId,
      },
      "getAllInvestments",
    ],
    getAllInvestments,
    {
      enabled: aggregatorId ? true : false,
      refetchOnWindowFocus: false,
    }
  );
}
