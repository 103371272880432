import React, { useEffect, useState } from "react";
import styles from "../../GetStarted.module.scss";
import CustomTextArea from "./../../../../components/CustomHTMLElements/CustomTextArea";
import CustomInputField from "./../../../../components/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "./../../../../components/CustomHTMLElements/CustomSelectDropdown";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { errorHandler } from "./../../../../helpers/errorHandler";
import { yupValidators } from "./../../../../helpers/yupValidators";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { postDataWithDotNet } from "../../../../apis/dotNetApiMethods";
import { useHistory } from "react-router";
import Loader from "../../../../components/Loader/Loader.component";
import { useQueryCache } from "react-query";
import { decrypt } from "../../../../helpers/encryptor";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import useCountryCodesLive from "../../../../custom-hooks/useCountryCodesLive";
import useBusinessSectorsLive from "../../../../custom-hooks/useBusinessSectorsLive";
import { Link } from "react-router-dom";
import OnboardingBackWordButton from "../../../../components/OnboardingBackWordButton/OnboardingBackWordButton";
import advanclyLogo from "../../../../img/advancly_logo.svg";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  businessName: yupValidators.businessName,
  businessEmail: yupValidators.email,
  businessPhoneNumber: yupValidators.phoneNumber,
  businessSector: yupValidators.businessCategory,
  countryCode: yupValidators.countryCode,
  businessAddress: yupValidators.businessAddress,
  businessPurpose: yupValidators.businessPurpose,
  registrationNumber: yupValidators.registrationNumber,
  shortName: yupValidators.shortName,
  dialCode: yupValidators.dialCode,
});

export default function GetStartedBusinesInfo() {
  // const { aggregatorDetails } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [count, setCount] = useState(0);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState("+234");
  const [currentPhoneDialCode, setCurrentPhoneDialCode] = useState("");

  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const aggregatorDetails = JSON.parse(
    decrypt(sessionStorage.getItem("_aggDt"))
  );
  const {
    biz_name,
    sector_id,
    country_code,
    rep_personal_phone,
    rep_phone_dialcode,
  } = aggregatorDetails;
  const [businessSector, setBusinessSector] = useState(sector_id);
  const [countryCode, setCountryCode] = useState(country_code);
  useEffect(() => {
    setCurrentPhoneNumber(rep_personal_phone);
    setCurrentPhoneDialCode(rep_phone_dialcode);
  }, [rep_personal_phone, rep_phone_dialcode]);

  const {
    data: sectorsData,
    // status: sectorsStatus,
    error: sectorsError,
  } = useBusinessSectorsLive();
  const {
    data: countryCodes,
    error: countryCodesError,
    // status: countryCodesStatus,
  } = useCountryCodesLive();
  const queryCache = useQueryCache();

  const onSubmit = async ({
    businessAddress,
    businessEmail,
    businessName,
    businessPhoneNumber,
    businessPurpose,
    countryCode,
    registrationNumber,
    shortName,
    businessSector,
    dialCode,
  }) => {
    setLoading(true);
    setError(false);
    const req = {
      business_name: businessName,
      business_email: businessEmail,
      business_phone: businessPhoneNumber,
      sector_id: Number(businessSector),
      country_code: countryCode,
      business_address: businessAddress,
      business_description: businessPurpose,
      registration_number: registrationNumber,
      short_name: shortName,
      business_phone_dialcode: dialCode,
    };
    try {
      const res = await postDataWithDotNet(
        apiEndpoints.addAggregatorBusinessInfo,
        req,
        true
      );
      setLoading(false);
      if (res.status === true) {
        queryCache.invalidateQueries([
          aggregatorDetails.aggregator_id,
          "getEligibilityStatus",
          true,
        ]);
        history.push({
          pathname: "/get-started/onboarding",
          state: {
            successMessage: "Your account has been updated successfully",
          },
        });
      } else {
        setError(errorHandler(res));
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "GetStartedBusinessInfo.jsx",
        },
      });
      setLoading(false);
      if (error.response && error.response.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        setError(errorHandler(error));
      }
    }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "GetStartedBusinesInfo.jsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className={`ml-5 mt-5  `}>
      <div className={styles.onboardingLogoLink}>
        <Link className={styles.sparkLogoLink} to="/">
          <img
            className={styles.sparkLogo}
            src={advanclyLogo}
            alt="Login logo"
          />
        </Link>

        <OnboardingBackWordButton />
      </div>
      <div className={styles.newGetStartedInnerBusinessComponent}>
        <div className="d-flex justify-content-between align-items-center w-100 mb-4 mt-5">
          <h3 className="page-title mb-0"> Business Information</h3>
          <small>Step 1 of 3</small>
        </div>
        {error && <p className="alert alert-danger small">{error}</p>}
        {sectorsError && (
          <p className="alert alert-danger small">
            {errorHandler(sectorsError)}
          </p>
        )}
        {countryCodesError && (
          <p className="alert alert-danger small mb-0">
            {errorHandler(countryCodesError)}
          </p>
        )}
        <div className="">
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-6">
                  <CustomInputField
                    type="text"
                    maxLength="30"
                    reference={register}
                    placeholder="Enter Business Name"
                    label="Business Name"
                    name="businessName"
                    defaultValue={biz_name}
                    errors={errors.businessName}
                    showRequiredIcon={true}
                  />
                </div>

                <div className="col-6">
                  <CustomInputField
                    type="email"
                    maxLength="128"
                    reference={register}
                    placeholder="Enter Business Email"
                    label="Business Email Address"
                    name="businessEmail"
                    errors={errors.businessEmail}
                    showRequiredIcon={true}
                  />
                </div>

                <div className="col-2">
                  <CustomSelectDropdown
                    label="Business"
                    reference={register}
                    name="dialCode"
                    defaultValue={currentPhoneDialCode}
                    value={currentPhoneDialCode}
                    onChange={(e) => setCurrentPhoneDialCode(e.target.value)}
                    errors={errors.personal_phone || errors.dialCode}
                    style={{ borderRadius: "10px" }}
                  >
                    {" "}
                    <option value="">Choose</option>
                    {countryCodes?.map(({ dial_code, country_code }) => {
                      return (
                        <option value={dial_code} key={dial_code}>
                          {`${getUnicodeFlagIcon(country_code)} ${dial_code}`}
                        </option>
                      );
                    })}
                  </CustomSelectDropdown>
                </div>
                <div className="col-4">
                  <CustomInputField
                    type="text"
                    maxLength="20"
                    reference={register}
                    label=" Phone Number"
                    placeholder="Enter Business Phone Number"
                    name="businessPhoneNumber"
                    value={currentPhoneNumber}
                    onChange={(e) => setCurrentPhoneNumber(e.target.value)}
                    errors={errors.businessPhoneNumber || errors.dialCode}
                    showRequiredIcon={true}
                  ></CustomInputField>
                </div>
                <div className="col-12 col-lg-6">
                  <CustomSelectDropdown
                    reference={register}
                    label="What Sector is your business in?"
                    name="businessSector"
                    errors={errors.businessSector}
                    showRequiredIcon={true}
                    value={businessSector}
                    onChange={(e) => setBusinessSector(e.target.value)}
                  >
                    <option value="">Select Sector</option>
                    {sectorsData && sectorsData.length
                      ? sectorsData.map((sector) => {
                          const { category_id, category_name } = sector;
                          return (
                            <option key={category_id} value={category_id}>
                              {category_name}
                            </option>
                          );
                        })
                      : null}
                  </CustomSelectDropdown>
                </div>
                <div className="col-12 col-lg-6">
                  <CustomSelectDropdown
                    reference={register}
                    name="countryCode"
                    label="What country is your business located?"
                    errors={errors.countryCode}
                    showRequiredIcon={true}
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                  >
                    <option value="">Choose Country</option>
                    {countryCodes &&
                      countryCodes.map((country, index) => {
                        const { country_code, name } = country;
                        return (
                          <option value={country_code} key={index}>
                            {name}
                          </option>
                        );
                      })}
                  </CustomSelectDropdown>
                </div>
                <div className="col-6">
                  <CustomInputField
                    type="text"
                    maxLength="256"
                    reference={register}
                    placeholder="Enter Business Address"
                    label="Business Physical Address"
                    name="businessAddress"
                    errors={errors.businessAddress}
                    showRequiredIcon={true}
                  />
                </div>

                <div className="col-6">
                  <CustomInputField
                    type="text"
                    maxLength="30"
                    reference={register}
                    placeholder="Enter Business Name"
                    label="Business Short Name"
                    name="shortName"
                    errors={errors.shortName}
                    showRequiredIcon={true}
                  />
                </div>
                <div className="col-6">
                  <CustomInputField
                    type="text"
                    maxLength="256"
                    reference={register}
                    placeholder="Business  Registration number"
                    label="Business Registration number (e.g. RC Number)"
                    name="registrationNumber"
                    errors={errors.registrationNumber}
                    showRequiredIcon={true}
                  />
                </div>
                <div className="col-6">
                  <CustomTextArea
                    maxLength="200"
                    reference={register}
                    placeholder="Business Purpose"
                    label={`What does your business do? `}
                    name="businessPurpose"
                    errors={errors.businessPurpose}
                    showRequiredIcon={true}
                    onChange={(e) => setCount(e.target.value.length)}
                  />
                  <span className="d-flex justify-content-end color-blue-hover-none">
                    {count}/200
                  </span>
                </div>

                <div className="col-6">
                  <div className=" d-flex justify-content-start flex-wrap">
                    <button
                      className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                      type="submit"
                      disabled={loading}
                    >
                      Save
                      {loading && <Loader />}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
