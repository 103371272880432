import React, { Fragment, useEffect, useState } from "react";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../components/Paginator/Paginator.component";
import { formatMoney } from "../../helpers/formatter";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
// import Loader from "../../components/Loader/Loader.component";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import { appInsights } from "../../components/AppInsight/AppInsight";

function DueLoans({
  state,
  setState,
  data,
  pageNumber,
  size,
  error,
  setPageNumber,
  setSize,
  status,
  checkAll,
  setCheckAll,
  searchCurrency,
  setCurrency,
}) {
  const [count, setCount] = useState(null);
  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }
  // Multiple Checkbox
  const checkAllPendingRepaymentData = (e) => {
    if (checkAll) {
      const allLoans = document.querySelectorAll(".loans_for_repayment");
      for (let i = 0; i < allLoans.length; i++) {
        allLoans[i].checked = false;
      }
      setCheckAll(false);
      setState({
        ...state,
        totalAmount: 0,
        dataToStore: [],
      });

      return;
    }

    let totalAmount = 0;
    let loans = [];
    const allLoans = document.querySelectorAll(".loans_for_repayment");
    for (let i = 0; i < allLoans.length; i++) {
      allLoans[i].checked = true;
      const loan_Id = Number(allLoans[i].dataset["ref"]);
      const amount = Number(allLoans[i].dataset["amounttorepay"]);
      const duedate = Number(allLoans[i].dataset["duedate"]);
      loans.push({ loan_Id, amount, duedate });
      totalAmount += Number(
        JSON.parse(allLoans[i].dataset["record"]).amountToRepay
      );
    }

    setCheckAll(true);

    setState({
      ...state,
      totalAmount,
      dataToStore: [...state.dataToStore, ...loans],
    });
  };
  // Single Checkbox
  const checkboxHandler = ({ dataRecord: { amountToRepay }, eventTarget }) => {
    let loans = [];
    const loan_Id = Number(eventTarget.dataset["ref"]);
    const amount = Number(eventTarget.dataset["amounttorepay"]);
    const duedate = Number(eventTarget.dataset["duedate"]);
    loans.push({ loan_Id, amount, duedate });
    if (eventTarget.checked === false) {
      const dataToStore = state.dataToStore;
      const newdataToStore = dataToStore.filter((loan) => {
        return (
          Number(loan.duedate) !== Number(duedate) ||
          Number(loan.loan_Id) !== Number(loan_Id)
        );
      });

      return setState({
        ...state,
        dataToStore: [...newdataToStore],
        totalAmount: state.totalAmount
          ? state.totalAmount - Number(amountToRepay)
          : 0,
      });
    } else {
      setState({
        ...state,
        dataToStore: [...state.dataToStore, ...loans],
        totalAmount: state.totalAmount + Number(amountToRepay),
      });
    }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "DueLoans.jsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      {error && <p className="alert alert-danger small">{error}</p>}

      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          {/* <h5 className="page-subtitle">
            Wallet Balance:{" "}
            {walletDetailsStatus === "loading" ? (
              <Loader />
            ) : (
              <Fragment>
                {walletDetails?.summary?.availableBalance
                  ? formatMoney(
                      walletDetails?.summary?.availableBalance,
                      walletDetails?.summary?.currency?.code
                    )
                  : "N/A"}{" "}
              </Fragment>
            )}
          </h5> */}

          <div className="d-flex align-items-center">
            <p className="pr-2 pt-1">Showing:</p>
            <CustomSelectDropdown
              onChange={(e) => setSize(e.target.value)}
              value={size}
            >
              <option value={10}>10 Entries</option>
              <option value={20}>20 Entries</option>
              <option value={50}>50 Entries</option>
              <option value={100}>100 Entries</option>
            </CustomSelectDropdown>
          </div>
          <CustomSelectDropdown
            name="currency"
            onChange={(e) => setCurrency(e.target.value)}
            value={searchCurrency}
          >
            {/* <option value="">Select Currency </option> */}
            <option value="NGN">Naira</option>
            <option value="USD">USD</option>
          </CustomSelectDropdown>
        </div>

        {status === "loading" ? (
          <div className="d-flex justify-content-center p-5">
            <PageLoader />
          </div>
        ) : (
          !error &&
          status !== "loading" && (
            <Fragment>
              <div className="table-responsive">
                <table className="table">
                  <thead className="bg-lighter-grey">
                    <tr>
                      {
                        // searchCurrency && (
                        <th>
                          <input
                            type="checkbox"
                            name="unchecked"
                            onClick={(e) => checkAllPendingRepaymentData(e)}
                            checked={checkAll}
                          />
                        </th>
                      }
                      <th>Loan Id</th>
                      <th>Reference</th>
                      <th>Borrower</th>
                      <th>Product</th>
                      <th>Outstanding Amount</th>
                      <th>Principal Amount</th>
                      <th>Interest</th>
                      <th>Overdue Days</th>
                      <th>Due Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.data?.length ? (
                      data?.data?.map((data, id) => {
                        const {
                          loanRef,
                          borrowerName,
                          productName,
                          amountToRepay,
                          dateDue,
                          principal,
                          currency,
                          interest,
                          daysOverdue,
                          loanId,
                        } = data;
                        return (
                          <tr key={id}>
                            {
                              // searchCurrency &&
                              <td>
                                <input
                                  type="checkbox"
                                  data-record={JSON.stringify(data)}
                                  data-id={Number(loanId)}
                                  data-ref={Number(loanId)}
                                  data-amounttorepay={Number(amountToRepay)}
                                  data-duedate={Number(daysOverdue)}
                                  defaultChecked={state?.dataToStore?.includes(
                                    Number(loanId)
                                  )}
                                  onClick={(e) =>
                                    checkboxHandler({
                                      dataRecord: data,
                                      eventTarget: e.target,
                                    })
                                  }
                                  className="loans_for_repayment"
                                />
                              </td>
                            }
                            <td>{loanId}</td>
                            <td>{loanRef}</td>
                            <td
                              className="text-uppercase"
                              style={{ width: "10%" }}
                            >
                              {borrowerName}
                            </td>
                            <td className="font-weight-semi-normal">
                              {productName}
                            </td>
                            <td>{formatMoney(amountToRepay, currency)}</td>
                            <td>
                              {principal
                                ? formatMoney(principal, currency)
                                : null}
                            </td>
                            <td>
                              {interest
                                ? formatMoney(interest, currency)
                                : null}
                            </td>
                            <td style={{ minWidth: "120px" }}>{daysOverdue}</td>
                            <td style={{ minWidth: "120px" }}>
                              {dateDue?.substring(0, 10)}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoTableDataAvailable />
                    )}
                  </tbody>
                </table>
              </div>
              <Paginator
                size={size}
                page={pageNumber}
                count={count}
                changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
              />
            </Fragment>
          )
        )}
      </div>
    </div>
  );
}
export default DueLoans;
