import React, { useState } from "react";
import useAggregatorSignatoryUsers from "../../../custom-hooks/useAggregatorSignatoryUsers";
import ChangeMultipleSignatoryModal1 from "./ChangeMultipleSignatoryModal1";
import ChangeMultipleSignatoryModal2 from "./ChangeMultipleSignatoryModal2";
import ChangeMultipleSignatoryModal3 from "./ChangeMultipleSignatoryModal3";

const ChangeMultipleSignatoryComponentPage = ({
  dismissRef,
  signatories,
  signatoriesStatus,
}) => {
  const [changeMultipleSignatory, setChangeMultipleSignatory] = useState(1);
  const [changeMultipleSignatoryName, setChangeMultipleSignatoryName] =
    useState();

  const [agreementTick, setAgreementTick] = useState();
  const step = 1;
  const {
    data: usersData,
    status: usersStatus,
    error: usersError,
  } = useAggregatorSignatoryUsers();
  return (
    <div>
      {(() => {
        switch (changeMultipleSignatory) {
          case 1:
            return (
              <ChangeMultipleSignatoryModal1
                usersData={usersData}
                usersStatus={usersStatus}
                usersError={usersError}
                changeMultipleSignatory={changeMultipleSignatory}
                setChangeMultipleSignatory={setChangeMultipleSignatory}
                setChangeMultipleSignatoryName={setChangeMultipleSignatoryName}
                step={step}
                signatories={signatories}
                signatoriesStatus={signatoriesStatus}
                dismissRef={dismissRef}
                changeMultipleSignatoryName={changeMultipleSignatoryName}
              />
            );

          case 2:
            return (
              <ChangeMultipleSignatoryModal2
                changeMultipleSignatory={changeMultipleSignatory}
                setChangeMultipleSignatory={setChangeMultipleSignatory}
                setAgreementTick={setAgreementTick}
                step={step}
                dismissRef={dismissRef}
              />
            );
          case 3:
            return (
              <ChangeMultipleSignatoryModal3
                changeMultipleSignatory={changeMultipleSignatory}
                setChangeMultipleSignatory={setChangeMultipleSignatory}
                changeMultipleSignatoryName={changeMultipleSignatoryName}
                agreementTick={agreementTick}
                step={step}
                dismissRef={dismissRef}
              />
            );

          default:
            return "";
        }
      })()}
    </div>
  );
};

export default ChangeMultipleSignatoryComponentPage;
