import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../components/Paginator/Paginator.component";
import moment from "moment";
import { useHistory, withRouter } from "react-router-dom";
import useRepaymentDetails from "../../custom-hooks/useRepaymentDetails";
import { formatMoney } from "../../helpers/formatter";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import { useEffect } from "react";
import { appInsights } from "../../components/AppInsight/AppInsight";

function RepaymentList({
  dueLoansPreState,
  setDueLoansPreState,
  dueLoansState,
  count,
}) {
  const { data, status, error } = useRepaymentDetails(dueLoansPreState);
  let history = useHistory();

  const handleClick = (props) => {
    history.push(`/repayments/${props}`);
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "RepaymentList.jsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div>
      {error && <p className="alert alert-danger small">{error}</p>}
      {status === "loading" ? (
        <div className="d-flex justify-content-center p-5">
          <PageLoader />
        </div>
      ) : (
        !error &&
        status !== "loading" && (
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div className="page-subtitle"></div>
              <div className="d-flex align-items-center">
                <p className="pr-2">Showing:</p>
                <CustomSelectDropdown
                  onChange={(e) =>
                    setDueLoansPreState({
                      ...dueLoansState,
                      size: e.target.value,
                    })
                  }
                  value={dueLoansPreState.size}
                >
                  <option value={10}>10 Entries</option>
                  <option value={20}>20 Entries</option>
                  <option value={50}>50 Entries</option>
                  <option value={100}>100 Entries</option>
                </CustomSelectDropdown>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead className="bg-lighter-grey">
                  <tr>
                    <th>Reference</th>
                    <th>Borrower</th>
                    <th>Product</th>
                    <th>Principal</th>
                    <th>Principal Paid</th>
                    <th>Interest</th>
                    <th>Interest Paid</th>
                    <th>Due Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length ? (
                    data.map((data, id) => {
                      const {
                        loanRef,
                        borrowerName,
                        productName,
                        // repayment_amount,
                        principal,
                        principalPaid,
                        interest,
                        interestPaid,
                        borrower_id,
                        loan_due_date,
                        currency,
                      } = data;
                      return (
                        <tr key={id}>
                          <td>{loanRef}</td>
                          <td
                            className="text-uppercase"
                            style={{ width: "10%" }}
                          >
                            {borrowerName}
                          </td>
                          <td className="font-weight-semi-normal">
                            {productName}
                          </td>
                          {/* <td>{formatMoney(repayment_amount, currency)}</td> */}
                          <td>{formatMoney(principal, currency)}</td>
                          <td>{formatMoney(principalPaid, currency)}</td>
                          <td>{formatMoney(interest, currency)}</td>
                          <td>{formatMoney(interestPaid, currency)}</td>

                          <td style={{ minWidth: "120px" }}>
                            {moment(loan_due_date).format("DD-MM-YYYY")}
                          </td>
                          <td
                            className="color-mid-blue cursor-pointer"
                            onClick={() => handleClick(borrower_id)}
                            style={{ minWidth: "120px" }}
                          >
                            View Details
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoTableDataAvailable />
                  )}
                </tbody>
              </table>
            </div>
            <Paginator
              size={dueLoansPreState.size}
              page={dueLoansPreState.pageNumber}
              count={count}
              changeCurrentPage={(pageNumber) =>
                setDueLoansPreState({ ...dueLoansState, pageNumber })
              }
            />
          </div>
        )
      )}
    </div>
  );
}
export default withRouter(RepaymentList);
