import React from "react";

const SingleSignatoryModalStep1 = ({
  setSingleSignatory,
  setSinglesSignatoryName,
  step,
  singleSignatory,
  setSignatoryType,
}) => {
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Single Signatory Approval</h4>
        <button type="button" className="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div className="modal-body">
        <small
          className="color-mid-blue text-right  row cursor-pointer font-weight-bold pl-2 "
          onClick={() => {
            setSignatoryType("");
          }}
        >
          &#8592; Go back
        </small>
        <div>
          <p className="p-5 d-flex justify-content-center">
            You haven’t created users to choose <br /> a signatory from
          </p>
          <button
            className="btn advancly-btn btn-sm w-100 mt-3"
            onClick={() => {
              setSingleSignatory(singleSignatory + step);
            }}
          >
            Create User
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleSignatoryModalStep1;
