import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getRepaymentDetails = async ({ loanReference, fromDate, toDate,pageNumber ,size}) => {
  let queryParams = `?Page=${pageNumber}&PageSize=${size}`;
  if (loanReference) {
    queryParams = `${queryParams}&LoanReference=${loanReference}`;
  }
  if (fromDate) {
    queryParams = `${queryParams}&FromDate=${fromDate}`;
  }
  if (toDate) {
    queryParams = `${queryParams}&ToDate=${toDate}`;
  }
  const data = await getDataWithDotNet(
    `${apiEndpoints.getAllRepaymentsReport}${queryParams && queryParams}`
  );
  return data.data;
};

export default function useRepaymentDetails({ loanReference, fromDate, toDate,pageNumber ,size }) {
  return useQuery(
    [{ loanReference, fromDate, toDate,pageNumber ,size }, "getRepaymentDetails"],
    getRepaymentDetails,
    {
      refetchOnWindowFocus: false,
    }
  );
}
