import React, { useState } from "react";
import MultipleSignatoryModalStep1 from "./MultipleSignatoryModalStep1";
import MultipleSignatoryModalStep2 from "./MultipleSignatoryModalStep2";
import MultipleSignatoryModalStep3 from "./MultipleSignatoryModalStep3";

const MultipleSignatoryLandingPage = ({ setSignatoryType, dismissRef }) => {
  const [multipleSignatoryName, setMultipleSignatoryName] = useState("");
  const [agreementTick, setAgreementTick] = useState();
  const [multipleSignatory, setMultipleSignatory] = useState(1);
  const step = 1;
  return (
    <div>
      {(() => {
        switch (multipleSignatory) {
          case 1:
            return (
              <MultipleSignatoryModalStep1
                multipleSignatory={multipleSignatory}
                step={step}
                setMultipleSignatory={setMultipleSignatory}
                setMultipleSignatoryName={setMultipleSignatoryName}
                setSignatoryType={setSignatoryType}
                dismissRef={dismissRef}
              />
            );

          case 2:
            return (
              <MultipleSignatoryModalStep2
                multipleSignatory={multipleSignatory}
                step={step}
                setMultipleSignatory={setMultipleSignatory}
                setAgreementTick={setAgreementTick}
                dismissRef={dismissRef}
              />
            );
          case 3:
            return (
              <MultipleSignatoryModalStep3
                multipleSignatory={multipleSignatory}
                step={step}
                setMultipleSignatory={setMultipleSignatory}
                multipleSignatoryName={multipleSignatoryName}
                agreementTick={agreementTick}
                dismissRef={dismissRef}
              />
            );

          default:
            return "";
        }
      })()}
    </div>
  );
};

export default MultipleSignatoryLandingPage;
