import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import useAllCustomerWallets from "../../custom-hooks/useAllCustomerWallets";
import NoTableDataAvailable from "../NoTableDataAvailable/NoTableDataAvailable.component";
import PageLoader from "../PageLoader/PageLoader.component";
import Paginator from "../Paginator/Paginator.component";

const CustomerWallet = ({ match, profileDetails }) => {
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    consumerType: "Customer",
    targetId: profileDetails.customer_id,
  });

  const [count, setCount] = useState(null);
  const { data, status, error } = useAllCustomerWallets(preState);
  if (data) {
    if (count === null) {
      setCount(data?.totalCount);
    }
  }
  return (
    <div>
      {error && <p className="alert alert-danger small">{error}</p>}
      {!error && (
        <div>
          <div className="card">
            {status === "loading" ? (
              <div className="card-body p-3">
                <PageLoader />
              </div>
            ) : (
              <div className="card-body p-3">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="bg-lighter-grey">
                      <tr>
                        <th>S/N</th>
                        <th>Account Name</th>
                        <th>Account Number</th>
                        <th>Bank Name</th>
                        <th>Currency</th>
                        <th>Is Wallet Default</th>

                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.data ? (
                        data?.data?.map((request, _id) => {
                          const {
                            accountNumber,
                            bankName,
                            currency,
                            walletId,
                            accountName,
                            isDefault,
                          } = request;
                          return (
                            <tr key={_id}>
                              <td>{_id + 1}</td>
                              {/* <td>{walletId}</td> */}
                              <td>{accountName}</td>
                              <td>{accountNumber}</td>
                              <td>{bankName}</td>
                              <td>{currency}</td>
                              <td>{isDefault ? "True" : "False"}</td>
                              {/* <td>{walletProvider}</td> */}

                              <td>
                                <Link
                                  to={{
                                    pathname: `/wallet/customer/${walletId}`,
                                    state: {
                                      data: request,
                                      consumerType: preState.consumerType,
                                    },
                                  }}
                                  className="color-blue"
                                >
                                  View Details
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
                {data && data?.data && (
                  <Paginator
                    size={preState.size}
                    page={preState.pageNumber}
                    count={count}
                    changeCurrentPage={(pageNumber) =>
                      setPreState({ ...preState, pageNumber })
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(CustomerWallet);
