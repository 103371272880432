import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getWorldCountries = async () => {
  const { data } = await getData(apiEndpoints.WORLD_COUNTRY_CODES);
  return data;
};

export default function useWorldCountries() {
  return useQuery(["getWorldCountries"], getWorldCountries, {
    refetchOnWindowFocus: false,
  });
}
