import styles from "./InvestorsInvestment.module.scss";
import useInvestorInvestmentSummary from "../../../custom-hooks/useInvestorInvestmentSummary";
import { formatMoney } from "../../../helpers/formatter";
import { resolveCurrencyCodeBadges } from "../../../helpers/resolveBadges";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader/Loader.component";

const InvestorsInvestment = ({ id }) => {
  const { data, status, error } = useInvestorInvestmentSummary(id);

  return (
    <div>
      {status === "loading" ? (
        <Loader centered="true" text="Loading ..." />
      ) : error ? (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h6 className="center-txt py-5">
              <i className="fas fa-bell" /> Unable to Fetch Investor's
              Investment
            </h6>
          </div>
        </div>
      ) : data && status !== "loading" ? (
        <div className="datatable-wrapper">
          <div className=" speed-1x animated fadeInRight">
            {/*Datatable || Loader || Error Message*/}
            <div className="row mt-4 px-3">
              <div className="col-md-3 d-flex flex-column">
                <div className={styles.rightBorder}>
                  <div className={styles.investmentTitle}>
                    Total Amount Invested (Naira)
                  </div>
                  <div className={styles.investmentSubTitle}>
                    {formatMoney(data?.total_amount_invested_naira, "#")}
                  </div>
                </div>
              </div>
              <div className="col-md-3 d-flex flex-column">
                <div className={styles.rightBorder}>
                  <div className={styles.investmentTitle}>
                    Total Amount Invested (Dollar)
                  </div>
                  <div className={styles.investmentSubTitle}>
                    {formatMoney(data?.total_amount_invested_usd, "$")}
                  </div>
                </div>
              </div>
              <div className="col-md-3 d-flex flex-column">
                <div className={styles.rightBorder}>
                  <div className={styles.investmentTitle}>Naira Wallet</div>
                  <div className={styles.investmentSubTitle}>
                    {formatMoney(data?.naira_wallet_balance, "#")}
                  </div>
                </div>
              </div>
              <div className="col-md-3 d-flex flex-column">
                <div className={styles.rightBorder}>
                  <div className={styles.investmentTitle}>Dollar Wallet</div>
                  <div className={styles.investmentSubTitle}>
                    {formatMoney(data?.usd_wallet_balance, "$")}
                  </div>
                </div>
              </div>
            </div>
            <h5 className={`${styles.portfolio} mb-3 px-3`}>Portfolio</h5>
            <div className="row">
              {data &&
              data?.funder_investment_products &&
              data?.funder_investment_products.length > 0 ? (
                data?.funder_investment_products?.map((request, _id) => {
                  const {
                    investment_product_name,
                    investment_product_id,
                    investor_lcy_currency_code,
                    investor_usd_currency_code,
                  } = request;
                  const badges = resolveCurrencyCodeBadges([
                    { code: investor_usd_currency_code },
                    { code: investor_lcy_currency_code },
                  ]);
                  return (
                    <div
                      className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                      key={investment_product_id}
                    >
                      <div
                        className={`${styles.investmentLayoutCard} zoom-element`}
                      >
                        <div className={styles.title}>
                          {investment_product_name}
                        </div>
                        <div className={styles.content}>
                          <small className="color-grey mb-2">
                            {investor_lcy_currency_code &&
                            investor_usd_currency_code
                              ? "Dollar and Naira Fund"
                              : investor_lcy_currency_code &&
                                !investor_usd_currency_code
                              ? "Naira Fund"
                              : !investor_lcy_currency_code &&
                                investor_usd_currency_code
                              ? "Dollar Fund"
                              : null}
                          </small>
                        </div>
                        <div className="currency-badges">
                          {badges.map((item, i) => (
                            <div style={{ zIndex: badges.length - i }} key={i}>
                              {item}
                            </div>
                          ))}
                        </div>
                        <hr />
                        <div className={styles.link}>
                          <Link
                            to={{
                              pathname: `/investor/${id}/investment`,
                              state: {
                                id,
                                data,
                                investment_product_id,
                                investment_product_name,
                              },
                            }}
                            className="color-blue"
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : !data.funder_investment_products ||
                data?.funder_investment_products?.length === 0 ? (
                <div>
                  <div className="grid__padding animated fadeInRight">
                    <h4 className="center-txt py-5">
                      <i className="fas fa-bell" /> There are currently no
                      Investment
                    </h4>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="grid__padding animated fadeInRight">
                    <h4 className="center-txt py-5">
                      <i className="fas fa-bell" /> There are currently no
                      Investment
                    </h4>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt py-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              investors's investment, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestorsInvestment;
