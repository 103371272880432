import React from "react";
import DashboardCard from "../../components/DashboardCard/DashboardCard.component";
import { formatMoneyNoDecimal } from "../../helpers/formatterNoDecimal";
import { ReactComponent as InvestmentIcon } from "../../svg/icons/TotalInvestmentIcon.svg";
// import { ReactComponent as EarningIcon } from "../../svg/icons/TotalEarningsIcon.svg";
import { ReactComponent as InvestorIcon } from "../../svg/icons/InvestorsIcon.svg";
import { ReactComponent as InvestmentCountIcon } from "../../svg/icons/InvestmentCountIcon.svg";
import { InvestmentDashboardGraph } from "../../components/DashboardGraphs/InvestmentDashboardGraph";

const InvestmentDashboardTab = ({
  currentCurrency,
  activeProductName,
  investmentOverview,
  investmentOverviewStatus,
}) => {
  return (
    <div>
      {/* DASHBOARD INVESTMENTS */}
      <div className="card-deck d-block d-lg-flex card-lg-gutters-4 mb-4">
        <DashboardCard
          text="Total Investment Amount"
          value={
            investmentOverview &&
            formatMoneyNoDecimal(
              Math.trunc(investmentOverview?.total_investment_amount),
              currentCurrency
            )
          }
          status={investmentOverviewStatus}
          activeDefaultColor={true}
        >
          {" "}
          <InvestmentIcon />
        </DashboardCard>
        {/* <DashboardCard
          text="Total earnings"
          value={
            transactionsOverview &&
            formatMoneyNoDecimal(
              transactionsOverview?.total_repayment,
              transactionsOverview?.currency
            )
          }
          status={trasactionOverviewStatus}
        >
          <EarningIcon />
        </DashboardCard> */}
        <DashboardCard
          text="Total investors"
          value={
            investmentOverview?.total_investors >= 1
              ? investmentOverview?.total_investors
              : 0
          }
          status={investmentOverviewStatus}
        >
          <InvestorIcon />
        </DashboardCard>
        <DashboardCard
          text="Investment count"
          value={
            investmentOverview?.investment_count >= 1
              ? investmentOverview?.investment_count
              : 0
          }
          status={investmentOverviewStatus}
        >
          <InvestmentCountIcon />
        </DashboardCard>
      </div>
      <div className="horizontal-line-thin"></div>
      <InvestmentDashboardGraph
        currentCurrency={currentCurrency}
        activeProductName={activeProductName}
      />

      {/* DASHBOARD INVESTMENTS */}
    </div>
  );
};

export default InvestmentDashboardTab;
