import React, { Fragment, useState } from "react";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import { useHistory } from "react-router-dom";
import Loader from "../Loader/Loader.component";
import { errorHandler } from "../../helpers/errorHandler";
import CustomTextArea from "../CustomHTMLElements/CustomTextArea";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { putDataWithDotNet } from "../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import useBanksList from "../../custom-hooks/useBanksList";
import useAggregatorProducts from "../../custom-hooks/useAggregatorProducts";
import PageLoader from "../PageLoader/PageLoader.component";
import moment from "moment";
import { loanRequestSchema } from "../../helpers/loanRequestSchema";
import useBusinessSectors from "../../custom-hooks/useBusinessSectors";
import { numberRegExp } from "../../helpers/yupValidators";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  firstName: loanRequestSchema.firstName,
  lastName: loanRequestSchema.lastName,
  emailAddress: loanRequestSchema.email,
  gender: loanRequestSchema.borrowerGender,
  idNumber: loanRequestSchema.idNumber,
  phoneNumber: loanRequestSchema.borrowerPhoneNumber,
  city: loanRequestSchema.borrowerCity,
  state: loanRequestSchema.borrowerState,
  productID: loanRequestSchema.productId,
  loanAmount: loanRequestSchema.loanAmount,
  sectorID: loanRequestSchema.borrowerSector,
  tenureInDays: loanRequestSchema.loanTenure,
  address: yup
    .string()
    .trim()
    .min(5, "Address must have at least 5 characters")
    .max(256, "Address cannot be longer than 256 characters"),
  bankCode: yup.string().trim(),
  bankAccountNumber: yup
    .string()
    .trim()
    .matches(numberRegExp, "Account number must contain digits only")
    .max(10, "Account Number must have exactly 10 characters"),
  bankAccountName: yup
    .string()
    .trim()
    .max(128, "Account name cannot have more than 128 characters"),
  repaymentStartDate: yup.string().trim(),
  annualInterestRate: yup
    .string()
    .trim()
    .matches(numberRegExp, "Invalid interest rate")
    .max(3, "Interest rate cannot have more than 3 characters"),
  dateofBirth: yup.string().trim(),
});

export default function UpdateAnIndividualBatchLoanRecord({
  loanToBeUpdated,
  scrollToTop,
  dismissRef,
  fetchNewData,
}) {
  const [error, setError] = useState(false);
  const [success, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...loanToBeUpdated,
      idNumber: loanToBeUpdated.customerBVN,
      dateofBirth: moment(loanToBeUpdated.dateofBirth).format("YYYY-MM-DD"),
      repaymentStartDate: moment(loanToBeUpdated.repaymentStartDate).format(
        "YYYY-MM-DD"
      ),
    },
  });

  const {
    data: bankListData,
    status: bankStatus,
    error: bankListError,
  } = useBanksList();

  const {
    data: productsData,
    status: productsStatus,
    error: productsError,
  } = useAggregatorProducts({ country_code: "" });

  const {
    data: sectorsData,
    status: sectorsStatus,
    error: sectorsError,
  } = useBusinessSectors();

  const onSubmit = async (data) => {
    const {
      firstName,
      lastName,
      emailAddress,
      address,
      idNumber,
      bankAccountNumber,
      bankAccountName,
      gender,
      phoneNumber,
      city,
      state,
      productID,
      loanAmount,
      tenureInDays,
      sectorID,
      repaymentStartDate,
      annualInterestRate,
      bankCode,
      dateofBirth,
    } = data;

    const reqBody = {
      ...loanToBeUpdated,
      firstName,
      lastName,
      gender,
      city,
      state,
      customerBVN: idNumber,
      loanAmount: Number(loanAmount),
      address,
      emailAddress,
      dateofBirth,
      repaymentStartDate: repaymentStartDate,
      tenureInDays: Number(tenureInDays),
      annualInterestRate: Number(annualInterestRate),
      productID: Number(productID),
      sectorID: Number(sectorID),
      phoneNumber,
      bankCode: bankCode ? bankCode.toString() : "",
      bankAccountName,
      bankAccountNumber,
      borrowerType: "Individual",
    };

    setLoading(true);
    setError(false);
    setSucess(false);

    try {
      const data = await putDataWithDotNet(
        apiEndpoints.updateABatchLoanRecord,
        reqBody
      );
      setLoading(false);
      scrollToTop();
      if (data.status === true) {
        setSucess("Loan information has been updated and submitted");
        reset();
        fetchNewData();
        setTimeout(() => {
          // Refetch All Batch Loans Data
          setSucess(false);
          dismissRef.current.click();
        }, 3000);
      } else {
        setError(data.message);
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UpdateAnIndividualBatchLoanRecord.component.jsx",
        },
      });
      setLoading(false);
      if (error.response.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        scrollToTop();
        if (error.response.data.error && error.response.data.error) {
          setError(error.response.data.error);
        } else {
          setError(errorHandler(error));
        }
      }
    }
  };
  if (
    bankStatus === "loading" ||
    productsStatus === "loading" ||
    sectorsStatus === "loading"
  )
    return <PageLoader />;
  return (
    <Fragment>
      {success && <p className="alert alert-success small">{success}</p>}
      {error && <p className="alert alert-danger small">{error}</p>}
      {bankListError && (
        <p className="alert alert-danger small">
          {errorHandler(bankListError)}
        </p>
      )}
      {productsError && (
        <p className="alert alert-danger small">
          {errorHandler(productsError)}
        </p>
      )}
      {sectorsError && (
        <p className="alert alert-danger small">{errorHandler(sectorsError)}</p>
      )}
      <p className="text-danger small text-center mb-5">
        Fields with asterisk (*) are required
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 col-lg-6 mb-3">
            <CustomInputField
              type="text"
              maxLength="30"
              reference={register}
              placeholder="Enter borrower's first name"
              label="First Name"
              name="firstName"
              errors={errors.firstName}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12 col-lg-6  mb-3">
            <CustomInputField
              type="text"
              maxLength="30"
              reference={register}
              placeholder="Enter borrower's last name"
              label="Last Name"
              name="lastName"
              errors={errors.lastName}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12 col-lg-6  mb-3">
            <CustomInputField
              type="email"
              maxLength="128"
              reference={register}
              placeholder="Enter borrower's email address"
              label="Email"
              name="emailAddress"
              errors={errors.emailAddress}
              showRequiredIcon={true}
            />
          </div>

          <div className="col-12 col-lg-6 mb-3">
            <CustomInputField
              type="number"
              maxLength="11"
              reference={register}
              placeholder="Enter borrower's ID Number"
              label="ID Number"
              name="idNumber"
              errors={errors.idNumber}
              showRequiredIcon={true}
              extraLabel="(e.g. BVN)"
            />
          </div>

          <div className="col-12 col-lg-6 mb-3">
            <CustomSelectDropdown
              reference={register}
              label="Bank"
              name="bankCode"
              errors={errors.bankCode}
            >
              <option value="">Select Borrower's Bank</option>

              {bankListData &&
                bankListData.length &&
                bankListData.map((bank) => (
                  <option key={bank.code} value={bank.code}>
                    {bank.name}
                  </option>
                ))}
            </CustomSelectDropdown>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <CustomInputField
              type="text"
              maxLength="128"
              reference={register}
              placeholder="Enter borrower's Account Name"
              label="Account Name"
              name="bankAccountName"
              errors={errors.bankAccountName}
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <CustomInputField
              type="number"
              maxLength="10"
              reference={register}
              placeholder="Enter borrower's Account Number"
              label="Account Number"
              name="bankAccountNumber"
              errors={errors.bankAccountNumber}
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <CustomInputField
              type="number"
              maxLength="20"
              reference={register}
              label="Phone Number"
              placeholder="Enter borrower's Phone Number"
              name="phoneNumber"
              errors={errors.phoneNumber}
              showRequiredIcon={true}
            />
          </div>

          <div className="col-12 col-lg-6 mb-3">
            <CustomSelectDropdown
              reference={register}
              label="Gender"
              name="gender"
              errors={errors.gender}
              showRequiredIcon={true}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </CustomSelectDropdown>
          </div>

          <div className="col-12 col-lg-6 mb-3">
            <CustomInputField
              type="date"
              reference={register}
              label="Date of birth"
              placeholder="Enter borrower's date of birth"
              name="dateofBirth"
              errors={errors.dateofBirth}
            />
          </div>

          <div className="col-12 col-lg-6 mb-3">
            <CustomTextArea
              maxLength="256"
              reference={register}
              placeholder="Enter borrower's address"
              label="Address"
              name="address"
              errors={errors.address}
            />
          </div>

          <div className="col-12 col-lg-6 mb-3">
            <CustomInputField
              type="text"
              maxLength="128"
              reference={register}
              label="City"
              placeholder="Enter borrower's city"
              name="city"
              errors={errors.city}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <CustomInputField
              type="text"
              maxLength="128"
              reference={register}
              label="State"
              name="state"
              placeholder="Enter borrower's state"
              errors={errors.state}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <CustomSelectDropdown
              reference={register}
              label="Product"
              name="productID"
              errors={errors.productID}
              showRequiredIcon={true}
            >
              <option value="">Select Product</option>
              {productsData &&
                productsData.length &&
                productsData.map((product) => (
                  <option key={product.id} value={product.id}>
                    {product.product_name}
                  </option>
                ))}
            </CustomSelectDropdown>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <CustomInputField
              type="number"
              maxLength="10"
              reference={register}
              label="Loan Amount"
              placeholder="Enter loan amount"
              name="loanAmount"
              errors={errors.loanAmount}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <CustomInputField
              type="number"
              maxLength="3"
              reference={register}
              label="Loan Tenure"
              name="tenureInDays"
              placeholder="Enter Loan Tenure"
              errors={errors.tenureInDays}
              extraLabel="(in days)"
              showRequiredIcon={true}
              min="1"
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <CustomSelectDropdown
              reference={register}
              label="Sector"
              name="sectorID"
              errors={errors.sectorID}
            >
              <option value="">Select Borrower Sector</option>
              {sectorsData &&
                sectorsData.length &&
                sectorsData.map((sector) => {
                  const { category_id, category_name } = sector;
                  return (
                    <option key={category_id} value={category_id}>
                      {category_name}
                    </option>
                  );
                })}
            </CustomSelectDropdown>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <CustomInputField
              type="date"
              reference={register}
              label="Repayment Start Date"
              name="repaymentStartDate"
              placeholder="Enter Repayment Start Date"
              errors={errors.repaymentStartDate}
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <CustomInputField
              type="number"
              maxLength="3"
              reference={register}
              label="Annual Interest Rate"
              name="annualInterestRate"
              placeholder="Enter Annual Interest Rate"
              errors={errors.annualInterestRate}
              extraLabel="(in %)"
              min="1"
            />
          </div>

          <div className="col-sm-12 mb-12">
            <button
              type="submit"
              className="btn advancly-btn btn-sm transition-3d-hover"
            >
              Update Loan
              {loading && <Loader />}
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  );
}
