import React from "react";
import { Route, withRouter } from "react-router-dom";
import AllInvestorsMain from "./AllInvestorsMain";
import EachInvestor from "./EachInvestor";

const AllInvestors = (props) => {
  let match = props.match;
  return (
    <div>
      <Route exact path={match.path} render={() => <AllInvestorsMain />} />
      <Route path={match.path + "/:id"} render={() => <EachInvestor />} />
    </div>
  );
};
export default withRouter(AllInvestors);
