import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
type currencyType = {
  currentCurrencyValue: string;
  aggregatorId: number;
  activeProductName: string;
};
const getLoanCount = async ({
  currentCurrencyValue,
  aggregatorId,
  activeProductName,
}: currencyType) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.LOAN_COUNT}?aggregatorId=${aggregatorId}&currency=${currentCurrencyValue}&product=${activeProductName}`
  );
  return data.data;
};

export default function useLoanCount({
  currentCurrencyValue,
  aggregatorId,
  activeProductName,
}: currencyType) {
  return useQuery(
    [{ currentCurrencyValue, aggregatorId, activeProductName }, "getLoanCount"],
    getLoanCount,
    {
      enabled: currentCurrencyValue && activeProductName ? true : false,
      retry: 4,
    }
  );
}
