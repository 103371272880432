import React, { useRef, useState } from "react";
import Loader from "../Loader/Loader.component";
import { errorHandler } from "./../../helpers/errorHandler";
import { apiEndpoints } from "./../../apis/apiEndpoints";
import { putDataWithDotNet } from "../../apis/dotNetApiMethods";
import { useQueryCache } from "react-query";
import { appInsights } from "../AppInsight/AppInsight";

export default function UpdateUserConfirmationDialog({ userToUpdate }) {
  const dismissRef = useRef();
  const queryCache = useQueryCache();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateUserStatus = async () => {
    const { id, isActive } = userToUpdate;
    setLoading(true);
    setError(null);
    try {
      const res = await putDataWithDotNet(
        `${apiEndpoints.toggleAggregatorUserStatus}/${id}`,
        {},
        true
      );
      if (res.status === true) {
        setSuccess(`User has been ${isActive ? "disabled" : "enabled"}`);
        queryCache.invalidateQueries("getAggregatorUsers");
        setTimeout(() => {
          dismissRef.current.click();
          setSuccess(null);
        }, 3000);
      } else {
        setError(res.message);
      }
      setLoading(false);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UpdateUserConfirmationDialog.component.jsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const hideModal = () => {
    dismissRef.current.click();
  };

  return (
    <div
      className="modal fade"
      id="updateUserStatusModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="updateUserStatusModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <h5
              className="page-subtitle text-center w-100"
              id="updateUserStatusModalLabel"
            >
              Are you sure you want to{" "}
              {userToUpdate && userToUpdate.isActive ? "disable" : "enable"}{" "}
              this user?
            </h5>
            <button
              type="button"
              className="close d-none"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            ></button>
          </div>
          <div className="modal-body">
            {error && <p className="alert alert-danger small">{error}</p>}
            {success && <p className="alert alert-success small">{success}</p>}
            <div className="d-flex justify-content-between align-item-center mt-3">
              <button
                className="btn btn-sm advancly-white-btn"
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                className="btn btn-sm advancly-btn"
                disabled={loading}
                onClick={updateUserStatus}
              >
                Proceed {loading && <Loader />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
