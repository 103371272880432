import React, { useState, Fragment, useEffect } from "react";
import styles from "../../GetStarted.module.scss";
import { Link, useHistory } from "react-router-dom";
import {
  DeleteUploadedDocument,
  DownloadUploadedDocument,
} from "../../../../components/UploadedDocumentCards/UploadedDocumentCard.component";
import { convertToBase64 } from "./../../../../helpers/convertToBase64";
// import PageLoader from "../../../../components/PageLoader/PageLoader.component";
import useListOfDocumentsToUpload from "../../../../custom-hooks/useListOfDocumentsToUpload";
import Loader from "../../../../components/Loader/Loader.component";
import { errorHandler } from "./../../../../helpers/errorHandler";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import useListOfDocumentsAlreadyUploaded from "./../../../../custom-hooks/useListOfDocumentsAlreadyUploaded";
import { useQueryCache } from "react-query";
import { postData } from "../../../../apis/apiMethods";
import Alert from "./../../../../components/Alert/Alert.component";
import { putDataWithDotNet } from "../../../../apis/dotNetApiMethods";
import OnboardingBackWordButton from "../../../../components/OnboardingBackWordButton/OnboardingBackWordButton";
import advanclyLogo from "../../../../img/advancly_logo.svg";
import PageLoaderAnimation from "../../../../components/PageLoader/PageLoaderAnimation";
import { useAuth } from "../../../../Context/auth.context";
import { v4 as uuidv4 } from "uuid";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import CommentsDocsUpload from "../../../../components/Comments/CommentsDocsUpload";
import LoaderColored from "../../../../components/Loader/LoaderColored";
export default function GetStartedUploadDocuments() {
  const [allFiles, setAllFiles] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disableUpload, setDisableUpload] = useState(true);
  const [disableAddFile, setDisableFile] = useState(false);
  const [replaceFileId, setReplaceFileId] = useState("");
  const [itemValues, setItemValues] = useState("");

  const history = useHistory();
  const queryCache = useQueryCache();

  const { data, status, error: docsError } = useListOfDocumentsToUpload();

  const {
    data: uploadedDocs,
    status: uploadedDocsStatus,
    error: uploadedDocsError,
    refetch: refetchUploadedDocs,
  } = useListOfDocumentsAlreadyUploaded();

  const { aggregatorDetails } = useAuth();
  if (uploadedDocs && uploadedDocs.length && !allFiles.length) {
    // The structure of the data coming from this endpoint is unnecessarily complicated.
    // We are basically running multiple nested loops to extract category files. When done we set those files the state (allFiles).
    let newFiles = [];
    uploadedDocs
      .sort((a, b) => a.position - b.position)
      .map((doc) => {
        const { aggregator_documents } = doc;

        return aggregator_documents.forEach((aggregator_document) => {
          aggregator_document.files.forEach((uploadedFile) => {
            newFiles.push({
              filename_with_extension: uploadedFile.file_name,
              base64_file_string: uploadedFile.upload_path,
              document_field_id: aggregator_document.document_field_id,
              aggregator_document_id: uploadedFile?.aggregator_document_id,
              approval_status: uploadedFile?.approval_status,
              isNewFile: false,
              detailed_id: uuidv4(),
              comments: uploadedFile?.comments,
            });
          });
        });
      });
    setAllFiles(newFiles);
  }

  const getSpecificCategoryDocuments = (idInQuestion) => {
    // Here we are basically getting the files that belong to a particular category and then eventually
    // display them under each category in the UI.
    let result = allFiles.filter(
      (item) => item.document_field_id === idInQuestion
    );

    return result;
  };

  const onFileChange = async (e, id, document_field_id) => {
    if (!e.target.files[0]) return;
    setError(null);
    const fileName = e.target.files[0].name;

    const isValidFile =
      fileName.endsWith(".pdf") ||
      fileName.endsWith(".doc") ||
      fileName.endsWith(".docx") ||
      fileName.endsWith(".jpeg") ||
      fileName.endsWith(".jpg") ||
      fileName.endsWith(".png") ||
      fileName.endsWith(".csv") ||
      fileName.endsWith(".xlsx") ||
      fileName.endsWith(".xls");
    if (!isValidFile) {
      return setError("Invalid File type.");
    }
    const fileBase64 = await convertToBase64(e.target.files[0]);
    // Remove file
    document.getElementById(`file-selector${id}`).value = "";
    const newFile = {
      filename_with_extension: fileName,
      base64_file_string: fileBase64,
      document_field_id: document_field_id,
      isNewFile: true,
      detailed_id: uuidv4(),
    };
    //This condition is to disable the upload button
    if (newFile.filename_with_extension && newFile.base64_file_string) {
      setDisableUpload(false);
      setDisableFile(true);
    } else {
      setDisableUpload(true);
      setDisableFile(false);
    }

    if (replaceFileId) {
      let indexOfFileTOBeReplaced = allFiles.findIndex(
        (item) => item.detailed_id === replaceFileId
      );

      const _fileInQuestion = allFiles.find(
        (item) => item?.detailed_id === replaceFileId
      );

      let newAllFiles = [...allFiles];
      newAllFiles[indexOfFileTOBeReplaced] = {
        filename_with_extension: fileName,
        base64_file_string: fileBase64,
        document_field_id: document_field_id,
        aggregator_document_id: _fileInQuestion?.aggregator_document_id,
        isNewFile: true,
        isReupload: true,
        detailed_id: uuidv4(),
      };

      setAllFiles(newAllFiles);

      setReplaceFileId("");
    } else {
      setAllFiles((prev) => {
        return [...prev, newFile];
      });
    }
  };

  const replaceFile = (_id, detailed_id_of_file) => {
    setReplaceFileId(detailed_id_of_file);
    return document.getElementById(`file-selector${_id}`).click();
  };

  const removeFile = (id) => {
    let result = allFiles.filter((item) => item.detailed_id !== id);
    setAllFiles(result);
    setDisableFile(false);
    setDisableUpload(true);
  };

  const ReUploadAFile = async (reqBody) => {
    try {
      await postData(apiEndpoints.reuploadCategoryFile, reqBody, true);
      setLoading(false);
      queryCache.invalidateQueries("getListOfDocumentsAlreadyUploaded");
      refetchUploadedDocs();

      let newFiles = [...allFiles];
      let result = newFiles.map((item) => {
        return {
          ...item,
          isNewFile: false,
          isReupload: false,
        };
      });
      setAllFiles(result);
      setSuccess("File has been uploaded.");
      // setDisableUpload(true);
      setDisableFile(false);
      setDisableUpload(true);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "GetStartedUploadDocuments.jsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const UploadAFile = async (reqBody) => {
    try {
      await postData(
        apiEndpoints.getListOfDocumentsAlreadyUploaded,
        reqBody,
        true
      );
      setLoading(false);
      queryCache.invalidateQueries("getListOfDocumentsAlreadyUploaded");
      refetchUploadedDocs();
      let newFiles = [...allFiles];
      let result = newFiles.map((item) => {
        return {
          ...item,
          isNewFile: false,
        };
      });
      setAllFiles(result);
      setSuccess("File has been uploaded.");
      setDisableUpload(true);
      setDisableFile(false);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "GetStartedUploadDocuments.jsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const handleUploadDocument = async (id) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    const filesToUpload = allFiles.filter(
      (item) => item?.document_field_id === id
    );

    // Check to see if any file is to be replaced/reuploaded.
    const isFileReuploadExist = filesToUpload.filter(
      (item) => item?.isReupload === true
    );

    if (isFileReuploadExist.length > 0) {
      const filesToBeReUploaded = filesToUpload.filter(
        (item) => item?.isReupload === true
      );

      let files = filesToBeReUploaded.map((item) => {
        return {
          base64_file_string: item?.base64_file_string,
          filename_with_extension: item?.filename_with_extension,
          aggregator_document_id: item?.aggregator_document_id,
        };
      });

      const reqBody = {
        aggregator_documents: [
          {
            document_field_id: filesToBeReUploaded[0]?.document_field_id,
            files: files,
          },
        ],
      };

      await ReUploadAFile(reqBody);
    } else {
      let newFilesToBeUploaded = filesToUpload.filter(
        (item) => item.isNewFile === true
      );

      let files = newFilesToBeUploaded.map((item) => {
        return {
          base64_file_string: item?.base64_file_string,
          filename_with_extension: item?.filename_with_extension,
          aggregator_document_id: item?.aggregator_document_id,
        };
      });

      const reqBody = {
        aggregator_documents: [
          {
            document_field_id: newFilesToBeUploaded[0]?.document_field_id,
            files: files,
          },
        ],
      };

      await UploadAFile(reqBody);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    // Ensure allFiles array is not empty
    // if (allFiles.length !== data.length) {
    //   return setError("Please upload all files");
    // }
    try {
      const res = await putDataWithDotNet(
        apiEndpoints.getListOfDocumentsAlreadyUploadedSingle,
        {},
        true
      );

      if (res.status_code === 200) {
        history.push({
          pathname: `/get-started/onboarding`,
          state: {
            successMessage: "Well done! Your uploads have been saved",
          },
        });
        setTimeout(() => {}, 5000);
      } else {
        setError(res);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "GetStartedUploadDocuments.jsx",
        },
      });
      if (error.response && error.response.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        setError(errorHandler(error));
      }
    }
    setLoading(false);
  };

  const handleProceed = () => {
    //THE COMMENTED CODE  BELOW   IS THE OLD IMPLEMENTATION  FOR  CHECKING DOCUMENT LENGTH, THE NEW IMPLEMENTATION IS FOCUSED ON CHECKING REQUIRED DOCUMENTS AND ENSURING THAT DOCUMENT CATEGORY MATCH FILE UPLOAD
    // let testArray = [];
    // uploadedDocs.forEach((uploadedDoc) => {
    //   console.log("docs", uploadedDoc);
    //   console.log("checkingData", data);
    //   let result = uploadedDoc.aggregator_documents.every(
    //     (item) => item.files.length > 0
    //     // && isFileApprovalStatusApproved(item?.files)
    //   );
    //   if (result === true) {
    //     testArray.push(true);
    //   } else {
    //     testArray.push(false);
    //   }
    // });
    // if (testArray.length === data.length) {
    //   let finalResult = testArray.every((item) => item === true);
    //   if (!finalResult) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
    // return true;
    let docCategoriesisMandatory = data
      .map(({ is_mandatory }) => {
        return is_mandatory;
      })
      .filter((booleanV) => {
        return booleanV === true;
      });

    let UploadedDocuments = uploadedDocs
      .map(({ is_mandatory }) => {
        return is_mandatory;
      })
      .filter((booleanValues) => {
        return booleanValues === true;
      });

    if (docCategoriesisMandatory.length === UploadedDocuments.length) {
      let docsUploadfilter = uploadedDocs
        .filter(({ is_mandatory }) => {
          return is_mandatory === true;
        })
        .map(({ aggregator_documents }) => {
          return aggregator_documents.every((item) => {
            return item.files.length > 0;
          });
        });

      docsUploadfilter.every((isEveryFileLength) => {
        return isEveryFileLength !== true;
      });
    } else if (uploadedDocs.length === 0) {
      return true;
    } else {
      return true;
    }
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "GetStartedUploadDocuments.jsx",
      isLoggedIn: true,
    });
  }, []);

  if (status === "loading" || uploadedDocsStatus === "loading")
    return (
      <div className="d-flex justify-content-center align-items-center my-auto">
        {" "}
        <PageLoaderAnimation />
      </div>
    );
  const EmbedProductUpload = aggregatorDetails?.product_types.some(
    ({ product }) => product === "Embed"
  );
  const SparkProductUpload = aggregatorDetails?.product_types.some(
    ({ product }) => product === "Spark"
  );

  return (
    <div className={`ml-5 mt-5`}>
      <div className={styles.onboardingLogoLink}>
        <Link className={styles.sparkLogoLink} to="/">
          <img
            className={styles.sparkLogo}
            src={advanclyLogo}
            alt="Login logo"
          />
        </Link>
        <OnboardingBackWordButton />
      </div>
      <div className={styles.newGetStartedInnerBusinessComponent}>
        <div className=" ">
          <div className="">
            {error && <Alert message={error} />}
            {success && <Alert message={success} type="success" />}
            {docsError && (
              <p className="alert alert-danger small">
                {errorHandler(docsError)}
              </p>
            )}
            {uploadedDocsError && (
              <p className="alert alert-danger small">
                {errorHandler(uploadedDocsError)}
              </p>
            )}

            <form onSubmit={onSubmit}>
              <>
                {/* SPARK AND EMBED UPLOAD*/}
                <div className="d-flex justify-content-start align-items-center w-100 mb-4 mt-3">
                  {EmbedProductUpload && SparkProductUpload && (
                    <h3 className="page-title mb-0 ">
                      Upload Documents For Both Spark and Embed
                    </h3>
                  )}
                  {!EmbedProductUpload && SparkProductUpload && (
                    <h3 className="page-title mb-0 ">
                      Upload Documents For Spark
                    </h3>
                  )}
                  {EmbedProductUpload && !SparkProductUpload && (
                    <h3 className="page-title mb-0 ">
                      Upload Documents For Embed
                    </h3>
                  )}
                </div>
                <p>
                  Kindly upload the following documents for our Credit
                  Assessment team to review
                </p>
                <p
                  className={`text-danger d-flex flex-wrap justify-content-center font-weight-bold  ${styles.upload_hint_alert}`}
                >
                  *Please note that you are required to upload all documents for
                  each category to proceed to the next step*
                </p>
                <div>
                  {data &&
                    data.length > 0 &&
                    data
                      .sort((a, b) => a.position - b.position)
                      .map((eachData, _id) => {
                        const { title, id, is_mandatory } = eachData;
                        return (
                          <div className="row d-flex" key={id}>
                            <div
                              className="col-xs-12 col-md-6 mb-3 gap-2"
                              key={id}
                            >
                              <label className={styles.titleForUpload}>
                                {title}{" "}
                                {is_mandatory === true ? (
                                  <span
                                    className={styles.smallSpanTitleRequired}
                                  >
                                    required *
                                  </span>
                                ) : (
                                  <span
                                    className={styles.smallSpanTitleOptional}
                                  >
                                    optional
                                  </span>
                                )}
                              </label>
                              <div className=" mt-3">
                                {/* <div className="color-light-blue font-weight-bold">
                                     {`Your uploaded ${title}`}
                                      </div> */}
                                <div>
                                  {getSpecificCategoryDocuments(id).length >
                                    0 &&
                                    getSpecificCategoryDocuments(id).map(
                                      (item) => (
                                        <Fragment key={item.detailed_id}>
                                          {item.isNewFile === true ? (
                                            <Fragment>
                                              <DeleteUploadedDocument
                                                name={
                                                  item.filename_with_extension
                                                }
                                                deleteFile={() =>
                                                  removeFile(item.detailed_id)
                                                }
                                              />
                                              <p className="text-danger font-weight-bold">
                                                *Click upload to add more files*
                                              </p>
                                              {loading && (
                                                <div
                                                  className={`d-flex   mt-1 mb-1 ${styles.upload_loader}`}
                                                >
                                                  <div></div>
                                                  <LoaderColored />
                                                </div>
                                              )}
                                            </Fragment>
                                          ) : (
                                            <div>
                                              <div className="d-flex align-items-center">
                                                <DownloadUploadedDocument
                                                  name={
                                                    item.filename_with_extension
                                                  }
                                                  pathname={
                                                    item.base64_file_string
                                                  }
                                                />

                                                <div className="pl-3">
                                                  {item?.approval_status ===
                                                    1 && (
                                                    <span className="ml-2 p-2 pending_status">
                                                      Pending
                                                    </span>
                                                  )}
                                                  {item?.approval_status ===
                                                    2 && (
                                                    <span className="ml-2 p-2 approved_status">
                                                      Approved
                                                    </span>
                                                  )}
                                                  {item?.approval_status ===
                                                    3 && (
                                                    <span className="ml-2  p-2 declined_status ">
                                                      Declined
                                                    </span>
                                                  )}
                                                </div>
                                              </div>

                                              <div className="d-flex align-items-center">
                                                {item?.approval_status ===
                                                  3 && (
                                                  <div
                                                    className="text-underline color-mid-blue cursor-pointer"
                                                    onClick={() =>
                                                      replaceFile(
                                                        _id,
                                                        item?.detailed_id
                                                      )
                                                    }
                                                  >
                                                    Re-upload file
                                                  </div>
                                                )}
                                                <button
                                                  className={`btn color-mid-blue  transition-3d-hover `}
                                                  data-toggle="modal"
                                                  data-target="#commentsDocUploadModal"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setItemValues(item);
                                                  }}
                                                >
                                                  Comments{" "}
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-chat-dots"
                                                    viewBox="0 0 16 16"
                                                  >
                                                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                    <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                                                  </svg>
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </Fragment>
                                      )
                                    )}

                                  {getSpecificCategoryDocuments(id).length >
                                    0 && (
                                    <div className="mt-3">
                                      <button
                                        className="btn  advancly-white-btn btn-sm transition-3d-hover"
                                        onClick={() => {
                                          document
                                            .getElementById(
                                              `file-selector${_id}`
                                            )
                                            .click();
                                        }}
                                        type="button"
                                        disabled={disableAddFile || loading}
                                      >
                                        Add File
                                      </button>
                                      <button
                                        className="btn advancly-btn btn-sm transition-3d-hover ml-2"
                                        onClick={() => handleUploadDocument(id)}
                                        type="button"
                                        disabled={loading || disableUpload}
                                      >
                                        Upload
                                      </button>
                                    </div>
                                  )}

                                  {getSpecificCategoryDocuments(id).length <
                                    1 && (
                                    <Fragment>
                                      <div
                                        className="text-underline color-mid-blue cursor-pointer mt-4"
                                        onClick={() => {
                                          document
                                            .getElementById(
                                              `file-selector${_id}`
                                            )
                                            .click();
                                        }}
                                      >
                                        Add File
                                      </div>
                                    </Fragment>
                                  )}
                                </div>
                              </div>
                              <input
                                type="file"
                                accept="application/pdf, application/msword, image/*,.xlsx, .xls,.csv"
                                onChange={(e) => onFileChange(e, _id, id)}
                                className={`d-none ${styles.inputFileBorder}`}
                                id={`file-selector${_id}`}
                              />
                            </div>
                          </div>
                        );
                      })}
                </div>
              </>
              <div className="row">
                <div className="col-12  pl-0 align-self-center mt-5  flex-wrap mb-3 ">
                  <button
                    className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                    type="button"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                    type="submit"
                    disabled={!data || loading || handleProceed()}
                  >
                    Save {loading && <Loader />}
                  </button>
                  {/* <button
                    className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                    type="submit"
                    disabled={ !data || loading || allFiles.length !== data.length
                    }
                  >
                    Save {loading && <Loader />}
                  </button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CommentsDocsUpload fileComments={itemValues} />
    </div>
  );
}
