import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { appInsights } from "../../components/AppInsight/AppInsight";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import Paginator from "../../components/Paginator/Paginator.component";
import useWalletDisbursementTransactions from "../../custom-hooks/useWalletDisbursementTransactions";
import { formatMoney } from "../../helpers/formatter";

const WalletDisbursementTransactions = () => {
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
  });
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    startDate: "",
    endDate: "",
    searchStatus: "",
  });
  const [count, setCount] = useState(null);
  const { data, status, error } = useWalletDisbursementTransactions(preState);
  if (data) {
    if (count === null) {
      setCount(data?.totalCount);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const filterData = (e) => {
    e.preventDefault();
    setPreState({ ...state });
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletDisbursementTransactions.jsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      {error && <p className="alert alert-danger small">{error}</p>}
      {!error && (
        <div>
          <div className="card">
            {status === "loading" ? (
              <div className="card-body p-3">
                <PageLoader />
              </div>
            ) : (
              <div className="card-body p-3">
                <div className="d-flex justify-content-between align-items-center py-3">
                  <form
                    className="d-flex flex-row flex-wrap"
                    onSubmit={filterData}
                  >
                    <div className="form-group mr-3">
                      <div className="input-group mr-3">
                        <label className="mr-2 mt-3">Filter By Status</label>
                        <CustomSelectDropdown
                          className="form-control"
                          value={state.searchStatus}
                          name="searchStatus"
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">All</option>
                          <option value={0}>Pending</option>
                          <option value={1}>Approved</option>
                          <option value={2}>Declined</option>
                        </CustomSelectDropdown>
                      </div>
                    </div>
                    {/* <div
                      className="input-group mr-3"
                      style={{ maxWidth: "200px", marginTop: "10px" }}
                    >
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Check By Status"
                        value={state.searchStatus}
                        name="searchStatus"
                        onChange={(e) => handleChange(e)}
                      />
                    </div> */}

                    <div className="form-group mr-3">
                      <div className="input-group mt-2">
                        <label className="mr-2 mt-2">From </label>
                        <input
                          type="date"
                          name="startDate"
                          className="form-control"
                          value={state.startDate}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group mr-3 mt-2">
                      <div className="input-group">
                        <label className="mr-2 mt-2">To </label>
                        <input
                          type="date"
                          className="form-control"
                          name="endDate"
                          value={state.endDate}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn advancly-btn btn-sm mt-2"
                        value="submit"
                        disabled={status === "loading"}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead className="bg-lighter-grey">
                      <tr>
                        <th>S/N</th>
                        <th>Recipient Name</th>
                        <th>Account Number</th>
                        <th>Bank Name</th>
                        <th>Amount</th>
                        <th>Transaction Date</th>
                        <th>Status</th>
                        {/* <th>Reference</th> */}
                        <th>Narration</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data ? (
                        data?.data.map((item, _id) => {
                          const {
                            id,
                            account_name,
                            account_number,
                            bank_name,
                            amount,
                            transaction_date,
                            status,
                            maker_checker_reference,
                            narration,
                          } = item;
                          return (
                            <tr key={id}>
                              <td>{_id + 1}</td>
                              <td>
                                {account_name ? account_name : "Not Available"}
                              </td>
                              <td>{account_number}</td>
                              <td>{bank_name}</td>
                              <td>{formatMoney(amount, "#")}</td>
                              <td>
                                {transaction_date
                                  ? transaction_date.substring(0, 10)
                                  : "N/A"}
                              </td>
                              <td>{status}</td>
                              {/* <td>{maker_checker_reference}</td> */}
                              <td>{narration}</td>
                              <td>
                                <Link
                                  to={{
                                    pathname: `/wallettransactionlog/${maker_checker_reference}`,
                                    state: {
                                      data: item,
                                    },
                                  }}
                                  className="color-blue"
                                >
                                  View Details
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
                {data && data?.data && (
                  <Paginator
                    size={preState.size}
                    page={preState.pageNumber}
                    count={count}
                    changeCurrentPage={(pageNumber) =>
                      setPreState({ ...preState, pageNumber })
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletDisbursementTransactions;
