import React, { useState } from "react";
import NoTableDataAvailable from "../NoTableDataAvailable/NoTableDataAvailable.component";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import Paginator from "../../components/Paginator/Paginator.component";
import { formatMoney } from "../../helpers/formatter";
import { useLocation, withRouter } from "react-router-dom";
import OnboardingBackButtonComponent from "../OnboardingBackButton/OnboardingBackButton.component";
import Loader from "../Loader/Loader.component";
import useCustomerWalletTransactions from "../../custom-hooks/useCustomerWalletTransactions";

const CustomerWalletTransactions = () => {
  const location = useLocation();
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    accountNumber: location?.state?.data?.accountNumber,
    consumerType: location?.state?.consumerType,
  });
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    startDate: "",
    endDate: "",
    accountNumber: location?.state?.data?.accountNumber,
    consumerType: location?.state?.consumerType,
  });
  const [count, setCount] = useState(null);
  const { data, status, error } = useCustomerWalletTransactions(preState);

  if (data) {
    if (count === null) {
      setCount(data?.total);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const filterData = (e) => {
    e.preventDefault();
    setCount(null);
    setPreState({ ...state, pageNumber: 1 });
  };

  return (
    <div>
      {error && <p className="alert alert-danger small">{error}</p>}
      {!error && (
        <div>
          <div className="d-flex align-items-center justify-content-start">
            <h3 className="page-title d-flex align-items-center">
              <OnboardingBackButtonComponent />
              Customer Wallet Transactions
            </h3>
          </div>
          <div className="card">
            {status === "loading" ? (
              <div className="card-body p-3">
                <PageLoader />
              </div>
            ) : (
              <div className="card-body p-3">
                <div className="d-flex justify-content-between align-items-center py-3">
                  <form
                    className="d-flex flex-row flex-wrap"
                    onSubmit={filterData}
                  >
                    <div className="form-group mr-3">
                      <div className="input-group mt-2">
                        <label className="mr-2 mt-2">From </label>
                        <input
                          type="date"
                          name="startDate"
                          className="form-control"
                          value={state.startDate}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group mr-3 mt-2">
                      <div className="input-group">
                        <label className="mr-2 mt-2">To </label>
                        <input
                          type="date"
                          className="form-control"
                          name="endDate"
                          value={state.endDate}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn advancly-btn btn-sm mt-2"
                        value="submit"
                        disabled={status === "loading"}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <h3 className="page-subtitle mb-3  ">
                  Available Balance:{" "}
                  {status === "loading" ? (
                    <Loader />
                  ) : data?.availableBalance ? (
                    formatMoney(data?.availableBalance, data?.currency)
                  ) : (
                    "0.00"
                  )}
                </h3>
                <div className="table-responsive">
                  <table className="table">
                    <thead className="bg-lighter-grey">
                      <tr>
                        <th>S/N</th>
                        <th>Transaction Date</th>
                        <th>Narration</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Transaction Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.transactions?.length > 0 ? (
                        data?.transactions?.map((request, _id) => {
                          const {
                            transactionDate,
                            transactionRemarks,
                            debitAmount,
                            creditAmount,
                            transactionStatus,
                          } = request;
                          return (
                            <tr key={_id}>
                              <td>{_id + 1}</td>
                              <td>{transactionDate.substring(0, 10)}</td>
                              <td>{transactionRemarks}</td>
                              <td>
                                {formatMoney(debitAmount, data?.currency)}
                              </td>
                              <td>
                                {formatMoney(creditAmount, data?.currency)}
                              </td>
                              <td>{transactionStatus}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
                {data && data?.transactions && (
                  <Paginator
                    size={preState.size}
                    page={preState.pageNumber}
                    count={count}
                    changeCurrentPage={(pageNumber) =>
                      setPreState({ ...preState, pageNumber })
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(CustomerWalletTransactions);
