import React, { useState } from "react";
import FirstSignUp from "./SignUpOneComponents/FirstSignUp";
import SignUpFour from "./SignUpFour";
import SignUpThree from "./SignUpThree";
import SignUpWaitList from "./SignUpWaitList";
import SignupTwo from "./SignupTwo";
import { SignUpFive } from "./SignUpFive";

const Signup = () => {
  const [step, setStep] = useState(1);
  const [state, setstate] = useState({});
  const [reqBodyData, setReqBodyData] = useState();
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  const upDateState = (state) => {
    setstate(state);
  };
  return (
    <div>
      {(() => {
        switch (step) {
          case 1:
            return (
              <FirstSignUp
                setStep={setStep}
                upDateState={upDateState}
                error={error}
                loading={loading}
                setLoading={setLoading}
                setError={setError}
              />
            );
          case 2:
            return (
              <SignupTwo
                setStep={setStep}
                state={state}
                upDateState={upDateState}
                error={error}
                loading={loading}
                setLoading={setLoading}
                setError={setError}
              />
            );
          case 3:
            return (
              <SignUpThree
                setStep={setStep}
                state={state}
                personalEmail={state.personalEmail}
                error={error}
                loading={loading}
                setLoading={setLoading}
                setError={setError}
                reqBodyData={reqBodyData}
                setReqBodyData={setReqBodyData}
              />
            );
          case 4:
            return (
              <SignUpFour
                setStep={setStep}
                state={state}
                personalEmail={state.personalEmail}
                error={error}
                loading={loading}
                setLoading={setLoading}
                setError={setError}
                reqBodyData={reqBodyData}
                setReqBodyData={setReqBodyData}
              />
            );
          case 5:
            return (
              <SignUpFive
                setStep={setStep}
                state={state}
                personalEmail={state.personalEmail}
                error={error}
                loading={loading}
                setLoading={setLoading}
                setError={setError}
                reqBodyData={reqBodyData}
                setReqBodyData={setReqBodyData}
              />
            );
          case 6:
            return (
              <SignUpWaitList
                setStep={setStep}
                state={state}
                error={error}
                loading={loading}
                setLoading={setLoading}
                setError={setError}
                reqBodyData={reqBodyData}
                setReqBodyData={setReqBodyData}
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default Signup;
