import React from "react";
import { useForm } from "react-hook-form";
import { errorHandler } from "../../../helpers/errorHandler";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import Loader from "../../Loader/Loader.component";

const ChangeMultipleSignatoryModal1 = ({
  usersData,
  usersStatus,
  usersError,
  changeMultipleSignatory,
  setChangeMultipleSignatory,
  setChangeMultipleSignatoryName,
  step,
  signatories,
  signatoriesStatus,
  dismissRef,
  changeMultipleSignatoryName,
}) => {
  const submitHandler = (data) => {
    // logic for change multiple signatory
    let signatoriesBody;
    const { changeSignatoryName1, changeSignatoryName2, changeSignatoryName3 } =
      data;

    if (
      changeSignatoryName1 !== "0" &&
      changeSignatoryName2 !== "0" &&
      changeSignatoryName3 !== "0"
    ) {
      signatoriesBody = [
        { email: changeSignatoryName1, levelNo: 1 },
        { email: changeSignatoryName2, levelNo: 2 },
        { email: changeSignatoryName3, levelNo: 3 },
      ];
      setChangeMultipleSignatoryName(signatoriesBody);
      setChangeMultipleSignatory(changeMultipleSignatory + step);
    } else if (changeSignatoryName1 !== "0" && changeSignatoryName2 !== "0") {
      signatoriesBody = [
        { email: changeSignatoryName1, levelNo: 1 },
        { email: changeSignatoryName2, levelNo: 2 },
      ];
      setChangeMultipleSignatoryName(signatoriesBody);
      setChangeMultipleSignatory(changeMultipleSignatory + step);
    } else if (changeSignatoryName1 !== "0") {
      signatoriesBody = [{ email: changeSignatoryName1, levelNo: 1 }];
      setChangeMultipleSignatoryName(signatoriesBody);
      setChangeMultipleSignatory(changeMultipleSignatory + step);
    } else {
      return;
    }
  };
  const {
    handleSubmit,
    register,
    // errors, watch
  } = useForm();

  //   const watchChangeSignatoryNameChange = watch("changeSignatoryName1");
  //   const watchChangeSignatoryNameChange2 = watch("changeSignatoryName2");
  //   const watchChangeSignatoryNameChange3 = watch("changeSignatoryName3");
  // const { data: users, status, error } = useAggregatorSignatoryUsers();
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Change Multiple Signatory Approval</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={() => {
            setChangeMultipleSignatory(1);
          }}
        >
          &times;
        </button>
      </div>
      <div className="modal-body">
        <p className="pt-2">Select one of the following users as a signatory</p>
        <form onSubmit={handleSubmit(submitHandler)}>
          {usersStatus === "loading" && (
            <small className="text-primary">Loading...</small>
          )}
          {usersError && (
            <small className="text-dangeralert alert-danger small text-center">
              {errorHandler(usersError)}
            </small>
          )}
          <CustomSelectDropdown
            name="changeSignatoryName1"
            reference={register({ required: true })}
            label="Signatory One"
          >
            <option value={0}>None</option>

            {signatories && signatories[0]?.email && (
              <option value={signatories[0].email} selected>
                {signatories[0]?.email}
              </option>
            )}

            {usersData?.map((user) => {
              const { id, firstName, lastName, email } = user;
              return (
                <option
                  key={id}
                  value={email}
                >{`${firstName} ${lastName}`}</option>
              );
            })}
          </CustomSelectDropdown>
          {/* {errors.changeSignatoryName1 && (
            <p className="text-danger">Please select a name</p>
          )} */}
          <CustomSelectDropdown
            name="changeSignatoryName2"
            reference={register({ required: true })}
            label="Signatory Two"
          >
            <option value={0}>None</option>

            {signatories && signatories[1]?.email && (
              <option value={signatories[1].email} selected>
                {signatories[1]?.email}
              </option>
            )}

            {usersData?.map((user) => {
              const { id, firstName, lastName, email } = user;
              return (
                <option
                  key={id}
                  value={email}
                >{`${firstName} ${lastName}`}</option>
              );
            })}
          </CustomSelectDropdown>
          {/* {errors.changeSignatoryName2 && (
            <p className="text-danger">Please select a name</p>
          )} */}
          <CustomSelectDropdown
            name="changeSignatoryName3"
            reference={register({ required: true })}
            label="Signatory Three"
          >
            {signatories && signatories[2]?.email ? (
              <option value={signatories[2].email} selected>
                {signatories[2]?.email}
              </option>
            ) : (
              ""
            )}
            <option value={0}>None</option>

            {usersData?.map((user) => {
              const { id, firstName, lastName, email } = user;
              return (
                <option
                  key={id}
                  value={email}
                >{`${firstName} ${lastName}`}</option>
              );
            })}
          </CustomSelectDropdown>
          {/* {errors.changeSignatoryName3 && (
            <p className="text-danger">Please select a name</p>
          )} */}

          {signatoriesStatus === "loading " && (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}

          <button
            className="btn advancly-btn btn-sm w-100 mt-3"
            // disabled={
            //   watchChangeSignatoryNameChange ||
            //   watchChangeSignatoryNameChange2 ||
            //   watchChangeSignatoryNameChange3
            // }
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  );
};
export default ChangeMultipleSignatoryModal1;
