import { Fragment } from "react";
import { Link, useRouteMatch } from "react-router-dom";

const LoanCountModal = ({ count, setShowModal }) => {
  const match = useRouteMatch();
  return (
    <div
      className="popup-box"
      id="walletDetailsModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="walletDetailsModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered " role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <h5 className="modal-title page-subtitle">Due Loans Reminder</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowModal(false)}
            >
              x
            </button>
          </div>
          <div className="modal-body text-centre">
            <Fragment>
              <h6 className="">
                You have <span className="text-danger">{count}</span> due loan
                {count > 1 ? "s" : ""}.{" "}
                <span className="">
                  <Link
                    to={{
                      pathname: match.path + "repayments",
                      state: { dueLoans: true },
                    }}
                    className="color-blue"
                  >
                    See details
                  </Link>
                </span>
              </h6>
            </Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoanCountModal;
