import React, { Fragment, useRef } from "react";
import { ReactComponent as SuccessIcon } from "../../svg/icons/checkbox-circle-line.svg";
import { ReactComponent as ErrorIcon } from "../../svg/icons/close-circle-line.svg";
import styles from "./BVNSuccessModal.module.scss";

const BVNSuccessModal = ({ modalMessage }) => {
  const dismissRef = useRef();
  return (
    <div
      className="modal fade"
      id="bvnSuccessModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="bvnSuccessModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <h6 className="text-center font-weight-bold w-100">BVN Result</h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            ></button>
          </div>
          <div className="modal-body">
            {typeof modalMessage !== "string" ? (
              <Fragment>
                <div className={styles.statusIcon}>
                  <SuccessIcon />
                </div>
                <p>
                  First Name:{" "}
                  <span>{modalMessage && modalMessage.first_name}</span>
                </p>
                <p>
                  Last Name:{" "}
                  <span>{modalMessage && modalMessage.last_name}</span>
                </p>
                <p>
                  BVN: <span>{modalMessage && modalMessage.bvn}</span>
                </p>
                <p>
                  Mobile Number:{" "}
                  <span>{modalMessage && modalMessage.mobile}</span>
                </p>
                <p>
                  Date of Birth:{" "}
                  <span>{modalMessage && modalMessage.formatted_dob}</span>
                </p>
              </Fragment>
            ) : (
              <Fragment>
                <div className={styles.statusIcon}>
                  <ErrorIcon />
                  <p className="font-weight-bold text-center mt-5">
                    {modalMessage}
                  </p>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BVNSuccessModal;
