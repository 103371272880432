import React, { useState } from "react";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import useWebHookEvent from "../../../custom-hooks/useWebHookEvent";
import Loader from "../../Loader/Loader.component";
import LoaderColored from "../../Loader/LoaderColored";
import { errorHandler } from "../../../helpers/errorHandler";
import { postData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { appInsights } from "../../AppInsight/AppInsight";
import { queryCache } from "react-query";

const schema = yup
  .object({
    hookTypes: yup.string().required("Please select hook type"),
    hookUrl: yup.string().url("Invalid URL").required("URL is required"),
  })
  .required();
const CreateWebHooks = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    data: webHookEventData,
    status: webHookEventStatus,
    error: webHookEventError,
  } = useWebHookEvent();

  const onSubmit = async (data) => {
    const { hookTypes, hookUrl } = data;
    setLoading(true);
    setError(false);
    const reqBody = {
      webhook_url: hookUrl,
      webhook_event_id: hookTypes,
    };
    try {
      const res = await postData(apiEndpoints.SUBSCRIBE_WEB_EVENT, reqBody);
      setLoading(false);
      setSuccess(true);
      setSuccessMessage(res?.message);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
      // Refetch web hook list
      queryCache.invalidateQueries("getWebHookList");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "CreateWebHooks.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));

      setTimeout(() => {
        setError(false);
      }, 4000);
    }
  };
  return (
    <div>
      {webHookEventError && (
        <small className="text-dangeralert alert-danger small text-center">
          {errorHandler(webHookEventError)}
        </small>
      )}
      {error && <p className="alert alert-danger small row">{error}</p>}
      {success && <p className="alert alert-success small">{successMessage}</p>}
      <form className="" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className=" col-12 col-md-5 ">
            <CustomSelectDropdown
              name="hookTypes"
              reference={register}
              errors={errors.hookTypes}
              showRequiredIcon={true}
            >
              <option>Enter Types</option>
              {webHookEventData &&
                webHookEventData?.map(({ id, name }) => {
                  return (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  );
                })}
            </CustomSelectDropdown>
            {webHookEventStatus === "loading" && <LoaderColored />}
          </div>
          <div className="col-12 mt-2 col-md-5">
            <CustomInputField
              name="hookUrl"
              type="text"
              reference={register}
              errors={errors.hookUrl}
              showRequiredIcon={true}
              placeholder="Enter Webhook Url e.g https://www.example.com"
            ></CustomInputField>
          </div>
          <div className="col-2">
            {" "}
            <button
              type="submit"
              disabled={loading}
              className="btn advancly-btn btn-sm  transition-3d-hover mt-2  d-flex align-items-center"
            >
              Create WebHook
              {loading && <Loader />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateWebHooks;
