import { useState } from "react";
import useWebHookList from "../../../custom-hooks/useWebHookList";
import { errorHandler } from "../../../helpers/errorHandler";
import NoTableDataAvailable from "../../NoTableDataAvailable/NoTableDataAvailable.component";
import PageLoader from "../../PageLoader/PageLoader.component";
import DeleteWebHookModal from "./DeleteWebHookModal";
import EditWebHookModal from "./EditWebHookModal";
import HistoryWebHookModal from "./HistoryWebHookModal";

const WebHookList = () => {
  const [idData, setIdData] = useState(null);
  const {
    data: webHookListData,
    status: webHookListStatus,
    error: webHookListError,
  } = useWebHookList();
  return (
    <div className="">
      {webHookListError && (
        <small className="text-dangeralert alert-danger small text-center">
          {errorHandler(webHookListError)}
        </small>
      )}
      <div className=" p-3">
        <div className="table-responsive">
          <table className="table">
            <thead className="">
              <tr>
                <th>Url</th>
                <th>Event Type</th>
                <th colSpan="3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {webHookListStatus === "loading" ? (
                <div className="d-flex justify-content-center align-items-center ">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <PageLoader />
                </div>
              ) : webHookListData ? (
                <>
                  {webHookListData?.map(({ webhookUrl, webhookEvent, id }) => {
                    return (
                      <tr key={id}>
                        <td>{webhookUrl}</td>
                        <td>{webhookEvent?.name}</td>
                        {/* Right now business has decided history should be display */}

                        {/* <td
                          className="color-mid-blue cursor-pointer "
                          data-toggle="modal"
                          data-target="#webHookHistoryModal"
                          onClick={() => setIdData(id)}
                          style={{ textDecoration: "underline" }}
                        >
                          History{" "}
                        </td> */}
                        <td
                          className="color-mid-blue cursor-pointer "
                          data-toggle="modal"
                          data-target="#editWebHookModal"
                          onClick={() => setIdData(id)}
                          style={{ textDecoration: "underline" }}
                        >
                          Edit
                        </td>
                        <td
                          className="color-mid-blue cursor-pointer "
                          onClick={() => setIdData(id)}
                          data-toggle="modal"
                          data-target="#deleteWebHookModal"
                          style={{ textDecoration: "underline" }}
                        >
                          Delete
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <NoTableDataAvailable />
              )}
            </tbody>
          </table>
        </div>
      </div>
      <HistoryWebHookModal webhookEventId={idData} />
      <DeleteWebHookModal idData={idData} />
      <EditWebHookModal idData={idData} />
    </div>
  );
};

export default WebHookList;
