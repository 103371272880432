import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getWalletTransactions = async ({
  pageNumber,
  size,
  startDate,
  endDate,
  consumerType,
  accountNumber,
}) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;

  if (startDate) {
    queryParams = `${queryParams}&StartDate=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&EndDate=${endDate}`;
  }
  if (consumerType) {
    queryParams = `${queryParams}&ConsumerType=${consumerType}`;
  }
  if (accountNumber) {
    queryParams = `${queryParams}&AccountNumber=${accountNumber}`;
  }
  const data = await getData(
    `${apiEndpoints.getAllWalletTransactions}?${queryParams}`
  );

  return data?.data;
};

export default function useWalletTransactions({
  pageNumber,
  size,
  startDate,
  endDate,
  consumerType,
  accountNumber,
}) {
  return useQuery(
    [
      { pageNumber, size, startDate, endDate, consumerType, accountNumber },
      "getAllWalletWalletTransactions",
    ],
    getWalletTransactions
  );
}
