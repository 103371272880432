import React, { useRef, useState } from "react";
import { ReactComponent as SuccessIcon } from "../../../svg/icons/success-icon2.svg";
import styles from "./webHooks.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import LoaderColored from "../../Loader/LoaderColored";
import useWebHookEvent from "../../../custom-hooks/useWebHookEvent";
import { errorHandler } from "../../../helpers/errorHandler";
import { appInsights } from "../../AppInsight/AppInsight";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { queryCache } from "react-query";
import { putData } from "../../../apis/apiMethods";
import Loader from "../../Loader/Loader.component";
const schema = yup
  .object({
    hookTypes: yup.string().required("Please select hook type"),
    hookUrl: yup.string().url("Invalid URL").required("URL is required"),
  })
  .required();
const EditWebHookModal = ({ idData }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const dismissRef = useRef();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    data: webHookEventData,
    status: webHookEventStatus,
    error: webHookEventError,
  } = useWebHookEvent();

  const onSubmit = async (data) => {
    const { hookTypes, hookUrl } = data;
    setLoading(true);
    setError(false);
    const reqBody = {
      id: idData,
      webhook_url: hookUrl,
      webhook_event_id: hookTypes,
    };
    try {
      const res = await putData(
        `${apiEndpoints.UPDATE_WEB_HOOK_SUBSCRIPTION}${idData}`,
        reqBody
      );
      setLoading(false);
      setSuccess(true);
      setSuccessMessage(res?.message);
      setTimeout(() => {
        setSuccess(false);
        dismissRef.current.click();
      }, 3000);
      // Refetch web hook list
      queryCache.invalidateQueries("getWebHookList");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "EditWebHookModal.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));

      setTimeout(() => {
        setError(false);
        dismissRef.current.click();
      }, 4000);
    }
  };
  return (
    <div>
      <div
        className="modal fade"
        id="editWebHookModal"
        tabindex="-1"
        aria-labelledby="editwebHookModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mb-5">
          {webHookEventError && (
            <small className="text-dangeralert alert-danger small text-center">
              {errorHandler(webHookEventError)}
            </small>
          )}
          <div className="modal-content  ">
            {error && <p className="alert alert-danger small row">{error}</p>}

            {success ? (
              <div className="modal-body d-flex flex-column align-items-center">
                <div className={styles.successIcon}>
                  <SuccessIcon />
                </div>
                <h5 className={styles.success_heading}>Webhook Updated!</h5>
                <p className={styles.success_paragraph}>
                  {/* You have successfully updated this webhook */}
                  {successMessage}
                </p>
                <div className="d-flex flex-column">
                  <button
                    type="button"
                    className=" btn advancly-btn  mt-3 mb-4 "
                    data-dismiss="modal"
                    aria-label="Close"
                    ref={dismissRef}
                  >
                    Done
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div className="modal-header border-bottom-0 ">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Edit Webhook
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref={dismissRef}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form className="" onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex flex-column">
                      <div className="mt-3 ">
                        <CustomSelectDropdown
                          name="hookTypes"
                          reference={register}
                          errors={errors.hookTypes}
                          showRequiredIcon={true}
                        >
                          <option>Edit Types</option>
                          {webHookEventData &&
                            webHookEventData?.map(({ id, name }) => {
                              return (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              );
                            })}
                        </CustomSelectDropdown>
                        {webHookEventStatus === "loading" && <LoaderColored />}
                      </div>
                      <div className="mt-3">
                        <CustomInputField
                          name="hookUrl"
                          type="url"
                          reference={register}
                          errors={errors.hookUrl}
                          showRequiredIcon={true}
                          placeholder="Edit  Webhook Url"
                        ></CustomInputField>
                      </div>
                      <div className="mt-3">
                        {" "}
                        <button
                          type="submit"
                          className="btn advancly-btn  transition-3d-hover mt-2   w-100"
                        >
                          Update
                          {loading && <Loader />}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditWebHookModal;
