import React, { useEffect, useState } from "react";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../components/Paginator/Paginator.component";
// import moment from "moment";
import { formatMoney } from "../../helpers/formatter";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import Loader from "../../components/Loader/Loader.component";
import useAutoPaidRepaymentdetails from "../../custom-hooks/useAutoPaidRepaymentdetails";
import { appInsights } from "../../components/AppInsight/AppInsight";
// import { attachClassNameToTag } from "../../helpers/attachClassNameToTag";

function AutoRepaymentLoans() {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [count, setCount] = useState(null);

  const { data, status, error } = useAutoPaidRepaymentdetails({
    Page: pageNumber,
    PageSize: size,
  });
  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }
  useEffect(() => {
    appInsights.trackPageView({
      name: "AutoRepaymentLoans.jsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      {error && <p className="alert alert-danger small">{error}</p>}
      {!error && status !== "loading" && (
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div className="page-subtitle"></div>
            <div className="d-flex align-items-center">
              <p className="pr-2 pt-1">Showing:</p>
              <CustomSelectDropdown
                onChange={(e) => setSize(e.target.value)}
                value={size}
              >
                <option value={10}>10 Entries</option>
                <option value={20}>20 Entries</option>
                <option value={50}>50 Entries</option>
                <option value={100}>100 Entries</option>
              </CustomSelectDropdown>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>Reference</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Loan Amount</th>
                  <th>Loan Interest</th>
                  <th>Loan Tenure</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data && data?.data?.length ? (
                  data?.data?.map((data, id) => {
                    const {
                      loan_ref,
                      aggregator_details,
                      borrower_details,
                      loan_tenure,
                      loan_amount,
                      loan_interest,
                      status,
                    } = data;
                    return (
                      <tr key={id}>
                        <td>{loan_ref}</td>
                        <td>{borrower_details.first_name}</td>
                        <td>{borrower_details.last_name}</td>
                        <td>
                          {formatMoney(
                            loan_amount,
                            aggregator_details.currency
                          )}
                        </td>
                        <td>
                          {formatMoney(
                            loan_interest,
                            aggregator_details.currency
                          )}
                        </td>
                        <td>{loan_tenure}</td>
                        <td>{status}</td>
                      </tr>
                    );
                  })
                ) : (
                  <NoTableDataAvailable />
                )}
              </tbody>
            </table>
          </div>
          <Paginator
            size={size}
            page={pageNumber}
            count={count}
            changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
          />
        </div>
      )}
      {status === "loading" && (
        <div className="d-flex justify-content-center p-5">
          <Loader />
        </div>
      )}
    </div>
  );
}
export default AutoRepaymentLoans;
